import moment from 'moment';
import React, { useRef, useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { GET } from '../../../services/HttpRequests';

export default function ExportCallLogsPopup() {
  const [list, setList] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const csvLink = useRef(null);
  const DICT = {
    answered: 'Answered',
    not_answered: 'Unanswered',
  };
  let org_name = localStorage.getItem('doosra-biz-organisation-name') || '';
  let columns = [
    { id: 'status', displayName: 'CALL STATUS' },
    { id: 'zvr_name', displayName: 'AGENT NAME' },
    { id: 'didNumber', displayName: 'DID NUMBER' },
    { id: 'initiatorNumber', displayName: ' VIRTUAL NO' },
    { id: 'destinationNumber', displayName: 'DESTINATION NUMBER' },
    { id: 'duration', displayName: 'DURATION' },
    { id: 'created_at_date', displayName: 'DATE' },
    { id: 'created_at_time', displayName: 'TIME' },
  ];
  const outgoingCallsUrl = process.env.REACT_APP_OUTGOING_CALLS_API;

  const openPopup = () => {
    setIsPopupOpen(true);
    setFetching(false);
    setFromDate(null);
    setToDate(null);
  };

  const fetchDataHandler = async () => {
    try {
      if (fetching) return;
      setFetching(true);

      const res = await GET(
        `${outgoingCallsUrl}v1/organisations/outbound-call/call-log/list`,
        {
          pageNo: 1,
          pageSize: 5000,
          organisationId: localStorage.getItem('doosra-biz-organisation-id'),
          search_key: 'date',
          from_date: fromDate,
          to_date: toDate,
        }
      );
      setList(res.data.response.items);
      csvLink.current.handleClick();
      setFetching(false);
      setIsPopupOpen(false);
    } catch (err) {
      console.log(err);
      setFetching(false);
    }
  };

  return (
    <div>
      <button className="Otherbutton" onClick={openPopup}>
        EXPORT
      </button>
      <Popup
        open={isPopupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Download call logs</p>
            <img
              className="PopupClose"
              src={Close}
              onClick={() => {
                setIsPopupOpen(false);
                setFetching(false);
                setFromDate(null);
                setToDate(null);
              }}
            />
          </div>
          <div className="PopupBody">
            <p className="PopupInfo">
              The logs will be downloaded as a CSV file.
            </p>
            <div style={{ margin: '8px 0 0 40px' }}>
              <p style={{ margin: 0 }}>From Date</p>
              <input
                type="date"
                onChange={(e) => setFromDate(e.target.value)}
                value={fromDate}
              />
            </div>
            <div style={{ margin: '8px 0 0 40px' }}>
              <p style={{ margin: 0 }}>To Date</p>
              <input
                type="date"
                min={fromDate}
                disabled={!fromDate}
                onChange={(e) => setToDate(e.target.value)}
                value={toDate}
              />
            </div>
            <p className="PopupNote">
              Max download limit - Recent 5000 entries
            </p>

            <CsvDownloader
              filename={`${org_name}_outgoing_call_list_${new Date().toLocaleDateString()}`}
              separator=","
              columns={columns}
              ref={csvLink}
              datas={
                list.length
                  ? list.map((detail) => {
                      return {
                        ...detail,
                        status: DICT[detail.status],
                        zvr_name: detail?.user?.zvr_name,
                        created_at_date: moment(detail.createdAt).format("DD-MM-YYYY"),
                        created_at_time: moment(detail.createdAt).format("HH:mm:ss"),
                      };
                    })
                  : [{}]
              }
              text=""
              style={{ display: 'none' }}
            />

            <button
              className={
                !fromDate || !toDate
                  ? 'ButtonFullWidth BgGrey'
                  : 'ButtonFullWidth'
              }
              style={
                !fromDate || !toDate || fetching ? { cursor: 'default' } : null
              }
              disabled={!fromDate || !toDate}
              onClick={fetchDataHandler}
            >
              {fetching ? 'Loading ...' : 'Download CSV'}
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
}
