import React, { useState, useEffect } from 'react';
import { commonStyle } from '../../../assets/css/css';
import { connect } from 'react-redux';
import Sidebar from '../../../common/componentV2/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { alpha, styled } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { PUT, GET, DELETE, POST } from '../../../services/HttpRequests';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import DataTable from '../../../common/component/table/DataTable';
import moment from 'moment';
import Button from '../../../common/component/button/Button';
import Popup from '../../../common/component/popups/popups';
import InfoRed from '../../../assets/images/infoRed.png';
import Close from '../../../assets/images/popupClose.png';
import Delete from '../../../assets/images/delete.png';
import PreLoader from '../../../common/component/preLoader/PreLoader';
const role = localStorage.getItem('doosra-biz-user-role');
const isFreeTrail = localStorage.getItem('is_free_trial_enabled');
const orgUrl = process.env.REACT_APP_ORGANIZATION_API;
const planAvailable = localStorage.getItem('is_plan_available');
const showPaymentHistory = localStorage.getItem('show_payment_history');
export const TOPBAR_DATA_SUCCESS = 'TOPBAR_DATA_SUCCESS';

const APIManagement = (props) => {
  const dispatch = useDispatch();
  const [isToggled, setIsToggled] = useState(props.agentEmailVerification);
  const [APIList, setAPIList] = useState([]);
  const [addAPIPopupOpen, setAddAPIPopupOpen] = useState(false);
  const [deleteAPIPopupOpen, setDeleteAPIPopupOpen] = useState(null);
  const [name, setName] = useState(null);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const fetchAPIListHandler = async () => {
    setProcessing(true);
    await GET(`${orgUrl}v1/organisation/api-key`)
      .then((res) => {
        if (res.data.response.api_key) {
          setAPIList([res?.data?.response]);
        }
        setProcessing(false);
      })
      .catch((err) => {
        console.log(err);
        setProcessing(false);
      });
  };

  const addAPIHandler = async () => {
    setProcessing(true);
    await POST(`${orgUrl}v1/organisation/api-key`, { apiName: name })
      .then((res) => {
        if (res.data.response.api_key) {
          setAPIList([res?.data?.response]);
        }
        setName(null);
        setError(null);
        setAddAPIPopupOpen(false);
        setProcessing(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason || 'An error occured');
        setProcessing(false);
      });
  };

  const deleteAPIHandler = async () => {
    setProcessing(true);
    await DELETE(`${orgUrl}v1/organisation/api-key`, null, {
      apiKey: deleteAPIPopupOpen.api_key,
    })
      .then((res) => {
        setAPIList(res?.data?.response);
        setError(null);
        setDeleteAPIPopupOpen(null);
        setProcessing(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason || 'An error occured');
        setProcessing(false);
      });
  };

  useEffect(() => {
    fetchAPIListHandler();
  }, []);

  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success('Copied!');
    } catch (err) {
      toast.error('Failed to copy!');
    }
  };

  const AddAPIPopup = (
    <Popup
      open={addAPIPopupOpen}
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      <div className="PopupContainer" style={{ overflow: 'auto' }}>
        <div className="PopupHeaderContainer">
          <p>CREATE API KEY</p>
          <img
            alt=""
            className="PopupClose"
            src={Close}
            onClick={() => setAddAPIPopupOpen(false)}
          />
        </div>
        <div className="PopupBody">
          <div className="PopupInputContainer">
            <label className="PopupInputLabel">API Key Name</label>
            <input
              className="PopupInput"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          {error ? (
            <div className="ErrorContainer ErrorContainerMargin">
              <img
                alt=""
                src={InfoRed}
                style={{
                  paddingRight: '5px',
                  position: 'relative',
                  top: '-2px',
                }}
              />
            </div>
          ) : null}

          <button
            className="ButtonFullWidth BgGreen"
            onClick={addAPIHandler}
            style={{ marginTop: '20px' }}
          >
            SAVE
          </button>
        </div>
      </div>
    </Popup>
  );

  const DeleteAPIPopup = (
    <Popup
      open={!!deleteAPIPopupOpen}
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      <div className="PopupContainer" style={{ overflow: 'auto' }}>
        <div className="PopupHeaderContainer">
          <p>DELETE API KEY</p>
          <img
            alt=""
            className="PopupClose"
            src={Close}
            onClick={() => setDeleteAPIPopupOpen(null)}
          />
        </div>
        <div className="PopupBody">
          <p
            className="PopupNote"
            style={{ fontSize: '14px', fontWeight: '700', color: '#000000' }}
          >
            Deleting an API key may result in your integrations not functioning
            at all
          </p>

          <p
            className="PopupNote"
            style={{ fontSize: '14px', fontWeight: '500', color: '#000000' }}
          >
            This action is irreversible and permanent. Do you want to proceed?
          </p>

          {error ? (
            <div className="ErrorContainer ErrorContainerMargin">
              <img
                alt=""
                src={InfoRed}
                style={{
                  paddingRight: '5px',
                  position: 'relative',
                  top: '-2px',
                }}
              />
            </div>
          ) : null}

          <button
            className="ButtonFullWidth BgGreen"
            onClick={deleteAPIHandler}
            style={{ marginTop: '20px' }}
          >
            DELETE
          </button>
        </div>
      </div>
    </Popup>
  );

  return (
    <div className={`${props.className}`}>
      {processing && <PreLoader />}
      <Topbar />
      <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
        <Sidebar />
        <div className="ContentContainer">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '14px',
            }}
          >
            <h2>Manage API Keys</h2>
            <button
              className="Otherbutton"
              style={{
                marginRight: 0,
                backgroundColor: '#07aa3f',
                color: '#ffffff',
                width: '140px',
              }}
              onClick={() => setAddAPIPopupOpen(true)}
            >
              CREATE API KEY
            </button>
          </div>

          {AddAPIPopup}
          {DeleteAPIPopup}
          <DataTable.Table style={{ width: '100%', marginBottom: '2px' }}>
            <DataTable.Head>
              <DataTable.CellHead width="150">CREATED</DataTable.CellHead>
              <DataTable.CellHead width="200">API KEY NAME</DataTable.CellHead>
              <DataTable.CellHead>API KEY</DataTable.CellHead>
              <DataTable.CellHead width="70">ACTIONS</DataTable.CellHead>
            </DataTable.Head>
            <DataTable.Body>
              {APIList.length > 0 &&
                APIList.map((item) => (
                  <DataTable.Row key={item?._id}>
                    <DataTable.Cell>
                      {moment(item?.created_at).format('DD-MM-YYYY')}
                    </DataTable.Cell>
                    <DataTable.Cell>{item?.api_name || '-'}</DataTable.Cell>
                    <DataTable.Cell
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleCopy(item.api_key)}
                    >
                      {item?.api_key}
                    </DataTable.Cell>
                    <DataTable.Cell>
                      <img
                        style={{ cursor: 'pointer' }}
                        src={Delete}
                        onClick={() => {
                          setDeleteAPIPopupOpen(item);
                        }}
                      />
                    </DataTable.Cell>
                  </DataTable.Row>
                ))}
            </DataTable.Body>
          </DataTable.Table>
        </div>
      </div>
    </div>
  );
};

// border: solid 1px #d2d3d4;
//     border-radius: 4px;
//     // max-width:300px;
//     padding:12px;
//     flex:1;
//     box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  agentEmailVerification: state.topbar.agentEmailVerification,
});

export default commonStyle(connect(mapStateToProps, {})(APIManagement));
