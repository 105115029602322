import React, { Component } from 'react';
import { connect } from 'react-redux';

import Sidebar from '../../../common/componentV2/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import MoreMenuIcon, {
  DraggableIcon,
  EditGroupIcon,
  GroupDeleteIcon,
  MinusRedIcon,
  PlusGreenIcon,
  PlusIcon,
  RoutingSVG,
} from '../../../assets/Icons/Icons';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { DELETE, GET, POST, PUT } from '../../../services/HttpRequests';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import { commonStyle } from '../../../assets/css/css';
import UpgradeServices from '../../../common/component/upgrade-services/UpgradeServices';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import {
  ADMIN_GROUP_SEARCH_BUTTON,
  ADMIN_ADD_GROUP_BUTTON,
  ADMIN_GROUP_TYPE_BUTTON,
  ADMIN_SAVE_GROUP_BUTTON,
  ADMIN_EDIT_GROUP_BUTTON,
  ADMIN_EDIT_GROUP_MEMBERS_BUTTON,
  ADMIN_GROUP_DELETE_BUTTON,
} from '../../../common/posthog/events';
import { logPostHogEvent } from '../../../common/posthog/posthog';

const userUrl = process.env.REACT_APP_USER_API;
const priorityRoutingUrl = process.env.REACT_APP_PRIORITY_ROUTING_API;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const removeCountryCode = (number) => {
  if (number.length > 10) {
    return number.replace('91', '');
  }
  return number;
};

class IVRPage extends Component {
  constructor() {
    super();
    this.state = {
      groups: [],
      popupType: '',
      error: '',
      newGroup: {},
      allUsers: [],
      remainUser: [],
      selectedUsers: [],
      page: 1,
      page_size: 8,
      total: 0,
      total_pages: 0,
      loading: true,
      currentGroup: {},
      mode: '',
      orgId: localStorage.getItem('doosra-biz-organisation-id'),
      searchValue: '',
      error: '',
      count: 0,
      searchData: {},
    };
  }

  componentDidMount = async () => {
    this.fetchAllGroups();
    const userResponse = await GET(userUrl + `v1/user`, {
      search: '',
      organisationId: this.state.orgId,
    });
    this.setState({
      allUsers: userResponse.data.response.users.docs.filter(
        (item) => item?.organisation?.is_onboard
      ),
    });
  };

  fetchAllGroups = async () => {
    this.setState({
      loading: true,
    });
    const allGrupsResponse = await GET(
      `${priorityRoutingUrl}v1/organisations/priority-routing/group/list`,
      {
        page: this.state.page - 1,
        page_size: this.state.page_size,
        organisationId: this.state.orgId,
      }
    ).then((res) => {
      return res.data.response;
    });
    let count = Math.ceil(
      allGrupsResponse.metadata.total / this.state.page_size
    );
    this.setState({
      groups: allGrupsResponse.items,
      total: allGrupsResponse.metadata.total,
      total_pages: count,
      loading: false,
      count: allGrupsResponse.metadata.total,
      searchData: {},
      page: 1,
    });
  };

  onClickAddGroup = () => {
    logPostHogEvent(ADMIN_ADD_GROUP_BUTTON);
    this.setState({
      popupType: 'groupname',
      mode: 'new',
    });
  };

  onChangeGroupName = (e) => {
    this.setState({
      newGroup: { ...this.state.newGroup, name: e.target.value },
    });
  };

  onChangeDescription = (e) => {
    this.setState({
      newGroup: { ...this.state.newGroup, description: e.target.value },
    });
  };

  onChangeAddRoutingType = (e) => {
    logPostHogEvent(ADMIN_GROUP_TYPE_BUTTON);
    this.setState({
      newGroup: { ...this.state.newGroup, routing_type: e.target.value },
    });
  };

  onChangeEditGroupName = (e) => {
    this.setState({
      currentGroup: { ...this.state.currentGroup, name: e.target.value },
    });
  };

  onChangeEditGroupDescreption = (e) => {
    this.setState({
      currentGroup: { ...this.state.currentGroup, description: e.target.value },
    });
  };

  onChangeEditRoutingType = (e) => {
    this.setState({
      currentGroup: {
        ...this.state.currentGroup,
        routing_type: e.target.value,
      },
    });
  };

  onClickGroupNameSave = async () => {
    const { name, description, routing_type } = this.state.currentGroup;
    this.setState({ error: '' });
    if (!name || !description) {
      let errText = '';
      if (!description) errText = 'Please Enter Description';
      if (!name) errText = 'Please Enter Name';
      this.setState({ error: errText });
    } else if (!routing_type) {
      this.setState({ error: 'Please Select Routing Type' });
    } else {
      this.updateGroupDetails({
        name: this.state.currentGroup.name,
        description: this.state.currentGroup.description,
        routing_type: this.state.currentGroup.routing_type,
      });
    }
  };

  onClickEditGroupMembersSave = () => {
    const selectedUsers = this.state.selectedUsers || [];
    if (selectedUsers && selectedUsers.length <= 0) {
      this.setState({ error: 'Please Add Agents' });
    } else {
      this.setState({ error: '' });
      this.updateGroupDetails({
        members: [...this.state.selectedUsers.map((user) => user.user_id)],
      });
    }
  };

  updateGroupDetails = async (data) => {
    const editResponse = await PUT(
      `${priorityRoutingUrl}v1/organisations/priority-routing/group`,
      {},
      {
        ...data,
        organisationId: this.state.orgId,
        priorityRoutingGroupId: this.state.currentGroup._id,
      }
    );
    if (editResponse) {
      this.setState(
        {
          currentGroup: {},
          mode: '',
          popupType: '',
          remainUser: [],
          selectedUsers: [],
        },
        () => this.fetchAllGroups()
      );
    }
  };

  onCancleNewPopup = () => {
    this.setState({
      newGroup: {},
      popupType: '',
      mode: '',
      searchValue: '',
      selectedUsers: [],
      remainUser: [],
      currentGroup: {},
      error: '',
    });
  };

  onClickGroupNameNext = () => {
    const { name, description, routing_type } = this.state.newGroup;
    this.setState({ error: '' });

    if (!name || !description) {
      let errText = '';
      if (!description) errText = 'Please Enter Description';
      if (!name) errText = 'Please Enter Name';
      this.setState({ error: errText });
      console.log('error');
    } else if (!routing_type) {
      this.setState({ error: 'Please Select Routing Type' });
    } else {
      this.setState({
        popupType: 'assignuser',
        remainUser: [...this.state.allUsers],
      });
    }
  };

  onClickAddUserToSelectedUser = (item) => {
    const selectedUsers = this.state.selectedUsers;
    selectedUsers.push(item);
    const index = this.state.remainUser.findIndex(
      (user) => user.user_id === item.user_id
    );
    const remainUser = this.state.remainUser;
    remainUser.splice(index, 1);
    this.setState({
      selectedUsers,
      remainUser,
    });
  };

  onClickSelectedUserToAddUser = (item) => {
    const selectedUsers = this.state.selectedUsers;
    const remainUser = this.state.remainUser;
    remainUser.push(item);
    const index = this.state.selectedUsers.findIndex(
      (user) => user.user_id === item.user_id
    );
    selectedUsers.splice(index, 1);
    this.setState({
      selectedUsers,
      remainUser,
    });
  };

  remainUsersListJSX = () => {
    const result = this.state.searchValue
      ? this.state.remainUser.filter((item) =>
          item.zvr_name
            .toLowerCase()
            .includes(this.state.searchValue.toLowerCase())
        )
      : this.state.remainUser;
    return result.map((item) => (
      // return this.state.remainUser.map((item) => (
      <React.Fragment>
        <div
          style={{ display: 'grid', gridTemplateColumns: '60% 40%' }}
          key={item._id}
        >
          <p
            style={{
              paddingLeft: '30px',
              fontSize: '14px',
              color: '#33aabb',
              margin: 'auto 0',
            }}
          >
            {item.zvr_name}
          </p>
          <p style={{ height: '28px', width: '128px', margin: '8px 92px' }}>
            <PlusGreenIcon
              onClick={() => this.onClickAddUserToSelectedUser(item)}
            />
          </p>
        </div>
        <hr className="HR" style={{ width: '90%', margin: '0 auto' }} />
      </React.Fragment>
    ));
  };

  selectedUsersListJSX = () => {
    const result = this.state.searchValue
      ? this.state.selectedUsers.filter((item) =>
          item.zvr_name
            .toLowerCase()
            .includes(this.state.searchValue.toLowerCase())
        )
      : this.state.selectedUsers;
    return result.map((item) => (
      <React.Fragment>
        <div
          style={{ display: 'grid', gridTemplateColumns: '60% 40%' }}
          key={item._id}
        >
          <p
            style={{
              paddingLeft: '30px',
              fontSize: '14px',
              color: '#33aabb',
              margin: 'auto 0',
            }}
          >
            {item.zvr_name}
          </p>
          <p style={{ height: '28px', width: '128px', margin: '8px 92px' }}>
            <MinusRedIcon
              onClick={() => this.onClickSelectedUserToAddUser(item)}
            />
          </p>
        </div>
        <hr className="HR" style={{ width: '90%', margin: '0 auto' }} />
      </React.Fragment>
    ));
  };

  showMembers = () => {
    return this.state.currentGroup.members.map((item, index) => (
      <React.Fragment>
        <div
          style={{ display: 'grid', gridTemplateColumns: '20% 80%' }}
          key={item._id}
        >
          <p
            style={{
              paddingLeft: '30px',
              fontSize: '14px',
              color: '#33aabb',
              margin: 'auto 0',
            }}
          >
            {index + 1}
          </p>
          <p style={{ height: '28px', width: '128px', margin: '8px 92px' }}>
            {item.zvr_name}
          </p>
        </div>
        <hr className="HR" style={{ width: '90%', margin: '0 auto' }} />
      </React.Fragment>
    ));
  };

  primaryShow = () => {
    return this.state.currentGroup.primaries.map((item, index) => (
      <React.Fragment>
        <div
          style={{ display: 'grid', gridTemplateColumns: '20% 80%' }}
          key={item._id}
        >
          <p
            style={{
              paddingLeft: '30px',
              fontSize: '14px',
              color: '#33aabb',
              margin: 'auto 0',
            }}
          >
            {index + 1}
          </p>
          <p style={{ height: '28px', width: '128px', margin: '8px 92px' }}>
            {removeCountryCode(item.v_mobile_no)}
          </p>
        </div>
        <hr className="HR" style={{ width: '90%', margin: '0 auto' }} />
      </React.Fragment>
    ));
  };
  onClickAssignUserNext = () => {
    this.setState({ error: '' });
    const selectedUsers = this.state.selectedUsers || [];
    if (selectedUsers && selectedUsers.length <= 0) {
      this.setState({ error: 'Please Add Agents' });
    } else {
      this.setState({
        newGroup: { ...this.state.newGroup, members: this.state.selectedUsers },
        popupType: 'priority',
      });
    }
  };

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.selectedUsers,
      result.source.index,
      result.destination.index
    );

    this.setState({
      selectedUsers: items,
    });
  };

  onClickPriorityNext = async () => {
    logPostHogEvent(ADMIN_SAVE_GROUP_BUTTON);
    const selectedUsers = this.state.selectedUsers || [];
    const { name, description, routing_type } = this.state.newGroup;
    this.setState({ error: '' });
    if (!name || !description) {
      let errText = '';
      if (!description) errText = 'Please Enter Description';
      if (!name) errText = 'Please Enter Name';
      this.setState({ error: errText });
    }
    if (!routing_type) {
      this.setState({ error: 'Please Select Routing Type' });
    }
    if (selectedUsers && selectedUsers.length <= 0) {
      this.setState({ error: 'Please Add Agents' });
    } else {
      this.setState({ error: '' });
      this.setState({
        newGroup: { ...this.state.newGroup, members: this.state.selectedUsers },
      });

      const response = await POST(
        `${priorityRoutingUrl}v1/organisations/priority-routing/group`,
        {
          name: this.state.newGroup.name,
          members: this.state.selectedUsers.map((item) => item.user_id),
          description: this.state.newGroup.description,
          routing_type: this.state.newGroup.routing_type,
          organisationId: this.state.orgId,
        }
      );
      if (response) {
        this.setState(
          {
            selectedUsers: [],
            remainUser: [],
            newGroup: {},
            popupType: '',
            loading: true,
            page: 1,
            page_size: 8,
            mode: '',
          },
          () => {
            this.fetchAllGroups();
          }
        );
      }
    }
  };

  onClickEditGroup = (type, item) => {
    logPostHogEvent(
      type === 'groupname'
        ? ADMIN_EDIT_GROUP_BUTTON
        : ADMIN_EDIT_GROUP_MEMBERS_BUTTON
    );
    this.setState(
      {
        popupType: type,
        currentGroup: item,
        mode: 'edite',
      },
      () => {
        if (type === 'assignuser') {
          const selectedUsers = [...this.state.currentGroup.members];
          const allUsers = [...this.state.allUsers];
          const remiainUserFromAllUsers = [...this.state.currentGroup.members];
          for (let i = allUsers.length - 1; i >= 0; i--) {
            for (let j = 0; j < remiainUserFromAllUsers.length; j++) {
              if (
                allUsers[i] &&
                allUsers[i].user_id === remiainUserFromAllUsers[j].user_id
              ) {
                allUsers.splice(i, 1);
              }
            }
          }
          this.setState({
            selectedUsers,
            remainUser: allUsers,
          });
        }
      }
    );
  };

  onDeleteGroup = (item) => {
    this.setState({
      mode: 'delete',
      currentGroup: item,
    });
  };

  onClickDeleteGroup = async () => {
    logPostHogEvent(ADMIN_GROUP_DELETE_BUTTON);
    const deleteResponse = await DELETE(
      `${priorityRoutingUrl}v1/organisations/priority-routing/group`,
      {},
      {
        priorityRoutingGroupId: this.state.currentGroup._id,
        organisationId: this.state.orgId,
      }
    );
    if (deleteResponse) {
      this.setState(
        {
          mode: '',
          currentGroup: {},
        },
        () => this.fetchAllGroups()
      );
    }
  };
  onCkickShowMore = (item, type) => {
    this.setState({
      mode: 'show',
      popupType: type,
      currentGroup: item,
    });
  };

  onChangeSearchValue = async (e) => {
    this.setState({ searchValue: e.target.value });
    const userResponse = await GET(userUrl + `v1/user`, {
      searchKey: 'user',
      search_key: 'user',
      q: e.target.value,
      organisationId: this.state.orgId,
    });
    let remainingUsers = userResponse?.data?.response?.users?.docs?.filter(
      (item) => item?.organisation?.is_onboard
    );
    const selectedUsersIds = this.state.selectedUsers.map((obj) => obj._id);
    const finalUsers = remainingUsers.filter(
      (obj) => !selectedUsersIds.includes(obj._id)
    );
    this.setState({ remainUser: finalUsers });
  };

  onSearchUser = () => {
    console.log('cliecked fuhsdjkf');
  };

  seachHandler = async (data) => {
    logPostHogEvent(ADMIN_GROUP_SEARCH_BUTTON);
    data.page = 0;
    data.page_size = this.state.page_size;
    data.organisationId = this.state.orgId;
    this.setState({
      loading: true,
    });
    const allGrupsResponse = await GET(
      `${priorityRoutingUrl}v1/organisations/priority-routing/group/list`,
      data
    ).then((res) => {
      return res.data.response;
    });
    let count = Math.ceil(
      allGrupsResponse.metadata.total / this.state.page_size
    );
    this.setState({
      groups: allGrupsResponse.items,
      total: allGrupsResponse.metadata.total,
      total_pages: count,
      loading: false,
      count: allGrupsResponse.metadata.total,
      page: 1,
      searchData: data,
    });
  };

  fetchPageData = async (pageNo) => {
    const data = {
      ...this.state.searchData,
      page: pageNo - 1,
      page_size: this.state.page_size,
      organisationId: this.state.orgId,
    };
    console.log('fetchPageData', { data, pageNo });
    this.setState({
      loading: true,
    });
    const allGrupsResponse = await GET(
      `${priorityRoutingUrl}v1/organisations/priority-routing/group/list`,
      data
    ).then((res) => {
      return res.data.response;
    });
    let count = Math.ceil(
      allGrupsResponse.metadata.total / this.state.page_size
    );
    this.setState({
      groups: allGrupsResponse.items,
      total: allGrupsResponse.metadata.total,
      total_pages: count,
      loading: false,
      count: allGrupsResponse.metadata.total,
      page: pageNo,
    });
  };

  render() {
    const {
      total,
      popupType,
      newGroup,
      selectedUsers,
      remainUser,
      page,
      loading,
      total_pages,
      groups,
      mode,
      currentGroup,
    } = this.state;
    const tabs = [
      {
        title: 'Groups',
        subtitle: `${this.state.count}`,
        onClick: () => this.props.history.push('/ivr'),
        active: true,
      },
    ];
    if (this.props.callPriorityRoutingStatus === false) {
      return (
        <div className={`${this.props.className}`}>
          <Sidebar />
          <div className={this.props.sidebarClosed ? 'Content' : 'ContentFull'}>
            <Topbar />
            <div className="ContentContainer">
              <UpgradeServices
                icon="callRouting"
                heading="Call Routing"
                text="Set-up your own call centre in 3 steps by upgrading to this feature. Get numbers, add agents & create groups and you're good to go!"
                emailSent={this.props.isCallRoutingEmailSent}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={`${this.props.className}`}>
        <Topbar />
        <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
          <Sidebar />
          <div className="ContentContainer">
            <TabSearchBar tabs={tabs}>
              <SearchBar
                type="ivr"
                disableTitle
                count={this.state.count}
                reset={this.fetchAllGroups}
                search={this.seachHandler}
                onClickAddGroup={this.onClickAddGroup}
              />
            </TabSearchBar>
            {loading ? (
              <PreLoader />
            ) : (
              <>
                <div className="TabelContainer">
                  <div className="DoosraNumbersTableAlignment">
                    {/* <p className="TableHeader">VIRTUAL NUMBER</p>
                <p className="TableHeader">STATUS</p>
                <p className="TableHeader">ASSIGNED AGENT/GROUP</p>
                <p className="TableHeader">PERSONAL PHONE NUMBER</p>
                <p className="TableHeader" style={{ textAlign: 'right', paddingRight: '10px' }}>ACTIONS</p> */}
                  </div>
                </div>
                {total <= 0 && !isSuperAdminWithPermission('ADD') ? (
                  <div
                    onClick={() => this.onClickAddGroup()}
                    className="exmptyrectangle"
                  >
                    <div>
                      <span className="create-a-group">Create a new group</span>
                    </div>
                    <div>
                      <PlusIcon />
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    {groups.map((item) => (
                      <div className="column">
                        <div className="rectangle">
                          <div style={{ margin: '10px 10px 10px 10px' }}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                flexDirection: 'row',
                              }}
                            >
                              <div
                                className="dot"
                                style={{
                                  textAlign: 'center',
                                  cursor: 'pointer',
                                }}
                              >
                                <div style={{ marginTop: '3px' }}>
                                  <RoutingSVG type="green" />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  marginLeft: 'auto',
                                }}
                              >
                                {isSuperAdminWithPermission('EDIT') && (
                                  <div class="showhim">
                                    <div
                                      className="menudot"
                                      style={{ textAlign: 'center' }}
                                    >
                                      <div>
                                        <MoreMenuIcon />
                                      </div>
                                    </div>
                                    <div className="showme">
                                      <div
                                        className="showMenu"
                                        onClick={() =>
                                          this.onClickEditGroup(
                                            'groupname',
                                            item
                                          )
                                        }
                                      >
                                        <div className="showMenuText">
                                          Edit Group
                                        </div>
                                        <div>
                                          <EditGroupIcon />
                                        </div>
                                      </div>
                                      <div
                                        className="showMenu"
                                        onClick={() =>
                                          this.onClickEditGroup(
                                            'assignuser',
                                            item
                                          )
                                        }
                                      >
                                        <div className="showMenuText">
                                          Edit Team Members
                                        </div>
                                        <div>
                                          <EditGroupIcon />
                                        </div>
                                      </div>
                                      <div
                                        className="showDeleteMenu"
                                        onClick={() => this.onDeleteGroup(item)}
                                      >
                                        <div className="showMenuText">
                                          Delete Group
                                        </div>
                                        <div>
                                          <GroupDeleteIcon />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div style={{ marginTop: 'inherit' }}>
                              <p className="groupName">
                                {item.name}
                                <p
                                  className="groupDescripition"
                                  title={item.description}
                                >
                                  <span
                                    style={{
                                      display: 'inline-block',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      maxWidth: '140ch',
                                      width: '225px',
                                      wordWrap: 'break-word',
                                    }}
                                  >
                                    {item.description}
                                  </span>
                                </p>
                              </p>
                            </div>

                            <div style={{ marginTop: 'inherit' }}>
                              <p className="groupName">
                                Routing Type
                                <p className="groupDescripition">
                                  <span
                                    style={{
                                      fontSize: 12,
                                      textTransform: 'capitalize',
                                    }}
                                  >
                                    {item.routing_type === 'PRIORITY_WISE'
                                      ? 'Priority'
                                      : item.routing_type === 'ROUND_ROBIN'
                                      ? 'Round Robin'
                                      : ''}
                                  </span>
                                </p>{' '}
                              </p>
                            </div>

                            {/* <div style={{ marginTop: 'inherit' }}>
                                <p className="groupName">
                                  Group Type
                                  <p className="groupDescripition">
                                    {(item.group_type == "INCOMINGCALL" ? 'Incoming Call'
                                      : item.group_type == "OUTGOINGCALL" ? 'OutGoing Call'
                                        : 'None')}
                                  </p> </p>
                              </div> */}

                            <div style={{ marginTop: 'inherit' }}>
                              <p className="groupName">
                                Call in numbers
                                <p className="groupDescripition">
                                  <span
                                    style={{
                                      display: 'block',
                                      whiteSpace: 'normal',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      maxWidth: '70ch',
                                      width: '165px',
                                      wordWrap: 'break-word',
                                    }}
                                  >
                                    {item.primaries.length > 0 ? (
                                      item.primaries.length <= 5 ? (
                                        <>
                                          {item.primaries.map(
                                            (number, index) => (
                                              <>
                                                {removeCountryCode(
                                                  number.v_mobile_no
                                                )}
                                                {item.primaries.length -
                                                  index !==
                                                  1 && <>,</>}
                                                &nbsp;
                                              </>
                                            )
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {item.primaries
                                            .slice(0, 5)
                                            .map((number, index, arr) => (
                                              <>
                                                {removeCountryCode(
                                                  number.v_mobile_no
                                                )}
                                                ,
                                                {index + 1 !== arr.length ??
                                                  ','}
                                                &nbsp;
                                              </>
                                            ))}
                                          <span
                                            style={{
                                              textDecoration: 'underline',
                                              color: '#196cca',
                                            }}
                                            onClick={() =>
                                              this.onCkickShowMore(
                                                item,
                                                'primaryshow'
                                              )
                                            }
                                          >
                                            +{item.primaries.length - 5} more
                                          </span>
                                        </>
                                      )
                                    ) : item?.dids?.length > 0 ? (
                                      <>
                                        {item.dids.map((number, index) => (
                                          <>
                                            {removeCountryCode(
                                              number.virtualNumber
                                            )}
                                            {item.dids.length - index !== 1 && (
                                              <>,</>
                                            )}
                                            &nbsp;
                                          </>
                                        ))}
                                      </>
                                    ) : (
                                      <>-</>
                                    )}
                                  </span>
                                </p>
                              </p>
                            </div>
                            <div style={{ marginTop: 'inherit' }}>
                              <p className="groupName">
                                Team Members
                                <p className="groupDescripition">
                                  {item.members.length <= 3 ? (
                                    <>
                                      {item.members.map((mem) => (
                                        <>
                                          {mem.zvr_name}
                                          <br />
                                        </>
                                      ))}
                                    </>
                                  ) : (
                                    <>
                                      {item.members.slice(0, 3).map((mem) => (
                                        <>
                                          {mem.zvr_name}
                                          <br />
                                        </>
                                      ))}
                                      <span
                                        style={{
                                          textDecoration: 'underline',
                                          color: '#196cca',
                                        }}
                                        onClick={() =>
                                          this.onCkickShowMore(
                                            item,
                                            'membersshow'
                                          )
                                        }
                                        primaryshow
                                      >
                                        +{item.members.length - 3} more
                                      </span>
                                    </>
                                  )}
                                </p>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <Popup
                  open={
                    (popupType === 'groupname' ||
                      popupType === 'assignuser' ||
                      popupType === 'priority') &&
                    mode === 'new'
                  }
                  closeOnDocumentClick={false}
                  closeOnEscape={false}
                >
                  {popupType === 'groupname' && (
                    <div className="PopupContainer">
                      <div className="PopupHeaderContainer">
                        <p>Group Name</p>
                        <img
                          className="PopupClose"
                          src={Close}
                          onClick={this.onCancleNewPopup}
                        />
                      </div>
                      <div className="PopupBody">
                        <div className="PopupInputContainer">
                          <input
                            className="PopupInput"
                            id="emp_id"
                            placeholder="Enter Group Name"
                            value={newGroup.name}
                            onChange={this.onChangeGroupName}
                          />
                        </div>
                        <div className="PopupInputContainer">
                          <input
                            className="PopupInput"
                            id="emp_id"
                            placeholder="Enter Description"
                            value={newGroup.description}
                            onChange={this.onChangeDescription}
                          />
                        </div>
                        <div className="PopupInputContainer">
                          <select
                            id="routing_type"
                            className="PopupInput"
                            onChange={this.onChangeAddRoutingType}
                            value={newGroup.routing_type}
                          >
                            <option>Select Routing Type</option>
                            {this.props.isPriorityCallRoutingAllowed && (
                              <option value="PRIORITY_WISE">Priority</option>
                            )}
                            {this.props.isRoundRobinCallRoutingAllowed && (
                              <option value="ROUND_ROBIN">Round Robin</option>
                            )}
                          </select>
                        </div>
                        <div
                          className="PopupInputContainer"
                          style={{ color: 'red' }}
                        >
                          {this.state.error}
                        </div>
                        <button
                          className={`ButtonFullWidth`}
                          onClick={this.onClickGroupNameNext}
                        >
                          Next
                        </button>
                        <button
                          className="ButtonCancelFullWidth"
                          onClick={this.onCancleNewPopup}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                  {popupType === 'assignuser' && (
                    <div className="PopupContainer">
                      <div className="PopupHeaderContainer">
                        <p>Add agents to the group</p>
                        <img
                          className="PopupClose"
                          src={Close}
                          onClick={this.onCancleNewPopup}
                        />
                      </div>
                      <div className="PopupBody">
                        <div
                          style={{
                            display: 'flex',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <input
                            placeholder="Search Agent"
                            className="PopupSearchInput"
                            id="searchValue"
                            onChange={this.onChangeSearchValue}
                            value={this.state.searchValue}
                          />
                        </div>
                        {selectedUsers.length > 0 && (
                          <>
                            <div
                              style={{
                                display: 'grid',
                                gridTemplateColumns: '60% 40%',
                                textAlign: 'center',
                                fontSize: '12px',
                                'letter-spacing': '1.2px',
                              }}
                              className="PopupColumnHeaders"
                            >
                              <p
                                style={{
                                  margin: '10px',
                                  textAlign: 'left',
                                  paddingLeft: '20px',
                                }}
                              >
                                SELECTED AGENTS
                              </p>
                              <p
                                style={{
                                  margin: '10px',
                                  textAlign: 'right',
                                  paddingRight: '10px',
                                }}
                              >
                                ADD/REMOVE
                              </p>
                            </div>
                            <div
                              style={{
                                maxHeight: '150px',
                                overflowY: 'auto',
                                overflowX: 'hidden',
                              }}
                            >
                              {this.selectedUsersListJSX()}
                            </div>
                          </>
                        )}
                        {remainUser.length > 0 && (
                          <>
                            <div
                              style={{
                                display: 'grid',
                                gridTemplateColumns: '60% 40%',
                                textAlign: 'center',
                                fontSize: '12px',
                                'letter-spacing': '1.2px',
                              }}
                              className="PopupColumnHeaders"
                            >
                              <p
                                style={{
                                  margin: '10px',
                                  textAlign: 'left',
                                  paddingLeft: '20px',
                                }}
                              >
                                AVAILABLE AGENTS
                              </p>
                              <p
                                style={{
                                  margin: '10px',
                                  textAlign: 'right',
                                  paddingRight: '10px',
                                }}
                              >
                                ADD/REMOVE
                              </p>
                            </div>
                            <div
                              style={{
                                maxHeight: '150px',
                                overflowY: 'auto',
                                overflowX: 'hidden',
                              }}
                            >
                              {this.remainUsersListJSX()}
                            </div>
                          </>
                        )}
                        <div
                          className="PopupInputContainer"
                          style={{ color: 'red' }}
                        >
                          {this.state.error}
                        </div>
                        {newGroup.routing_type === 'ROUND_ROBIN' ? (
                          <button
                            className={`ButtonFullWidth`}
                            onClick={this.onClickPriorityNext}
                          >
                            SAVE
                          </button>
                        ) : (
                          <button
                            className={`ButtonFullWidth`}
                            onClick={this.onClickAssignUserNext}
                          >
                            Next
                          </button>
                        )}
                        <button
                          className="ButtonCancelFullWidth"
                          onClick={this.onCancleNewPopup}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                  {popupType === 'priority' && (
                    <div className="PopupContainer">
                      <div className="PopupHeaderContainer">
                        <p>Arrange Routing Priority</p>
                        <img
                          className="PopupClose"
                          src={Close}
                          onClick={this.onCancleNewPopup}
                        />
                      </div>
                      <div className="PopupBody">
                        <div
                          style={{
                            display: 'grid',
                            gridTemplateColumns: '33% 33% 33%',
                            textAlign: 'center',
                            fontSize: '12px',
                            'letter-spacing': '1.2px',
                          }}
                          className="PopupColumnHeaders"
                        >
                          <p
                            style={{
                              margin: '10px',
                              textAlign: 'left',
                              paddingLeft: '20px',
                            }}
                          >
                            AGENTS
                          </p>
                          <p
                            style={{
                              margin: '10px',
                              textAlign: 'right',
                              paddingRight: '10px',
                            }}
                          >
                            PRIORITY
                          </p>
                          <p
                            style={{
                              margin: '10px',
                              textAlign: 'right',
                              paddingRight: '10px',
                            }}
                          >
                            ARRANGE
                          </p>
                        </div>
                        <div
                          style={{
                            height: '170px',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                          }}
                        >
                          <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                              {(provided, snapshot) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  style={{
                                    minHeight: '220px',
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                  }}
                                >
                                  {this.state.selectedUsers.map(
                                    (item, index) => (
                                      <Draggable
                                        key={item.user_id}
                                        draggableId={item.user_id}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <>
                                            <div
                                              className={`${
                                                snapshot.isDragging
                                                  ? 'dropColumnBg'
                                                  : 'dragColumnBg'
                                              }`}
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={{
                                                userSelect: 'none',
                                                // padding: grid * 2,
                                                margin: `0 0 ${grid}px 0`,
                                                display: 'grid',
                                                gridTemplateColumns:
                                                  '33% 33% 33%',
                                                ...provided.draggableProps
                                                  .style,
                                              }}
                                            >
                                              <p
                                                style={{
                                                  paddingLeft: '30px',
                                                  fontSize: '14px',
                                                  color: '#33aabb',
                                                  margin: 'auto 0',
                                                }}
                                              >
                                                {item.zvr_name}
                                              </p>
                                              <p
                                                style={{
                                                  height: '28px',
                                                  width: '10px',
                                                  margin: '8px 92px',
                                                  color: '#33aabb',
                                                }}
                                              >
                                                {index + 1}
                                              </p>
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  justifyContent: 'right',
                                                  alignItems: 'center',
                                                  padding: '0px 30px',
                                                }}
                                              >
                                                <DraggableIcon />
                                              </div>
                                            </div>
                                            <hr
                                              className="HR"
                                              style={{
                                                width: '90%',
                                                margin: '0 auto',
                                              }}
                                            />
                                          </>
                                        )}
                                      </Draggable>
                                    )
                                  )}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </div>
                        <button
                          className={`ButtonFullWidth`}
                          onClick={this.onClickPriorityNext}
                        >
                          SAVE
                        </button>
                        <button
                          className="ButtonCancelFullWidth"
                          onClick={this.onCancleNewPopup}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </Popup>
                <Popup
                  open={
                    (popupType === 'groupname' ||
                      popupType === 'assignuser' ||
                      popupType === 'priority') &&
                    mode === 'edite'
                  }
                  closeOnDocumentClick={false}
                  closeOnEscape={false}
                >
                  {popupType === 'groupname' && (
                    <div className="PopupContainer">
                      <div className="PopupHeaderContainer">
                        <p>Group Name</p>
                        <img
                          className="PopupClose"
                          src={Close}
                          onClick={this.onCancleNewPopup}
                        />
                      </div>
                      <div className="PopupBody">
                        <div className="PopupInputContainer">
                          <input
                            className="PopupInput"
                            id="emp_id"
                            placeholder="Enter Group Name"
                            value={currentGroup.name}
                            onChange={this.onChangeEditGroupName}
                          />
                        </div>
                        <div className="PopupInputContainer">
                          <input
                            className="PopupInput"
                            id="emp_id"
                            placeholder="Enter Group Description"
                            value={currentGroup.description}
                            onChange={this.onChangeEditGroupDescreption}
                          />
                        </div>
                        <div className="PopupInputContainer">
                          <select
                            id="routing_type"
                            className="PopupInput"
                            onChange={this.onChangeEditRoutingType}
                            value={currentGroup.routing_type}
                          >
                            <option>Select Routing Type</option>
                            {this.props.isPriorityCallRoutingAllowed && (
                              <option value="PRIORITY_WISE">Priority</option>
                            )}
                            {this.props.isRoundRobinCallRoutingAllowed && (
                              <option value="ROUND_ROBIN">Round Robin</option>
                            )}
                          </select>
                        </div>
                        <div
                          className="PopupInputContainer"
                          style={{ color: 'red' }}
                        >
                          {this.state.error}
                        </div>
                        <button
                          className={`ButtonFullWidth`}
                          onClick={this.onClickGroupNameSave}
                        >
                          SAVE
                        </button>
                        <button
                          className="ButtonCancelFullWidth"
                          onClick={this.onCancleNewPopup}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                  {popupType === 'assignuser' && (
                    <div className="PopupContainer">
                      <div className="PopupHeaderContainer">
                        <p>Add agents to the group</p>
                        <img
                          className="PopupClose"
                          src={Close}
                          onClick={this.onCancleNewPopup}
                        />
                      </div>
                      <div className="PopupBody">
                        <div
                          style={{
                            display: 'flex',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <input
                            placeholder="Search Agent"
                            className="PopupSearchInput"
                            id="searchValue"
                            onChange={this.onChangeSearchValue}
                            value={this.state.searchValue}
                          />
                        </div>
                        {selectedUsers.length > 0 && (
                          <>
                            <div
                              style={{
                                display: 'grid',
                                gridTemplateColumns: '60% 40%',
                                textAlign: 'center',
                                fontSize: '12px',
                                'letter-spacing': '1.2px',
                              }}
                              className="PopupColumnHeaders"
                            >
                              <p
                                style={{
                                  margin: '10px',
                                  textAlign: 'left',
                                  paddingLeft: '20px',
                                }}
                              >
                                SELECTED AGENTS
                              </p>
                              <p
                                style={{
                                  margin: '10px',
                                  textAlign: 'right',
                                  paddingRight: '10px',
                                }}
                              >
                                ADD/REMOVE
                              </p>
                            </div>
                            <div
                              style={{
                                maxHeight: '150px',
                                overflowY: 'auto',
                                overflowX: 'hidden',
                              }}
                            >
                              {this.selectedUsersListJSX()}
                            </div>
                          </>
                        )}
                        {remainUser.length > 0 && (
                          <>
                            <div
                              style={{
                                display: 'grid',
                                gridTemplateColumns: '60% 40%',
                                textAlign: 'center',
                                fontSize: '12px',
                                'letter-spacing': '1.2px',
                              }}
                              className="PopupColumnHeaders"
                            >
                              <p
                                style={{
                                  margin: '10px',
                                  textAlign: 'left',
                                  paddingLeft: '20px',
                                }}
                              >
                                AVAILABLE AGENTS
                              </p>
                              <p
                                style={{
                                  margin: '10px',
                                  textAlign: 'right',
                                  paddingRight: '10px',
                                }}
                              >
                                ADD/REMOVE
                              </p>
                            </div>
                            <div
                              style={{
                                maxHeight: '150px',
                                overflowY: 'auto',
                                overflowX: 'hidden',
                              }}
                            >
                              {this.remainUsersListJSX()}
                            </div>
                          </>
                        )}
                        <div
                          className="PopupInputContainer"
                          style={{ color: 'red' }}
                        >
                          {this.state.error}
                        </div>
                        {currentGroup.routing_type === 'ROUND_ROBIN' ? (
                          <button
                            className={`ButtonFullWidth`}
                            onClick={this.onClickEditGroupMembersSave}
                          >
                            SAVE
                          </button>
                        ) : (
                          <button
                            className={`ButtonFullWidth`}
                            onClick={this.onClickAssignUserNext}
                          >
                            Next
                          </button>
                        )}
                        <button
                          className="ButtonCancelFullWidth"
                          onClick={this.onCancleNewPopup}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                  {popupType === 'priority' && (
                    <div className="PopupContainer">
                      <div className="PopupHeaderContainer">
                        <p>Arrange Routing Priority</p>
                        <img
                          className="PopupClose"
                          src={Close}
                          onClick={this.onCancleNewPopup}
                        />
                      </div>
                      <div className="PopupBody">
                        <div
                          style={{
                            display: 'grid',
                            gridTemplateColumns: '33% 33% 33%',
                            textAlign: 'center',
                            fontSize: '12px',
                            'letter-spacing': '1.2px',
                          }}
                          className="PopupColumnHeaders"
                        >
                          <p
                            style={{
                              margin: '10px',
                              textAlign: 'left',
                              paddingLeft: '20px',
                            }}
                          >
                            AGENTS
                          </p>
                          <p
                            style={{
                              margin: '10px',
                              textAlign: 'right',
                              paddingRight: '10px',
                            }}
                          >
                            PRIORITY
                          </p>
                          <p
                            style={{
                              margin: '10px',
                              textAlign: 'right',
                              paddingRight: '10px',
                            }}
                          >
                            ARRANGE
                          </p>
                        </div>
                        <DragDropContext onDragEnd={this.onDragEnd}>
                          <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                  height: '220px',
                                  overflowY: 'auto',
                                  overflowX: 'hidden',
                                }}
                              >
                                {this.state.selectedUsers.map((item, index) => (
                                  <Draggable
                                    key={item.user_id}
                                    draggableId={item.user_id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <>
                                        <div
                                          className={`${
                                            snapshot.isDragging
                                              ? 'dropColumnBg'
                                              : 'dragColumnBg'
                                          }`}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            userSelect: 'none',
                                            // padding: grid * 2,
                                            margin: `0 0 ${grid}px 0`,
                                            display: 'grid',
                                            gridTemplateColumns: '33% 33% 33%',
                                            ...provided.draggableProps.style,
                                          }}
                                        >
                                          <p
                                            style={{
                                              paddingLeft: '30px',
                                              fontSize: '14px',
                                              color: '#33aabb',
                                              margin: 'auto 0',
                                            }}
                                          >
                                            {item.zvr_name}
                                          </p>
                                          <p
                                            style={{
                                              height: '28px',
                                              width: '10px',
                                              margin: '8px 92px',
                                              color: '#33aabb',
                                            }}
                                          >
                                            {index + 1}
                                          </p>
                                          <div
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'right',
                                              alignItems: 'center',
                                              padding: '0px 30px',
                                            }}
                                          >
                                            <DraggableIcon />
                                          </div>
                                        </div>
                                        <hr
                                          className="HR"
                                          style={{
                                            width: '90%',
                                            margin: '0 auto',
                                          }}
                                        />
                                      </>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                        <div
                          className="PopupInputContainer"
                          style={{ color: 'red' }}
                        >
                          {this.state.error}
                        </div>
                        <button
                          className={`ButtonFullWidth`}
                          onClick={this.onClickEditGroupMembersSave}
                        >
                          SAVE
                        </button>
                        <button
                          className="ButtonCancelFullWidth"
                          onClick={this.onCancleNewPopup}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </Popup>
                <Popup
                  open={
                    (popupType === 'primaryshow' ||
                      popupType === 'membersshow') &&
                    mode === 'show'
                  }
                  closeOnDocumentClick={false}
                  closeOnEscape={false}
                >
                  {popupType === 'membersshow' && (
                    <div className="PopupContainer">
                      <div className="PopupHeaderContainer">
                        <p>
                          GROUP MEMBERS (
                          {this?.state?.currentGroup?.members?.length})
                        </p>
                        <img
                          className="PopupClose"
                          src={Close}
                          onClick={this.onCancleNewPopup}
                        />
                      </div>
                      <div className="PopupBody">
                        <div
                          style={{
                            height: '170px',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                          }}
                        >
                          {this.showMembers()}
                        </div>
                      </div>
                    </div>
                  )}
                  {popupType === 'primaryshow' && (
                    <div className="PopupContainer">
                      <div className="PopupHeaderContainer">
                        <p>GROUP MEMBERS</p>
                        <img
                          className="PopupClose"
                          src={Close}
                          onClick={this.onCancleNewPopup}
                        />
                      </div>
                      <div className="PopupBody">
                        <div
                          style={{
                            height: '170px',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                          }}
                        >
                          {this.primaryShow()}
                        </div>
                      </div>
                    </div>
                  )}
                </Popup>
                <Popup
                  open={mode === 'delete'}
                  closeOnDocumentClick={false}
                  closeOnEscape={false}
                >
                  <div className="PopupContainer">
                    <div className="PopupBody">
                      <img
                        className="PopupClose"
                        src={Close}
                        onClick={this.onCancleNewPopup}
                      />
                      <div
                        style={{
                          color: '#00515a',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          paddingTop: '30px',
                          fontSize: '16px',
                          fontWeight: 'bold',
                          fontStretch: 'normal',
                          fontStyle: 'normal',
                          lineHeight: 1.19,
                          letterSpacing: 'normal',
                        }}
                      >
                        Are you sure you want to delete this group?
                      </div>
                      <button
                        className={`ButtonFullWidth BgRed`}
                        onClick={this.onClickDeleteGroup}
                      >
                        DELETE
                      </button>
                      <button
                        className="ButtonCancelFullWidth"
                        onClick={this.onCancleNewPopup}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Popup>
                <Paginator
                  currentPage={this.state.page}
                  lastPage={this.state.total_pages}
                  getInfo={(value) => this.fetchPageData(value)}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const SafeIVRPage = ({
  callPriorityRoutingStatus,
  isCallRoutingEmailSent,
  ...props
}) => {
  props = { callPriorityRoutingStatus, isCallRoutingEmailSent, ...props };
  return <IVRPage {...props} />;
};

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  callPriorityRoutingStatus: state.topbar.call_priority_routing,
  isCallRoutingEmailSent: state.topbar.isCallRoutingEmailSent,

  isPriorityCallRoutingAllowed: state.topbar.isPriorityCallRoutingAllowed,
  isRoundRobinCallRoutingAllowed: state.topbar.isRoundRobinCallRoutingAllowed,
});

export default commonStyle(connect(mapStateToProps, {})(SafeIVRPage));
