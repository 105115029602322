import React from "react";
import Phone from "../../assets/images/phone_callback1.png";
import Audio from "../../assets/images/audio_file1.png";
import Dialpad from "../../assets/images/dialpad1.png";
import SupportAgent from "../../assets/images/support_agent1.png";
import GroupIcon from "../../assets/images/groups.png";
import CallEndIcon from "../../assets/images/call_end1.png"
import CallFlowStyle from "../callFlow/CallFlow.css"
import callFlowWebhook from "../../assets/images/callFlowWebhook.png";

const BottomTabs = () => {
  const onDragStart = (event, nodeType, nodeLabel) => {
    event.dataTransfer.setData("application/reactflow/type", nodeType);
    event.dataTransfer.setData("application/reactflow/label", nodeLabel);
    event.dataTransfer.effectAllowed = "move";
  };
  return (
    <div className="BottomCardContainer">
      <div className="cardItems" style={{ backgroundColor: "#07AA3F0D" }} draggable onDragStart={(event) => onDragStart(event, "startIvr", "")}>
        <img className="cardItemImg" src={Phone} /> 
        <text className="cardText">Start</text>
      </div>
      <div className="cardItems" style={{ backgroundColor: "#2357890D", marginLeft: "5px" }} draggable onDragStart={(event) => onDragStart(event, "playAudio", "")}>
        <img className="cardItemImg" src={Audio} /> 
        <text className="cardText" style={{ color: "#235789"}}>Audio</text>
      </div>
      <div className="cardItems" style={{ backgroundColor: "#5C5C5C0D", marginLeft: "5px" }} draggable onDragStart={(event) => onDragStart(event, "menu", [])}>
        <img className="cardItemImg" src={Dialpad} /> 
        <text className="cardText" style={{ color: "#5C5C5C" }}>Menu</text>
      </div>
      <div className="cardItems" style={{ backgroundColor: "#D556720D", marginLeft: "5px" }} draggable onDragStart={(event) => onDragStart(event, "agent", "")}>
        <img className="cardItemImg" src={SupportAgent} /> 
        <text className="cardText" style={{ color: "#D55672" }}>Agent</text>
      </div>
      <div className="cardItems" style={{ backgroundColor: "#6B4ACE0D", marginLeft: "5px" }} draggable onDragStart={(event) => onDragStart(event, "groupCall", "")}>
        <img className="cardItemImg" src={GroupIcon} /> 
        <text className="cardText" style={{ color: "#6B4ACE" }}>Group</text>
      </div>
      <div className="cardItems" style={{ backgroundColor: "#F8BB440D", marginLeft: "5px" }} draggable onDragStart={(event) => onDragStart(event, "webhook", "")}>
        <img className="cardItemImg" src={callFlowWebhook} /> 
        <text className="cardText" style={{ color: "#F8BB44" }}>Webhook</text>
      </div>
      <div className="cardItems" style={{ backgroundColor: "#F8153B0D", marginLeft: "5px" }} draggable onDragStart={(event) => onDragStart(event, "endCall", "")}>
        <img className="cardItemImg" src={CallEndIcon} /> 
        <text className="cardText" style={{ color: "#F8153B" }}>End</text>
      </div>
    </div>
  )
};

export default BottomTabs;