import Sidebar from '../../../common/componentV2/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { commonStyle } from '../../../assets/css/css';
import { connect } from 'react-redux';
import Button from '../../../common/component/button/Button';
import CsvDownloader from 'react-csv-downloader';
import { useEffect, useState } from 'react';
import '../../../assets/css/dragAndDrop.css';
// import AddDID from '../component/AddDID';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import BackIcon from '../../../assets/images/backArrowBlue.png';
import { GET, POST, PUT } from '../../../services/HttpRequests';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
const organisationUrl = process.env.REACT_APP_ORGANIZATION_API;
const voiceApiUrl = process.env.REACT_APP_VOICE_API;

const PopupContainer = {
  width: '400px',
};
const scrollEffect = {
  overflowY: 'auto',
};

const Label = (props) => {
  return (
    <label
      style={{
        marginBottom: '16px',
        width: '100%',
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#282828',
      }}
    >
      {props.children}
    </label>
  );
};

const Input = (props) => {
  return (
    <input
      style={{
        fontSize: '16px',
        width: '100%',
        padding: '8px',
        border: 'solid 1px #939598',
        borderRadius: '4px',
        marginBottom: '24px',
      }}
      placeholder={props.placeholder}
      type={props.type || 'text'}
      value={props.value}
      onChange={props.onChange}
    />
  );
};

const Checkbox = (props) => {
  return (
    <div style={{ marginBottom: '24px' }}>
      <input
        style={{ marginRight: '6px' }}
        type="checkbox"
        onChange={props.onChange}
        checked={props.checked}
        id="topping"
        name="topping"
        value="Paneer"
      />
      {props.label || '-'}
    </div>
  );
};

const Day = (props) => {
  return (
    <div
      className={
        props.weekDays.includes(props.thisDay) ? 'DaySelected' : 'DayDefault'
      }
      onClick={() => {
        props.handleWeekDaySelection(props.thisDay);
      }}
    >
      {props?.thisDay?.charAt(0)}
    </div>
  );
};

export function CreateVoiceApi(props) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [uploading, setUploading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const edit = searchParams.get('edit');
  const duplicate = searchParams.get('duplicate');

  const handleFetchCampaignDetails = (id) => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId: id,
    };
    setIsLoading(true);
    console.log(voiceApiUrl);
    GET(voiceApiUrl + 'v1/voice', data)
      .then((res) => {
        const {
          campaignName,
          campaignDescription,
          // campaignStartDate,
          // campaignEndDate,
          // weekDays,
        } = res?.data?.response;
        setName(campaignName);
        setDescription(campaignDescription);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(false);
    if (edit === 'true' || duplicate === 'true') {
      handleFetchCampaignDetails(id);
    }
  }, [edit, duplicate, id]);

  const submitFormHandler = async () => {
    try {
      if (uploading) return;

      if (!name) {
        toast.error('Campaign name is required');
        return;
      }

      setUploading(true);
      const reqBody = {
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
        name,
        description,
      };
      if (edit === 'true') {
        for (let entry of reqBody.entries()) {
          console.log(entry[0], entry[1]);
        }
        await PUT(`${voiceApiUrl}v1/voice?id=${id}`, {}, reqBody, {
          'Content-Type': 'multipart/form-data',
        });
        toast.success(`Succesfully edited Campaign`);
      } else {
        await POST(`${voiceApiUrl}v1/voice`, reqBody, {
          'Content-Type': 'multipart/form-data',
        });
        toast.success(`Succesfully added Campaign`);
      }

      setUploading(false);
      history.push(`/voice-api-list`);
    } catch (err) {
      setUploading(false);
      console.log(err);
      toast.error(err?.response?.data?.error?.reason || 'An error occured');
    }
  };

  const cancelCampaignCreation = () => {
    history.push('/voice-api-list');
  };

  return (
    <div className={`${props.className}`}>
      <Topbar />
      <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
        <Sidebar />
        <div className="ContentContainer">
          <TabSearchBar>
            <div
              style={{
                marginLeft: '24px',
                display: 'flex',
                gap: '24px',
                alignItems: 'center',
              }}
            >
              <img
                src={BackIcon}
                style={{ cursor: 'pointer' }}
                onClick={cancelCampaignCreation}
                alt=""
              />
              <p
                style={{
                  margin: 0,
                  color: '#00515a',
                  fontSize: '24px',
                  fontWeight: 500,
                }}
              >
                {duplicate === 'true' ? (
                  <>Duplicating Campaign</>
                ) : edit === 'true' ? (
                  <>Edit Campaign</>
                ) : (
                  <>Create a Voice Api Campaign</>
                )}
              </p>
            </div>
          </TabSearchBar>
          {isLoading && <PreLoader />}
          {uploading && <PreLoader />}
          <Popup open={isOpen}>
            <img
              style={{ left: '96%', top: '-5%', zIndex: '1' }}
              alt=""
              className="PopupClose"
              src={Close}
              onClick={() => {
                setIsOpen(false);
              }}
            />
            <div
              className="PopupContainer"
              style={{ ...PopupContainer, ...scrollEffect }}
            >
              <div
                className="PopupHeaderContainer"
                style={{ ...PopupContainer }}
              >
                <p>Create Broadcast Confirmation</p>
              </div>
              <div className="PopupBody" style={{ ...PopupContainer }}>
                <p className="PopupBodyTitle">
                  The broadcast campaign will start on creation. Are you sure
                  you want to create the broadcast?
                </p>
                <p className="PopupNote">
                  Note: Calls are permitted solely from 9 am to 9 pm; any calls
                  outside of this timeframe will be placed in a queue.
                </p>
                <button
                  className="ButtonCancelFullWidth"
                  style={{ marginTop: '16px' }}
                  onClick={submitFormHandler}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Popup>
          {/* {!isLoading && ( */}
          <div
            style={{ width: '594px', marginLeft: '40px', marginTop: '40px' }}
          >
            <Label>Campaign Title</Label>
            <input
              style={{
                fontSize: '16px',
                width: '100%',
                padding: '8px',
                border: 'solid 1px #939598',
                borderRadius: '4px',
                marginBottom: '24px',
              }}
              placeholder="Enter the campaign title here…"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={edit === 'true' ? true : false}
            />
            <Label>Campaign Description (optional)</Label>
            <Input
              placeholder="Enter the campaign description here…"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            <div
              style={{
                marginTop: '40px',
                display: 'flex',
                gap: '16px',
                marginBottom: '50px',
              }}
            >
              <Button
                label={edit === 'true' ? 'EDIT CAMPAIGN' : 'SAVE CAMPAIGN'}
                style={{ backgroundColor: '#196cca', color: '#ffffff' }}
                onClick={() => setIsOpen(true)}
              />
              <Button
                onClick={cancelCampaignCreation}
                label="CANCEL"
                style={{ color: '#ed3833', borderColor: '#ed3833' }}
              />
            </div>
          </div>
          {/* )} */}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  userDetails: state.recovery.userDetails,
});

export default commonStyle(connect(mapStateToProps, {})(CreateVoiceApi));
