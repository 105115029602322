import Sidebar from '../../../common/componentV2/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { commonStyle } from '../../../assets/css/css';
import { connect } from 'react-redux';
import Button from '../../../common/component/button/Button';
import CsvDownloader from 'react-csv-downloader';
import { useEffect, useState } from 'react';
import '../../../assets/css/dragAndDrop.css';
import AddAgent from '../component/AddAgent';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import BackIcon from '../../../assets/images/backArrowBlue.png';
import { GET, POST, PUT } from '../../../services/HttpRequests';
import downloadIconGreen from '../../../assets/images/downloadIconGreen.png';
import cancelIcon from '../../../assets/images/cancelIconCircleGreen.png';
import DataCard from '../component/DataCard';
import DataTable from '../../../common/component/table/DataTable';
import ExportCampaignWiseReport from '../component/ExportCampaignWiseReport';
import AudioPlayer from '../component/AudioPlayer';

const outGoingUrl = process.env.REACT_APP_RECOVERY_CALL_API;

function CampaignReportTable(props) {
  return (
    <DataTable.Table>
      <DataTable.Head>
        <DataTable.CellHead width="20">#</DataTable.CellHead>
        <DataTable.CellHead width="50">AGENT</DataTable.CellHead>
        <DataTable.CellHead width="60">LEAD NAME</DataTable.CellHead>
        <DataTable.CellHead width="40">LEAD NUMBER</DataTable.CellHead>
        <DataTable.CellHead width="70">CALL STATUS</DataTable.CellHead>
        <DataTable.CellHead width="50">DURATION</DataTable.CellHead>
        <DataTable.CellHead width="60">DATE</DataTable.CellHead>
        <DataTable.CellHead width="60">TIME</DataTable.CellHead>
        <DataTable.CellHead width="60">DISPOSITION</DataTable.CellHead>
        <DataTable.CellHead
          width="140"
          style={{ textAlign: 'end', marginRight: '8px' }}
        >
          CALL RECORDING
        </DataTable.CellHead>
      </DataTable.Head>
      <DataTable.Body>
        {props?.agentsList?.length > 0 &&
          props?.agentsList?.map((agent, index) => (
            <DataTable.Row id={agent.userId}>
              <DataTable.Cell>{1 + index}</DataTable.Cell>
              <DataTable.Cell>{agent?.agentName}</DataTable.Cell>
              <DataTable.Cell>{agent?.leadName || '-'}</DataTable.Cell>
              <DataTable.Cell>{agent?.destinationNumber || '-'}</DataTable.Cell>
              <DataTable.Cell>{agent?.status || '-'}</DataTable.Cell>
              <DataTable.Cell>{agent?.duration || '-'}</DataTable.Cell>
              <DataTable.Cell>
                {new Date(agent.createdAt).toLocaleDateString() || '-'}
              </DataTable.Cell>
              <DataTable.Cell>
                {new Date(agent.createdAt).toLocaleTimeString() || '-'}
              </DataTable.Cell>
              <DataTable.Cell>{agent?.callTag || '-'}</DataTable.Cell>
              <DataTable.Cell style={{ textAlign: 'end', marginRight: '8px' }}>
                {agent?.recording ? (
                  <AudioPlayer
                    callRecordingUrl={agent?.recording}
                    callId={agent?._id}
                  />
                ) : (
                  ''
                )}
              </DataTable.Cell>
            </DataTable.Row>
          ))}
      </DataTable.Body>
    </DataTable.Table>
  );
}

export function RecoveryXCampaignReport(props) {
  const [campaignData, setCampaignData] = useState({});
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');

  const handleFetchCampaignDetails = (id) => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId: id,
    };
    GET(outGoingUrl + 'v1/organisations/outbound-call/get-campaign-calls', data)
      .then((res) => {
        console.log('res', res.data.response);
        setCampaignData(res.data.response);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (id) {
      handleFetchCampaignDetails(id);
    }
  }, [id]);

  return (
    <div className={`${props.className}`}>
      <Topbar />
      <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
        <Sidebar />
        <div className="ContentContainer">
          <TabSearchBar>
            <div
              style={{
                marginLeft: '24px',
                display: 'flex',
                gap: '24px',
                alignItems: 'center',
              }}
            >
              <img
                src={BackIcon}
                style={{ cursor: 'pointer' }}
                onClick={() => history.push(`/recovery-x-campaigns`)}
              />
              <p
                style={{
                  margin: 0,
                  color: '#00515a',
                  fontSize: '24px',
                  fontWeight: 500,
                }}
              >
                {campaignData?.campaignName || ''}
              </p>
              <ExportCampaignWiseReport
                campaignName={campaignData?.campaignName || ''}
                campaignData={campaignData?.campaignCallsData}
              />
            </div>
          </TabSearchBar>
          <div style={{ display: 'flex', gap: '32px', padding: '16px' }}>
            <DataCard title="Calls" count={campaignData?.totalCallsMade} />
            <DataCard
              title="Connected Calls"
              count={campaignData?.answeredCalls}
            />
            {/* <DataCard
              title="Calls Unanswered"
              count={campaignData?.unAnsweredCalls}
            />
            <DataCard
              title="Call Answer Rate (%)"
              count={campaignData?.answerPercentage}
            /> */}
          </div>
          <CampaignReportTable agentsList={campaignData?.campaignCallsData} />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  userDetails: state.recovery.userDetails,
});

export default commonStyle(
  connect(mapStateToProps, {})(RecoveryXCampaignReport)
);
