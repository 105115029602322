import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { commonStyle } from '../../../assets/css/css';

import '../../../assets/css/Content.css';
import { GET } from '../../../services/HttpRequests';
import AudioPlayer from '../components/AudioPlayer';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import Sidebar from '../../../common/componentV2/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import DataTable from '../../../common/component/table/DataTable';
import ViewContactPopup from '../../contacts/component/ViewContactPopup';
import AddContactNotesTagsPopup from '../../contacts/component/AddContactNotesTagsPopup';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import TagIcon from '../../../assets/images/tag.png';
import AICallAnalysisPopup from '../../../common/component/callAiPopup/index';
import {
  SEARCH_CALL_LOG_BUTTON,
  INCOMING_TAB,
  OUTGOING_TAB,
  DID_CALL_BACK_TAB,
  ADMIN_SEARCH_CALL_LOG_BUTTON,
  ADMIN_INCOMING_TAB,
  ADMIN_OUTGOING_TAB,
  ADMIN_DID_CALL_BACK_TAB,
  ADMIN_BLACKLIST_TAB,
} from '../../../common/posthog/events';
import { logPostHogEvent } from '../../../common/posthog/posthog';
import PlanExpired from '../../../common/component/plan-expired/PlanExpired';
import { setIncomingCallsTableHeaders } from '../../../actions/recoveryXActions';
import { INCOMING_CALLS_MAPPER } from '../../../common/constants';
import AddIndividualCallDispositionsPopup from '../components/AddIndividualCallDispositionsPopup';
import { toast } from 'react-toastify';

const outgoingCallsUrl = process.env.REACT_APP_OUTGOING_CALLS_API;
const incomingCallsUrl = process.env.REACT_APP_INCOMING_CALLS_API;
const cloudCallUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;

// const organisationId = localStorage.getItem('doosra-biz-organisation-id');
// const isUnansweredEnabled = [
//   "0c82dcd0-dc2b-11ec-8f79-1519cf62ab83", "259150fd-6c5a-4886-9eb7-901690b9f024",
//   "e6cabbf5-5c0d-4f21-a20e-e2cceb18f3ee", "d9e43a4a-8201-425d-89ff-12e571e45a67",
//   "1f117d72-360c-4c19-a08c-ef5c97e9961f", "421d68a1-8f6b-4741-970a-068726647ea5",
//   "afc9311a-801e-42a5-b4b8-f1c669317b5c", "c1f49fa7-4997-47c5-b6dd-b395f7742bc1",
//   "89311a60-ee25-11ec-880a-9391784445de"
// ].includes(organisationId)

class CallLogsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      outgoingCallLogCount: 0,
      didCallBackCount: 0,
      callBacksCount: 0,
      blackListCount: 0,
      totalPages: 0,
      currentPage: 1,
      pageSize: 15,
      callList: [],
      loading: false,
      searchData: {},
      filteredHeaders: props?.incomingCallsTableHeaders || [
        'VIRTUAL NO',
        'CALL TYPE',
        'DTMF CODE',
        'CALLER',
        'CONTACT NAME',
        'NOTES',
        'TAGS',
        'CALL STATUS',
        'DURATION',
        'PULSE COUNT',
        'DISPOSITION',
        'CALL COMMENT',
        'CALL RECORD',
        'TIME',
        'DATE',
      ],
      isHeadersPopUpOpen: false,
      hideTableHeaders: false,
      isModalOpen: false,
      dispositions: [],
    };
  }

  componentDidMount() {
    this.getFreshList();
    this.getOutgoingCallLogCount();
    this.getDispositions();
    // this.getDIDCallBackCount();
    // this.getCallBlackListCount();
  }

  toggleModal = () => {
    const value = !this.state.isModalOpen;
    this.setState({ isModalOpen: value });
  };

  getDispositions = () => {
    this.setState({ loading: true });
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    };
    return GET(cloudCallUrl + 'disposition', data)
      .then((res) => {
        this.setState({
          dispositions: res?.data?.response?.dispositions || [],
        });
        this.setState({ loading: false });
        return true;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.incomingCallsTableHeaders &&
      prevProps.incomingCallsTableHeaders &&
      this.props.incomingCallsTableHeaders?.length !==
        prevProps.incomingCallsTableHeaders?.length
    ) {
      this.setState({ filteredHeaders: this.props.incomingCallsTableHeaders });
    }
  }

  updateFilteredHeader = (header, isSelected) => {
    if (isSelected) {
      this.setState({
        filteredHeaders: [...this.state.filteredHeaders, header],
      });
      this.props.setIncomingCallsTableHeaders({
        incomingCallsTableHeaders: [...this.state.filteredHeaders, header],
      });
    } else {
      const unFilteredHeaders = this.state.filteredHeaders.filter(
        (headerType) => headerType !== header
      );
      this.setState({ filteredHeaders: unFilteredHeaders });
      this.props.setIncomingCallsTableHeaders({
        incomingCallsTableHeaders: unFilteredHeaders,
      });
    }
  };

  getFreshList = () => {
    this.setState({ loading: true });
    let data = {
      pageNo: this.state.currentPage,
      pageSize: this.state.pageSize,
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    };
    console.log('getFreshList', { data });
    GET(`${incomingCallsUrl}v1/organisations/calls`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        let count = Math.ceil(res.payload.count / this.state.pageSize);
        if (count === 0) count = 1;
        const data = res.payload.docs;
        data.map((item) => {
          if (item.notesObj) {
            const notes = item.notesObj.map((obj) => obj.note);
            if (notes && notes.length > 0) {
              item.notes = notes.join(', ');
            }
          }
          if (item.tagsObj) {
            item.tags = item.tagsObj.map((obj) => obj.tag);
          }
          return item;
        });
        this.setState({
          count: res.payload.count,
          callList: data,
          totalPages: count,
          pageSize: res.pageSize,
          loading: false,
          searchData: {},
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  seachHandler = (searchitem) => {
    const userRole = localStorage.getItem('doosra-biz-user-role');
    logPostHogEvent(
      userRole === 'ADMIN'
        ? ADMIN_SEARCH_CALL_LOG_BUTTON
        : SEARCH_CALL_LOG_BUTTON
    );
    searchitem.pageNo = 1;
    searchitem.pageSize = this.state.pageSize;
    searchitem.organisation_id = localStorage.getItem(
      'doosra-biz-organisation-id'
    );
    console.log('search handler', { searchitem });

    GET(`${incomingCallsUrl}v1/organisations/calls`, searchitem)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        let count = Math.ceil(res.payload.count / this.state.pageSize);
        if (count === 0) count = 1;
        const data = res.payload.docs;
        data.map((item) => {
          if (item.notesObj) {
            const notes = item.notesObj.map((obj) => obj.note);
            if (notes && notes.length > 0) {
              item.notes = notes.join(', ');
            }
          }
          if (item.tagsObj) {
            item.tags = item.tagsObj.map((obj) => obj.tag);
          }
          return item;
        });
        this.setState({
          callList: data,
          count: res.payload.count,
          totalPages: count,
          pageSize: res.pageSize,
          searchData: searchitem,
        });
      })
      .catch((err) => {
        if (err) {
          this.setState({ loading: false });
        }
      });
  };

  fetchPageData = (value) => {
    let data = {
      pageNo: value,
      pageSize: this.state.pageSize,
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    };
    console.log('fetchPageData', { data, search: this.state.searchData });

    data = {
      ...this.state.searchData,
      ...data,
    };
    console.log('fetchPageData', { data });
    GET(`${incomingCallsUrl}v1/organisations/calls`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        const data = res.payload.docs;
        data.map((item) => {
          if (item.notesObj) {
            const notes = item.notesObj.map((obj) => obj.note);
            if (notes && notes.length > 0) {
              item.notes = notes.join(', ');
            }
          }
          if (item.tagsObj) {
            item.tags = item.tagsObj.map((obj) => obj.tag);
          }
          return item;
        });
        this.setState({
          count: res.payload.count,
          callList: data,
          currentPage: value,
          pageSize: res.pageSize,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getOutgoingCallLogCount = () => {
    this.setState({ loading: true });
    let orgId = localStorage.getItem('doosra-biz-organisation-id');
    GET(`${outgoingCallsUrl}v1/organisations/outbound-call/call-log/list`, {
      pageNo: 1,
      pageSize: 10,
      organisationId: orgId,
    })
      .then((res) => res.data.response)
      .then((res) =>
        this.setState({
          outgoingCallLogCount: res.metadata.total,
          loading: false,
        })
      )
      .catch((err) => null);
  };
  getDIDCallBackCount = () => {
    let orgId = localStorage.getItem('doosra-biz-organisation-id');
    GET(`${incomingCallsUrl}v1/organisations/did-callback-list`, {
      pageNo: 1,
      pageSize: 10,
      organisationId: orgId,
    })
      .then((res) => res.data.response)
      .then((res) => this.setState({ didCallBackCount: res.payload.count }))
      .catch((err) => console.log(err));
  };

  getCallBlackListCount = () => {
    GET(`${incomingCallsUrl}v1/organisations/black-list`, {
      pageNo: 1,
      pageSize: 10,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    })
      .then((res) => res.data.response)
      .then((res) => this.setState({ blackListCount: res.payload.count }))
      .catch((err) => console.log(err));
  };

  trimNumber = (number) => {
    if (number && number.length === 12) return number.substring(2);
    return number;
  };

  render() {
    const { callList } = this.state;
    const userRole = localStorage.getItem('doosra-biz-user-role');
    let isFreeTrail = localStorage.getItem('is_free_trial_enabled');
    isFreeTrail = isFreeTrail ? (isFreeTrail === 'true' ? true : false) : false;
    const {
      callForwardingStatus,
      isOutboundCallEnabled,
      isOrgOutboundCallEnabled,
      isUnansweredAgentEnabled,
    } = this.props;

    // let displayData;
    const tabs = [];
    if (callForwardingStatus)
      tabs.push({
        title: 'Incoming',
        subtitle: `${this.state.count}`,
        onClick: () => {
          logPostHogEvent(
            userRole === 'ADMIN' ? ADMIN_INCOMING_TAB : INCOMING_TAB
          );
          this.props.history.push('/call-logs');
        },
        active: true,
      });

    if (
      (userRole === 'ADMIN' && isOrgOutboundCallEnabled) ||
      (userRole === 'USER' && isOutboundCallEnabled && isOrgOutboundCallEnabled)
    ) {
      // if (!isFreeTrail) {
      //   tabs.push({
      //     title: 'Callback',
      //     subtitle: `${this.state.didCallBackCount}`,
      //     onClick: () => {
      //       logPostHogEvent(
      //         userRole === 'ADMIN' ? ADMIN_DID_CALL_BACK_TAB : DID_CALL_BACK_TAB
      //       );
      //       this.props.history.push('/did-call-back');
      //     },
      //   });
      // }
      tabs.push({
        title: 'Outgoing',
        subtitle: `${this.state.outgoingCallLogCount}`,
        onClick: () => {
          logPostHogEvent(
            userRole === 'ADMIN' ? ADMIN_OUTGOING_TAB : OUTGOING_TAB
          );
          this.props.history.push('/outgoing-call-logs');
        },
      });
    }

    // if (userRole === 'ADMIN') {
    //   tabs.push({
    //     title: 'BlackList',
    //     subtitle: `${this.state.blackListCount}`,
    //     onClick: () => {
    //       logPostHogEvent(ADMIN_BLACKLIST_TAB);
    //       this.props.history.push('/black-list');
    //     },
    //   });
    // }

    const showDtmfCodes = (ivrDetails) => {
      if (ivrDetails && ivrDetails.length > 0) {
        ivrDetails = ivrDetails.filter((obj) => obj.dtmf_code !== 'timeout');
        const dtmfcodes = ivrDetails.map((obj) => obj.dtmf_code);
        return dtmfcodes && dtmfcodes.length > 0 ? dtmfcodes.join(', ') : '-';
      }
      return '-';
    };

    function timeStringToPulse(timeString) {
      let pulses = '-';
      if (timeString?.includes(':')) {
        const [hours, minutes, seconds] = timeString.split(':').map(Number);
        pulses = Math.ceil(hours * 60 + minutes + (seconds > 0 ? 1 : 0));
        console.log('timeStringToPulse:if:', { pulses });
      } else {
        const totalSeconds = Number(timeString);
        pulses = Math.ceil(totalSeconds / 60);
        console.log('timeStringToPulse:else:', { pulses });
      }
      if (pulses === 0) return '-';
      return pulses;
    }

    return (
      <div className={`${this.props.className}`}>
        <Topbar />
        <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
          <Sidebar />
          <div className="ContentContainer">
            <TabSearchBar tabs={tabs}>
              <SearchBar
                type="calls"
                disableTitle
                count={this.state.count}
                reset={this.getFreshList}
                search={this.seachHandler}
                headers={Object.keys(INCOMING_CALLS_MAPPER)}
                updateFilteredHeader={this.updateFilteredHeader}
                filteredHeaders={this.state.filteredHeaders}
                isPopUpOpen={this.state.isHeadersPopUpOpen}
                openHeadersPopUp={() => {
                  this.setState({ isHeadersPopUpOpen: true });
                }}
                closeHeadersPopUp={() =>
                  this.setState({ isHeadersPopUpOpen: false })
                }
                hideTableHeaders={this.state.hideTableHeaders}
              />
            </TabSearchBar>
            <div
              style={{
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                marginBottom: '50px',
              }}
            >
              <DataTable.Table style={{ width: '100%', marginBottom: '2px' }}>
                {this.state.loading && <PreLoader />}
                <DataTable.Head>
                  {userRole !== 'USER' && (
                    <>
                      <DataTable.CellHead width="70">
                        AGENT NAME
                      </DataTable.CellHead>
                      {isUnansweredAgentEnabled && (
                        <DataTable.CellHead width="70">
                          UNANSWERED AGENTS
                        </DataTable.CellHead>
                      )}
                    </>
                  )}
                  {this.state.filteredHeaders?.length &&
                    this.state.filteredHeaders.map((i) => (
                      <DataTable.CellHead width="70">{i}</DataTable.CellHead>
                    ))}
                  <DataTable.CellHead width="20">ACTIONS</DataTable.CellHead>
                </DataTable.Head>
                <DataTable.Body>
                  {(callList || []).map((item) => (
                    <DataTable.Row key={item?._id}>
                      {userRole !== 'USER' && (
                        <>
                          <DataTable.Cell>
                            {(item.call_status_text === 'Missed' &&
                              item.is_group_call_log) ||
                            item.call_status_text === 'Received'
                              ? '-'
                              : item.zvr_name || '-'}
                          </DataTable.Cell>

                          {isUnansweredAgentEnabled && (
                            <DataTable.Cell>
                              {item.busyUserList
                                ? item.busyUserList.join(', ')
                                : ''}
                            </DataTable.Cell>
                          )}
                        </>
                      )}
                      {this.state.filteredHeaders &&
                        this.state.filteredHeaders.map((header) => {
                          switch (header) {
                            case 'VIRTUAL NO':
                              return (
                                <DataTable.Cell>
                                  {this.trimNumber(
                                    item.is_group_call_log
                                      ? item.group_v_mobile_no ??
                                          item.v_mobile_no
                                      : item.v_mobile_no
                                  )}
                                </DataTable.Cell>
                              );
                            case 'CALL TYPE':
                              return (
                                <DataTable.Cell>
                                  {item?.incoming_call_type || '-'}
                                </DataTable.Cell>
                              );
                            case 'DTMF CODE':
                              return (
                                <DataTable.Cell>
                                  {showDtmfCodes(item.ivr_details)}
                                </DataTable.Cell>
                              );
                            case 'CALLER':
                              return (
                                <DataTable.Cell>
                                  {item.s_mobile_no}
                                </DataTable.Cell>
                              );
                            case 'CONTACT NAME':
                              return (
                                <DataTable.Cell>
                                  {item.contact_name || '-'}
                                </DataTable.Cell>
                              );
                            case 'NOTES':
                              return (
                                <DataTable.Cell>
                                  <div className="tableNotes">
                                    {item.notes || '-'}
                                  </div>
                                </DataTable.Cell>
                              );
                            case 'TAGS':
                              return (
                                <DataTable.Cell>
                                  {item?.tags?.length > 0 &&
                                    item.tags.map((tag, index) => {
                                      return (
                                        <div key={index} className="tableTags">
                                          <img
                                            alt="tag"
                                            src={TagIcon}
                                            style={{
                                              width: '15px',
                                              height: '15px',
                                              marginRight: 7,
                                            }}
                                          />
                                          <span>{tag}</span>
                                        </div>
                                      );
                                    })}
                                </DataTable.Cell>
                              );
                            case 'CALL STATUS':
                              return (
                                item.call_status_text &&
                                (item.call_status_text === 'Missed' ? (
                                  <DataTable.CellMissedCall>
                                    {item.call_status_text}
                                  </DataTable.CellMissedCall>
                                ) : item.call_status_text === 'Answered' ? (
                                  <DataTable.CellAnsweredCall>
                                    {item.call_status_text}
                                  </DataTable.CellAnsweredCall>
                                ) : (
                                  <DataTable.Cell>
                                    {item.call_status_text}
                                  </DataTable.Cell>
                                ))
                              );
                            case 'DURATION':
                              return (
                                <DataTable.Cell>
                                  {item.callBackId
                                    ? item.duration === 0
                                      ? '-'
                                      : moment('1900-01-01 00:00:00')
                                          .add(item.call_duration, 'seconds')
                                          .format('HH:mm:ss')
                                    : item.call_duration &&
                                      item.call_duration !== '00:00:00'
                                    ? item.call_duration
                                    : '-'}
                                </DataTable.Cell>
                              );
                            case 'PULSE COUNT':
                              return (
                                <DataTable.Cell>
                                  {
                                      item?.pulseCount ? item?.pulseCount : timeStringToPulse(item?.call_duration) 
                                  }
                                </DataTable.Cell>
                              );
                            case 'DISPOSITION':
                              return (
                                <DataTable.Cell>
                                  {item?.disposition || '-'}
                                </DataTable.Cell>
                              );
                            case 'CALL COMMENT':
                              return (
                                <DataTable.Cell>
                                  {item?.callComment || '-'}
                                </DataTable.Cell>
                              );
                            case 'CALL RECORD':
                              return (
                                <DataTable.Cell>
                                  <AudioPlayer
                                    callRecordingUrl={item?.call_recording_url}
                                    callId={
                                      item.callBackId
                                        ? item?._id
                                        : item?.call_id
                                    }
                                  />
                                </DataTable.Cell>
                              );
                            case 'TIME':
                              return (
                                <DataTable.Cell>
                                  {moment(item.created_at).format('HH:mm:ss')}
                                </DataTable.Cell>
                              );
                            case 'DATE':
                              return (
                                <DataTable.Cell>
                                  {moment(item.created_at).format('DD-MM-YYYY')}
                                </DataTable.Cell>
                              );
                            default:
                              return null;
                          }
                        })}
                      <DataTable.Cell>
                        <div style={{ display: 'flex', gap: 5 }}>
                          {item.call_status_text === 'Answered' &&
                            userRole === 'ADMIN' &&
                            localStorage.getItem(
                              'is_ai_intelligence_enabled'
                            ) === 'true' && (
                              <AICallAnalysisPopup
                                callId={item._id?.toString()}
                                audio={item.call_recording_url}
                                agentName={item?.zvr_name}
                                name={item?.contact_name}
                                number={item?.s_mobile_no}
                                duration={item.call_duration_seconds}
                                time={item.created_at}
                                date={item.created_at}
                                outgoing={false}
                                disposition={item?.disposition}
                              />
                            )}

                          <AddIndividualCallDispositionsPopup
                            open={this.state.isModalOpen}
                            toggle={this.toggleModal}
                            dispositions={this.state.dispositions}
                            callId={item.call_id}
                            updateCallDispositonTag={this.fetchPageData}
                            isDisabled={
                              !item.disposition || item.disposition === 'RNR'
                                ? false
                                : true
                            }
                            selectedDispositon={item?.disposition || ''}
                            selectedComment={item?.callComment || ''}
                            isIncomingCall={true}
                          ></AddIndividualCallDispositionsPopup>

                          {item.is_contact ? (
                            <ViewContactPopup
                              key={item._id}
                              contactInfo={item.contactObj}
                              getList={this.getFreshList}
                              editFrom="callLog"
                              userId={item.user_id}
                            />
                          ) : (
                            isSuperAdminWithPermission('EDIT') &&
                            userRole === 'USER' && (
                              <AddContactNotesTagsPopup
                                callerNo={item.s_mobile_no}
                                getList={this.getFreshList}
                              />
                            )
                          )}
                        </div>
                      </DataTable.Cell>
                    </DataTable.Row>
                  ))}
                </DataTable.Body>
              </DataTable.Table>
            </div>
            <Paginator
              currentPage={this.state.currentPage}
              lastPage={this.state.totalPages}
              getInfo={(value) => this.fetchPageData(value)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  callForwardingStatus: state.topbar.call_forwarding_status,
  isOrgOutboundCallEnabled: state.topbar.isOrgOutboundCallEnabled,
  isOutboundCallEnabled: state.topbar.isOutboundCallEnabled,
  isUnansweredAgentEnabled: state.topbar.isUnansweredAgentEnabled,
  incomingCallsTableHeaders: state.recovery.incomingCallsTableHeaders,
});

export default commonStyle(
  connect(mapStateToProps, { setIncomingCallsTableHeaders })(CallLogsPage)
);
