import React, { useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { GET } from '../../../services/HttpRequests';
import moment from 'moment';
const recoveryCallUrl = process.env.REACT_APP_RECOVERY_CALL_API;
const cloudCallCenterUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;

export default function ExportRecoveryCallLogsPopup(props) {
  const [list, setList] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);

  let columns = [
    { id: 'initiatorName', displayName: 'INITIATOR NAME' },
    { id: 'initiatorNumber', displayName: 'INITIATOR NUMBER' },
    { id: 'destinationNumber', displayName: 'DESTINATION NUMBER' },
    { id: 'status', displayName: 'STATUS' },
    { id: 'duration', displayName: 'DURATION' },
    { id: 'created_at_date', displayName: 'DATE' },
    { id: 'created_at_time', displayName: 'TIME' },
    { id: 'disposition', displayName: 'CALL DISPOSITION' },
    { id: "callComment", displayName: "CALL COMMENT"},
    { id: "pulseCount", displayName: "PULSE COUNT" },
    { id: "recordingUrl", displayName: "RECORDING URL" },
    { id: "campaignName", displayName: "CAMPAIGN NAME"}

  ];

  const openPopup = () => {
    setIsPopupOpen(true);
    const organisationId = localStorage.getItem('doosra-biz-organisation-id');
    let data = { pageNo: '1', pageSize: '10000', organisationId };
    const sourceKeys = Object.keys(props.exportFilters);
    // Loop through the keys and add to the destination object if not already present
    for (const key of sourceKeys) {
      if (!data.hasOwnProperty(key)) {
        data[key] = props.exportFilters[key];
      }
    }
    GET(`${cloudCallCenterUrl}cloud-call-logs/get-calls`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        setList(res.callsData);
        setDataFetched(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <button className="Otherbutton" onClick={openPopup}>
        EXPORT
      </button>
      <Popup
        open={isPopupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Download call logs</p>
            <img
              className="PopupClose"
              src={Close}
              alt=""
              onClick={() => {
                setIsPopupOpen(false);
                setDataFetched(false);
              }}
            />
          </div>
          <div className="PopupBody">
            <p className="PopupInfo">
              The logs will be downloaded as a CSV file.
            </p>
            <p className="PopupNote">
              Max download limit - Recent 10,000 entries
            </p>
            {dataFetched ? (
              <CsvDownloader
                filename={`call_logs_${new Date().toLocaleDateString()}`}
                separator=","
                columns={columns}
                datas={
                  list.length
                    ? list.map((detail) => {
                        return {
                          initiatorName: detail?.user?.zvr_name,
                          initiatorNumber: detail.initiatorNumber,
                          destinationNumber: detail.destinationNumber,
                          status:
                            detail.status === 'not_answered'
                              ? 'Not Answered'
                              : 'Answered',
                          duration: detail.duration,
                          created_at_date: moment(detail.createdAt).format("DD-MM-YYYY"),
                          created_at_time: moment(detail.createdAt).format("HH:mm:ss"),
                          disposition: detail.disposition,
                          callComment: detail.callComment,
                          pulseCount: detail.pulseCount,
                          recordingUrl: detail.recordingUrl,
                          campaignName: detail.campaignName

                        };
                      })
                    : [{}]
                }
                className="ButtonFullWidth BgGreen"
                text="Download CSV"
              />
            ) : (
              <button
                className="ButtonFullWidth BgGrey"
                style={{ cursor: 'default' }}
              >
                SAVE
              </button>
            )}
          </div>
        </div>
      </Popup>
    </div>
  );
}
