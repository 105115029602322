import Sidebar from '../../../common/componentV2/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { commonStyle } from '../../../assets/css/css';
import { connect } from 'react-redux';
import Button from '../../../common/component/button/Button';
import CsvDownloader from 'react-csv-downloader';
import { useEffect, useState } from 'react';
import '../../../assets/css/dragAndDrop.css';
// import AddDID from '../component/AddDID';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import BackIcon from '../../../assets/images/backArrowBlue.png';
import { GET, POST, PUT } from '../../../services/HttpRequests';
import downloadIconGreen from '../../../assets/images/downloadIconGreen.png';
import cancelIcon from '../../../assets/images/cancelIconCircleGreen.png';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { AiFillPlusCircle } from 'react-icons/ai';
import { AiFillMinusCircle } from 'react-icons/ai';
const broadcastUrl = process.env.REACT_APP_BROADCAST_API;
const audioConversionUrl = process.env.REACT_APP_AUDIO_CONVERSION_API;
const organisationUrl = process.env.REACT_APP_ORGANIZATION_API;

const PopupContainer = {
  width: '400px',
};
const scrollEffect = {
  overflowY: 'auto',
};

const Label = (props) => {
  return (
    <label
      style={{
        marginBottom: '16px',
        width: '100%',
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#282828',
      }}
    >
      {props.children}
    </label>
  );
};

const Input = (props) => {
  return (
    <input
      style={{
        fontSize: '16px',
        width: '100%',
        padding: '8px',
        border: 'solid 1px #939598',
        borderRadius: '4px',
        marginBottom: '24px',
      }}
      placeholder={props.placeholder}
      type={props.type || 'text'}
      value={props.value}
      onChange={props.onChange}
    />
  );
};

const Checkbox = (props) => {
  return (
    <div style={{ marginBottom: '24px' }}>
      <input
        style={{ marginRight: '6px' }}
        type="checkbox"
        onChange={props.onChange}
        checked={props.checked}
        id="topping"
        name="topping"
        value="Paneer"
      />
      {props.label || '-'}
    </div>
  );
};

const SampleFile = () => {
  return (
    <>
      <CsvDownloader
        style={{
          cursor: 'pointer',
          textDecoration: 'none',
          textAlign: 'center',
          marginBottom: '16px',
        }}
        filename="broadcast_list"
        datas={[
          {
            name: 'Alice',
            number: '1234567890',
            personalText: 'Welcome To Alohaa',
          },
          {
            name: 'Joe',
            number: '2323232323',
            personalText: 'Thank You',
          },
        ]}
      >
        <a
          style={{
            color: '#13ba0c',
            fontSize: '14px',
            fontWeight: 500,
          }}
        >
          Download Sample Sheet{' '}
          <img
            src={downloadIconGreen}
            alt=""
            style={{ margin: '0 0 3px 8px' }}
          ></img>
        </a>
      </CsvDownloader>

      <div
        style={{
          width: '100%',
          height: '56px',
          fontSize: '12px',
          color: '#939598',
          marginBottom: '24px',
          backgroundColor: '#f4f4f4',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        Download the sample sheet and fill it in the
        <br />
        same format without changing rows or columns
      </div>
    </>
  );
};

const Day = (props) => {
  return (
    <div
      className={
        props.weekDays.includes(props.thisDay) ? 'DaySelected' : 'DayDefault'
      }
      onClick={() => {
        props.handleWeekDaySelection(props.thisDay);
      }}
    >
      {props?.thisDay?.charAt(0)}
    </div>
  );
};

export function CreateVoiceBroadcast(props) {
  const [smsCampaignType, setSmsCampaignType] = useState(false);
  const [voiceCampaignType, setVoiceCampaignType] = useState(true);
  const [smsTemplateId, setSmsTemplateId] = useState();
  const [smsTemplateText, setSmsTemplateText] = useState('');
  const [smsTemplatesList, setSmsTemplatesList] = useState([]);
  const [smsTemplatesValueList, setSmsTemplatesValueList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [personalisedAudioUrlPrefix, setPersonalisedAudioUrlPrefix] =
    useState('');
  const [maxRetries, setmaxRetries] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [agents, setAgents] = useState([]);
  // const [DID, setDID] = useState(null);
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [audio, setAudio] = useState(null);
  // const [addDIDPopup, setAddDIDPopup] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [uploading, setUploading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const [scheduleTime, setScheduleTime] = useState(null);
  const [category, setCategory] = useState('STANDARD');
  const [categoryConfig, setCategoryConfig] = useState([
    { key: '', label: '' },
  ]);
  const [voicebroadcastDids, setVoicebroadcastDids] = useState([]);
  const [selectedDid, setSelectedDid] = useState();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const edit = searchParams.get('edit');
  const duplicate = searchParams.get('duplicate');

  const handleFetchCampaignDetails = (id) => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId: id,
    };
    setIsLoading(true);
    GET(broadcastUrl + 'v1/voice-broadcast', data)
      .then((res) => {
        const {
          campaignName,
          agentsData,
          campaignDescription,
          campaignStartDate,
          campaignEndDate,
          weekDays,
        } = res?.data?.response;
        setName(campaignName);
        setDescription(campaignDescription);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const fetchVoiceBroadcastDids = async () => {
    setIsLoading(true);
    const data = {
      pageNo: 0,
      pageSize: 25,
      organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
    };
    GET(organisationUrl + 'v1/organisation/voice-broad-cast/did-numbers', data)
      .then((res) => {
        const items = res?.data?.response?.items;
        const dids = items
          .filter((item) => item?.type === 'DID')
          .map((item) => item?.vmn);
        setVoicebroadcastDids(dids);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.error?.reason ||
            'error occured while fetching voiceBroadcastDids!'
        );
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(false);
    if (edit === 'true' || duplicate === 'true') {
      handleFetchCampaignDetails(id);
    }
  }, [edit, duplicate, id]);

  useEffect(() => {
    fetchVoiceBroadcastDids();
  }, []);

  const submitFormHandler = async () => {
    try {
      if (uploading) return;
      console.log(scheduleTime);
      if (category === 'SURVEY') {
        let error = '';
        let existingKeys = [];
        let existingLabels = [];
        for (let i of categoryConfig) {
          if (!i.key || !i.label) {
            error = 'Key Value pairs are missing';
            break;
          }
          if (existingKeys.includes(i.key)) {
            error = 'Duplicates Keys are present';
            break;
          }
          if (existingLabels.includes(i.label)) {
            error = 'Duplicates Labels are present';
            break;
          }
          existingKeys.push(i.key);
          existingLabels.push(i.label);
        }
        if (error) {
          toast.error(error);
          return;
        }
      }

      if (!name) {
        toast.error('Campaign name is required');
        return;
      }

      if (!file && edit !== 'true') {
        toast.error('Please upload one lead list for campaign');
        return;
      }

      if (!audio) {
        toast.error('audio file is required');
        return;
      }

      // if (voiceCampaignType && !DID.virtualNumber) {
      //   toast.error('Please select a DID');
      //   return;
      // }

      if (isScheduled && !scheduleTime) {
        toast.error('Please select schedule time');
        return;
      }

      setUploading(true);
      let formData = new FormData();
      formData.append(
        'organisationId',
        localStorage.getItem('doosra-biz-organisation-id')
      );
      formData.append('name', name);
      // formData.append('smsCampaignType', smsCampaignType);
      // formData.append('voiceCampaignType', voiceCampaignType);
      // formData.append('smsTemplateId', smsTemplateId);
      formData.append('description', description);
      formData.append('maxRetries', maxRetries);
      formData.append('personalisedAudioUrlPrefix', personalisedAudioUrlPrefix);
      formData.append('isScheduled', isScheduled);
      if (isScheduled) {
        formData.append('scheduledTime', scheduleTime);
      }
      if (selectedDid?.length > 0) {
        formData.append('did', selectedDid);
      }
      formData.append('leadLists', file);
      formData.append('audio', audio);
      formData.append('category', category);

      if (category === 'SURVEY') {
        formData.append('categoryConfig', JSON.stringify(categoryConfig));
      }
      // for (let i = 0; i < files.length; i++) {

      // }

      if (edit === 'true') {
        for (let entry of formData.entries()) {
          console.log(entry[0], entry[1]);
        }
        await PUT(`${broadcastUrl}v1/voice-broadcast?id=${id}`, {}, formData, {
          'Content-Type': 'multipart/form-data',
        });
        toast.success(`Succesfully edited Campaign`);
      } else {
        const form = new FormData();
        form.append('audio', audio);
        form.append('destinationType', 'VOICE_BROADCAST');
        const res = await POST(
          `${audioConversionUrl}v1/audio/from-file-upload`,
          form,
          {}
        );
        const audioUrl = res.data.response.audioUrl;
        formData.append('audioUrl', audioUrl);
        await POST(`${broadcastUrl}v1/voice-broadcast`, formData, {
          'Content-Type': 'multipart/form-data',
        });
        toast.success(`Succesfully added Campaign`);
      }

      setUploading(false);
      history.push(`/voice-broadcasts-list`);
    } catch (err) {
      setUploading(false);
      console.log(err);
      toast.error(err?.response?.data?.error?.reason || 'An error occured');
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    const file = e.dataTransfer.files[0];
    // do something with the files, e.g. upload to server

    // const updatedFiles = [...files];
    // for (let i = 0; i < fileList.length; i++) {
    //   updatedFiles.push(fileList[i]);
    // }

    setFile(file);
    // clear the input field to allow uploading of the same file again
    e.target.value = '';
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    event.target.value = '';
    // onFilesSelected(updatedFiles);
  };

  const handleRemoveFile = (fileToRemove) => {
    setFile(null);
    // onFilesSelected(updatedFiles);
  };

  // const handleWeekDaySelection = (day) => {
  //   if (weekDays.includes(day)) {
  //     setWeekDays(weekDays.filter((val) => val !== day));
  //   } else {
  //     setWeekDays([...weekDays, day]);
  //   }
  // };

  const cancelCampaignCreation = () => {
    history.push('/voice-broadcasts-list');
  };

  const handleAddCategoryConfig = () => {
    setCategoryConfig([...categoryConfig, { key: '', label: '' }]);
  };
  const handleRemoveCategoryConfig = (currentItemIndex) => {
    const updateCategoryConfig = categoryConfig
      .slice(0, currentItemIndex)
      .concat(categoryConfig.slice(currentItemIndex + 1));
    setCategoryConfig(updateCategoryConfig);
  };
  const handleEditCategoryConfig = (
    index,
    newKey,
    newLabel,
    newCallForwardTo
  ) => {
    const updatedCategoryConfig = [...categoryConfig];
    updatedCategoryConfig[index].key = newKey;
    updatedCategoryConfig[index].label = newLabel;
    updatedCategoryConfig[index].callForwardTo = newCallForwardTo;
    setCategoryConfig(updatedCategoryConfig);
  };

  return (
    <div className={`${props.className}`}>
      <Topbar />
      <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
        <Sidebar />
        <div className="ContentContainer">
          <TabSearchBar>
            <div
              style={{
                marginLeft: '24px',
                display: 'flex',
                gap: '24px',
                alignItems: 'center',
              }}
            >
              <img
                src={BackIcon}
                style={{ cursor: 'pointer' }}
                onClick={cancelCampaignCreation}
                alt=""
              />
              <p
                style={{
                  margin: 0,
                  color: '#00515a',
                  fontSize: '24px',
                  fontWeight: 500,
                }}
              >
                {duplicate === 'true' ? (
                  <>Duplicating Campaign</>
                ) : edit === 'true' ? (
                  <>Edit Campaign</>
                ) : (
                  <>Create a Voice Broadcast Campaign</>
                )}
              </p>
            </div>
          </TabSearchBar>
          {isLoading && <PreLoader />}
          {uploading && <PreLoader />}
          <Popup open={isOpen}>
            <img
              style={{ left: '96%', top: '-5%', zIndex: '1' }}
              alt=""
              className="PopupClose"
              src={Close}
              onClick={() => {
                setIsOpen(false);
              }}
            />
            <div
              className="PopupContainer"
              style={{ ...PopupContainer, ...scrollEffect }}
            >
              <div
                className="PopupHeaderContainer"
                style={{ ...PopupContainer }}
              >
                <p>Create Broadcast Confirmation</p>
              </div>
              <div className="PopupBody" style={{ ...PopupContainer }}>
                <p className="PopupBodyTitle">
                  The broadcast campaign will start on creation. Are you sure
                  you want to create the broadcast?
                </p>
                <p className="PopupNote">
                  Note: Calls are permitted solely from 9 am to 9 pm; any calls
                  outside of this timeframe will be placed in a queue.
                </p>
                <button
                  className="ButtonCancelFullWidth"
                  style={{ marginTop: '16px' }}
                  onClick={submitFormHandler}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Popup>
          {/* {!isLoading && ( */}
          <div
            style={{ width: '594px', marginLeft: '40px', marginTop: '40px' }}
          >
            <Label>Campaign Category</Label>
            <div
              className="radio-button-group"
              style={{ display: 'flex', gap: '16px' }}
            >
              <label>
                <input
                  type="radio"
                  value="standard"
                  checked={category === 'STANDARD'}
                  onClick={() => {
                    setCategoryConfig([{ key: '', label: '' }]);
                    setCategory('STANDARD');
                  }}
                  name="voice-broadcast"
                  style={{ marginRight: '5px' }}
                />
                Standard
              </label>
              <label>
                <input
                  type="radio"
                  value="survey"
                  checked={category === 'SURVEY'}
                  onClick={() => setCategory('SURVEY')}
                  name="voice-broadcast"
                  style={{ marginRight: '5px' }}
                />
                Survey
              </label>
            </div>
            {category === 'SURVEY' && (
              <div style={{ marginBottom: '20px' }}>
                <div style={{ marginTop: '3px' }}>
                  {categoryConfig.map((item, index) => (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div
                        style={{ display: 'flex', gap: '10px', width: '479px' }}
                      >
                        <Input
                          placeholder="Enter Key..."
                          value={item.key}
                          style={{ margin: 0, padding: '5px' }}
                          onChange={(e) =>
                            handleEditCategoryConfig(
                              index,
                              e.target.value,
                              item.label,
                              item.callForwardTo
                            )
                          }
                        />
                        <Input
                          placeholder="Enter Label..."
                          value={item.label}
                          style={{ margin: 0, padding: '5px' }}
                          onChange={(e) =>
                            handleEditCategoryConfig(
                              index,
                              item.key,
                              e.target.value,
                              item.callForwardTo
                            )
                          }
                        />
                        <Input
                          placeholder="CallForwardTo..."
                          value={item.callForwardTo}
                          style={{ margin: 0, padding: '5px' }}
                          onChange={(e) =>
                            handleEditCategoryConfig(
                              index,
                              item.key,
                              item.label,
                              e.target.value
                            )
                          }
                        />
                        <span>
                          <AiFillMinusCircle
                            style={{ marginTop: '8px', cursor: 'pointer' }}
                            size="27px"
                            color="black"
                            onClick={() => handleRemoveCategoryConfig(index)}
                          />
                        </span>
                      </div>
                    </div>
                  ))}
                  <br />
                </div>
                {/* <div style={{ marginTop: "-10px" }}> */}
                <button
                  className="btn btn-success"
                  style={{
                    margin: '-10px 0px 0px 10px',
                    borderRadius: '10px',
                    fontWeight: '500',
                  }}
                  onClick={handleAddCategoryConfig}
                >
                  <AiFillPlusCircle style={{ margin: '0px 1px 3px 0px' }} /> Add
                  Key-Labels
                </button>
                {/* </div> */}
              </div>
            )}
            <Label>Campaign Title</Label>
            <input
              style={{
                fontSize: '16px',
                width: '100%',
                padding: '8px',
                border: 'solid 1px #939598',
                borderRadius: '4px',
                marginBottom: '24px',
              }}
              placeholder="Enter the campaign title here…"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={edit === 'true' ? true : false}
            />
            <Label>Campaign Description (optional)</Label>
            <Input
              placeholder="Enter the campaign description here…"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            {
              <>
                <div>
                  <Label>
                    Retries Count <br />
                  </Label>
                  <span>Un-answered leads will be called immediately</span>
                </div>
                <Input
                  type="number"
                  placeholder="Enter No. of MaxRetries here.."
                  value={maxRetries}
                  onChange={(e) => setmaxRetries(e.target.value)}
                  min={0}
                  max={3}
                />
                <Label>Personalised Audio Url Prefix</Label>
                <Input
                  placeholder="Enter Personalised Audio Url Prefix"
                  value={personalisedAudioUrlPrefix}
                  onChange={(e) =>
                    setPersonalisedAudioUrlPrefix(e.target.value)
                  }
                />
                <Label>Broadcast Type</Label>
                <div style={{ display: 'flex', gap: '16px' }}>
                  <Checkbox
                    label="Immediate"
                    checked={!isScheduled}
                    onChange={() => setIsScheduled(false)}
                  />
                  <Checkbox
                    label="Scheduled"
                    checked={isScheduled}
                    onChange={() => setIsScheduled(true)}
                  />
                </div>
                {isScheduled && (
                  <>
                    <Label>Broadcast Time</Label>
                    <Input
                      placeholder="Scheduled Time"
                      value={scheduleTime}
                      type="datetime-local"
                      onChange={(e) => setScheduleTime(e.target.value)}
                    />
                  </>
                )}
                <div
                  style={{
                    marginTop: '0px',
                    marginBottom: '15px',
                  }}
                >
                  <Label>Select Virtual No</Label>
                  <div style={{ display: 'flex' }}>
                    <select
                      className="PopupInput"
                      style={{
                        padding: 8,
                        width: '70%',
                      }}
                      id="selectedDid"
                      onChange={(e) => {
                        setSelectedDid(e.target.value);
                      }}
                      value={selectedDid}
                    >
                      <option value="" selected>
                        Select
                      </option>
                      {console.log(selectedDid)}
                      {voicebroadcastDids &&
                        voicebroadcastDids.map((item) => (
                          <option value={item._id}>{item}</option>
                        ))}
                    </select>
                    <div
                      style={{ width: 500, overflow: 'scroll', marginLeft: 10 }}
                    >
                      {selectedDid}
                    </div>
                  </div>
                </div>
                <Label>Add Leads</Label>
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: '16px',
                    color: '#939598',
                    marginTop: '-8px',
                  }}
                >
                  You can add a single CSV file to a campaign
                </p>
                {edit === 'true' && (
                  <div style={{ display: 'flex' }}>
                    <ul style={{ listStyleType: 'none', padding: '0' }}>
                      <li>
                        <p
                          style={{
                            width: '595px',
                            height: '48px',
                            backgroundColor: 'rgba(7, 170, 63, 0.16)',
                            borderRadius: '4px',
                            textDecoration: 'none',
                            margin: 0,
                            fontSize: '16px',
                            fontWeight: 500,
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px',
                          }}
                        >
                          oldLeads
                        </p>
                      </li>
                      <li>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <span
                            style={{
                              color: 'blue',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              history.push(
                                `/recovery-x-campaign/leads?campaignId=${id}&edit=true`
                              );
                            }}
                          >
                            Preview
                          </span>
                        </div>
                      </li>
                    </ul>
                    {/* <Button
                  style={{ marginBottom: '8px' }}
                  label="PREVIEW"
                /> */}
                  </div>
                )}
                <ul style={{ listStyleType: 'none', padding: '0' }}>
                  {file && (
                    <li
                      key={1}
                      style={{
                        display: 'flex',
                        gap: '16px',
                        marginBottom: '8px',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          width: '562px',
                          height: '48px',
                          backgroundColor: 'rgba(7, 170, 63, 0.16)',
                          borderRadius: '4px',
                          textDecoration: 'none',
                          margin: 0,
                          fontSize: '16px',
                          fontWeight: 500,
                          display: 'flex',
                          alignItems: 'center',
                          padding: '10px',
                        }}
                      >
                        {file.name}
                      </p>
                      <img
                        src={cancelIcon}
                        onClick={() => handleRemoveFile(file)}
                      />
                    </li>
                  )}
                </ul>
                <div
                  className={`drag-and-drop ${dragging ? 'dragging' : ''}`}
                  style={{ width: '100%' }}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  // onClick={() => document.getElementById('fileInput').click()} // added this line to click the file input element
                  onChange={handleFileChange}
                >
                  <input
                    className="dnd-file-input"
                    type="file"
                    accept=".csv"
                    id="fileInput"
                    // onChange={handleFileChange}
                  />
                  <label className="DnD-label" htmlFor="fileInput">
                    Drag and drop files here or click to select files
                  </label>
                </div>
                <SampleFile />
                <Label>Upload Audio File </Label>
                <input
                  type="file"
                  style={{ marginBottom: '12px' }}
                  accept=".wav"
                  onChange={(e) => {
                    setAudio(e.target.files[0]);
                  }}
                />
                <p
                  style={{
                    fontWeight: 500,
                    fontSize: '16px',
                    color: '#939598',
                    marginTop: '-8px',
                  }}
                >
                  Only .wav file is supported
                  <br />
                  Max size - 1 MB, Max audio duration 3mins
                </p>
              </>
            }
            {/* <Label>Select DID</Label>
            {DID?.virtualNumber && <p>DID - {DID?.virtualNumber}</p>}
            <p
              style={{
                fontWeight: 500,
                fontSize: '16px',
                color: '#939598',
                marginTop: '-8px',
              }}
            >
              A DID is the number that shows up on a devices caller ID
            </p>

            <Button
              label={'ADD DIDs'}
              style={{ color: '#196cca' }}
              onClick={() => setAddDIDPopup(true)}
            />
            <AddDID
              open={addDIDPopup}
              close={() => setAddDIDPopup(false)}
              setDID={setDID}
              agents={agents}
            /> */}
            <div
              style={{
                marginTop: '40px',
                display: 'flex',
                gap: '16px',
                marginBottom: '50px',
              }}
            >
              <Button
                label={edit === 'true' ? 'EDIT CAMPAIGN' : 'SAVE CAMPAIGN'}
                style={{ backgroundColor: '#196cca', color: '#ffffff' }}
                onClick={() => setIsOpen(true)}
              />
              <Button
                onClick={cancelCampaignCreation}
                label="CANCEL"
                style={{ color: '#ed3833', borderColor: '#ed3833' }}
              />
            </div>
          </div>
          {/* )} */}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  userDetails: state.recovery.userDetails,
});

export default commonStyle(connect(mapStateToProps, {})(CreateVoiceBroadcast));
