/* eslint-disable */
import React, { Component } from 'react';
import styled from '@emotion/styled';

import '../../../assets/css/SearchBar.css';
import AddUsersPopup from '../../../pages/Users/component/AddUsersPopup';
import AddContactPopup from '../../../pages/contacts/component/AddContactPopup';
import AddAutoAttendantPopup from '../../../pages/autoAttendant/component/AddAutoAttendantPopup';
import UploadContactPopup from '../../../pages/contacts/component/UploadContactPopup';
import AddLeadListPopup from '../../../pages/recoveryCalling/component/AddLeadListPopup';
import PopulateLeadLists from '../../../pages/recoveryCalling/component/PopulateLeadLists';
import ExportCallLogsPopup from '../../../pages/collLogs/components/ExportCallLogsPopup';
import ExportDIDCallBackLogsPopup from '../../../pages/collLogs/components/ExportDIDCallBackLogsPopup';
import ExportSMSPopup from '../../../pages/smsInbox/container/component/ExportSMSPopup';
import WhiteListSenderIdsPopup from '../../../pages/smsInbox/container/component/WhiteListSenderIdsPopup';
import BindPopUp from '../../../pages/NumberMasking/component/bindPopUp';
import ExportRecoveryLogsPopup from '../../../pages/recoveryCalling/component/ExportRecoveryLogsPopup';
import ExportRecoveryCallLogsPopup from '../../../pages/recoveryCalling/component/ExportCallLogsPopup';
import ExportOutGoingCallLogsPopup from '../../../pages/collLogs/components/ExportOutGoingCallLogsPopup';
import DeleteLeadListPopup from '../../../pages/recoveryCalling/component/DeleteLeadListPopup';
import IconFeatherArrowUpRight from '../../../assets/images/IconFeatherArrowUpRight.webp';
import DispositionsPopup from '../../../pages/recoveryCalling/component/AddDispositions';
import PopTwo from '../../../pages/recoveryCalling/component/BatchCallPopup';
import { Button } from 'react-bootstrap';
import ExportRecoveryAgents from '../../../pages/recoveryCalling/component/ExportRecoveryAgents';
import ExportContactsPopup from '../../../pages/contacts/component/ExportContactsPopup';
import { MenuItem, Select } from '@material-ui/core';
import DeleteMessagePopup from '../../../pages/smsInbox/component/DeleteMessagePopup';
import DispositionsRxPopup from '../../../pages/recoveryX/component/AddDispositions';
import DiscreteCallPopup from '../../../pages/recoveryX/component/DiscreteCallPopup';
import CCDispositionsRxPopup from '../../../pages/cloudCallCenter/component/AddDisposition';
import AddVerifiedContactPopup from '../../../pages/trailPeriod/component/AddVerifiedContactPopup';
import { Link } from 'react-router-dom';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import ModifyHeadersPopUp from '../../../pages/cloudCallCenter/component/ModifyHeadersPopUp';
import TableHeaders from '../TableHeaders/TableHeaders';

class SearchBar extends Component {
  constructor() {
    super();
    this.state = {
      searchBy: '',
      fromDate: '',
      toDate: '',
      searchValue: '',
      btnOnReset: false,
      userRole: localStorage.getItem('doosra-biz-user-role'),
    };
    this.initialState = this.state;
  }

  changeHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value, btnOnReset: false });
  };

  dropDownSelectHandler = (e) => {
    this.setState({
      searchBy: e.target.value,
      btnOnReset: false,
      searchValue: '',
    });
  };

  onSearch = () => {
    let data = {};
    this.setState({ btnOnReset: true });
    if (this.state.searchBy === 'date') {
      if (this.state.fromDate === '' || this.state.toDate === '') return;
      data.searchKey = 'date';
      data.search_key = 'date';
      data.from_date = this.state.fromDate;
      data.to_date = this.state.toDate;
    } else {
      if (this.state.searchBy === '' || this.state.searchValue === '') {
        this.props.reset();
        return;
      }
      data.searchKey = this.state.searchBy;
      data.search_key = this.state.searchBy;
      data.q = this.state.searchValue;
      data.searchCondition = this.state.searchValue;
    }
    this.props.search(data);
    this.setState({ btnOnReset: true });
  };

  onReset = () => {
    this.setState(this.initialState);
    this.props.reset();
  };

  resetBtnHandler = (value) => {
    this.setState({ btnOnReset: value });
  };

  render() {
    let searchInput;
    const { disableTitle, isFreeFormEnabled } = this.props;
    switch (this.state.searchBy) {
      case 'date':
        searchInput = (
          <React.Fragment>
            <input
              id="fromDate"
              value={this.state.fromDate}
              onChange={this.changeHandler}
              className="SearchBox"
              style={{ padding: '0px' }}
              type="date"
            />
            <input
              id="toDate"
              value={this.state.toDate}
              onChange={this.changeHandler}
              style={{ padding: '0px' }}
              className="SearchBox"
              type="date"
            />
          </React.Fragment>
        );
        break;
      case 'bindingValidity':
        searchInput = (
          <React.Fragment>
            <input
              id="fromDate"
              value={this.state.fromDate}
              onChange={this.changeHandler}
              className="SearchBox"
              style={{ padding: '0px' }}
              type="date"
            />
            <input
              id="toDate"
              value={this.state.toDate}
              onChange={this.changeHandler}
              style={{ padding: '0px' }}
              className="SearchBox"
              type="date"
            />
          </React.Fragment>
        );
        break;
      case 'bindingStatus':
        searchInput = (
          <select
            className="SearchDropdown"
            id="searchValue"
            onChange={this.changeHandler}
            value={this.state.searchValue}
          >
            <option value="">--select--</option>
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        );

        break;
      case 'groupType':
        searchInput = (
          <select
            className="SearchDropdown"
            id="searchValue"
            onChange={this.changeHandler}
            value={this.state.searchValue}
          >
            <option value="">--select--</option>
            <option value="INCOMING">In Coming</option>
            <option value="OUTGOING">Out Going</option>
            <option value="NONE">None</option>
          </select>
        );

        break;
      case 'current_status':
        searchInput = (
          <select
            className="SearchDropdown"
            id="searchValue"
            onChange={this.changeHandler}
            value={this.state.searchValue}
          >
            <option value="">--select--</option>
            <option value="ACTIVE">ACTIVE</option>
            <option value="ASSIGNED">ASSIGNED</option>
            <option value="INACTIVE">INACTIVE</option>
          </select>
        );

        break;
      case 'call_type':
        searchInput = (
          <select
            className="SearchDropdown"
            id="searchValue"
            onChange={this.changeHandler}
            value={this.state.searchValue}
          >
            <option value="">--select--</option>
            <option value="Answered">ANSWERED</option>
            <option value="Missed">MISSED</option>
            {this.props.type !== 'did-call-backs' && (
              <option value="Blocked">BLOCKED</option>
            )}
          </select>
        );
        break;
      case 'call_status':
        searchInput = (
          <select
            className="SearchDropdown"
            id="searchValue"
            onChange={this.changeHandler}
            value={this.state.searchValue}
          >
            <option value="">--select--</option>
            <option value="^answered$">Answered</option>
            <option value="not_answered">Not Answered</option>
            <option value="NOT_INITIATED">Not Called</option>
          </select>
        );
        break;
      case 'recieved_on':
        searchInput = (
          <select
            className="SearchDropdown"
            id="searchValue"
            onChange={this.changeHandler}
            value={this.state.searchValue}
          >
            <option value="">--select--</option>
            <option value="vmn">VMN</option>
            <option value="did">DID</option>
          </select>
        );
        break;
      case 'status':
        searchInput = (
          <select
            className="SearchDropdown"
            id="searchValue"
            onChange={this.changeHandler}
            value={this.state.searchValue}
          >
            <option value="">--select--</option>
            <option value="answered">Answered</option>
            <option value="not_answered">Unanswered</option>
          </select>
        );
        break;
      case 'recieved_on':
        searchInput = (
          <select
            className="SearchDropdown"
            id="searchValue"
            onChange={this.changeHandler}
            value={this.state.searchValue}
          >
            <option value="">--select--</option>
            <option value="vmn">VMN</option>
            <option value="did">DID</option>
          </select>
        );
        break;
      default:
        searchInput = (
          <input
            id="searchValue"
            value={this.state.searchValue}
            onChange={this.changeHandler}
            className="SearchBox"
            placeholder="Search..."
          />
        );
        break;
    }
    return (
      <div className={`${this.props.className}`}>
        <div className="SearchBarComponent">
          {!disableTitle && this.props.type !== 'campaign-agent' && (
            <div className="SelectedContainer">
              {this.props.type === 'users' ? (
                <div className="Tabs">
                  <h6>AGENT LIST</h6> <p>{this.props.count}</p>
                </div>
              ) : null}
              {this.props.type === 'sms' ? (
                <div className="Tabs">
                  <h6>SMS</h6>
                  <p>{this.props.count}</p>
                </div>
              ) : null}
              {this.props.type === 'contacts' ? (
                <div className="Tabs">
                  <h6>Contacts</h6>
                  <p>{this.props.count}</p>
                </div>
              ) : null}
              {this.props.type === 'autoAttendant' ? (
                <div className="Tabs">
                  <h6>Auto Attendant</h6>
                  <p>{this.props.count}</p>
                </div>
              ) : null}
              {this.props.type === 'trailPeriod' ? (
                <h6>Verified Contacts</h6>
              ) : null}
              {this.props.type === 'recoveryCalling' ? <h6>Leads</h6> : null}
              {this.props.type === 'doosraNumbers' ? (
                <h6>NUMBER LIST</h6>
              ) : null}

              {this.props.type === 'calls' ? <h6>CALLS</h6> : null}
              {this.props.type === 'ivr' && (
                <>
                  <h6>Groups</h6>
                  <p>{this.props.count}</p>
                </>
              )}
            </div>
          )}
          <div style={{ flex: '1' }}>
            {this.props.type === 'users' ? (
              <AddUsersPopup
                refreshList={this.props.refreshList}
                basicUserDownloadDetail={this.props.basicUserDownloadDetail}
                managersData={this.props.managersData}
                isWebrtcEnabled={
                  this.props.isWebrtcEnabled ||
                  this.props.isCCWebrtcEnabled ||
                  this.props.isWebRtcCallSettingEnabled
                }
              />
            ) : null}

            {this.props.type === 'trailPeriod' &&
            this.state.userRole === 'ADMIN' ? (
              <AddVerifiedContactPopup
                sendOtpVerifiedContact={(e) =>
                  this.props.sendOtpVerifiedContact(e)
                }
                verifyOtpVerifiedContact={(e) =>
                  this.props.verifyOtpVerifiedContact(e)
                }
                addContactHandler={(e) => this.props.addContactHandler(e)}
              />
            ) : null}
            {this.props.type === 'autoAttendant' &&
            this.state.userRole === 'ADMIN' ? (
              <AddAutoAttendantPopup
                addAutoAttendantHandler={(e) =>
                  this.props.addAutoAttendantHandler(e)
                }
              />
            ) : null}

            {this.props.type === 'contacts' && (
              <div style={{ display: 'flex' }}>
                {this.state.userRole === 'ADMIN' && (
                  <>
                    <UploadContactPopup
                      usersList={this.props.usersList}
                      refreshList={this.props.refreshList}
                    />
                    <ExportContactsPopup usersList={this.props.usersList} />
                  </>
                )}
                {this.state.userRole === 'USER' && (
                  <AddContactPopup
                    addContactHandler={(e) => this.props.addContactHandler(e)}
                    getList={(e) => this.props.refreshList(e)}
                  />
                )}
              </div>
            )}
            {this.props.type === 'recoveryCalling' &&
            this.state.userRole === 'ADMIN' ? (
              <div style={{ display: 'flex' }}>
                <AddLeadListPopup
                  addLeadListHandler={(e) => this.props.addLeadListHandler(e)}
                  addBulkLeadListHandler={(e) =>
                    this.props.addBulkLeadListHandler(e)
                  }
                />
                <ExportRecoveryLogsPopup leadListId={this.props.leadListId} />
                <DeleteLeadListPopup
                  leadLists={this.props.leadLists}
                  deleteLeadList={(e) => this.props.deleteLeadList(e)}
                  toggleLeadListVisibility={(e, f) =>
                    this.props.toggleLeadListVisibility(e, f)
                  }
                />
                <DispositionsPopup
                  refreshList={this.props.refreshList}
                  dispositions={this.props.dispositions}
                />
              </div>
            ) : this.props.type === 'recoveryCalling' &&
              this.state.userRole === 'USER' ? (
              <div style={{ display: 'flex' }}>
                {/* <button
                className="Otherbutton"
                style={{ width: 200 }}
                onClick={() => this.props.batchCallInit()}
              >
                Start Calling Leads <img src={IconFeatherArrowUpRight} alt={"Arrow Up"}></img>
              </button> */}
                <PopTwo
                  selectedAgentStatus={this.props.selectedAgentStatus}
                  agentMetric={this.props.agentMetric}
                  batchCallHandler={this.props.batchCallInit}
                  dispositions={this.props.dispositions}
                  filterAndInitiatebatchCallHandler={
                    this.props.filterAndInitiatebatchCallHandler
                  }
                ></PopTwo>
                <button
                  className="SearchButton"
                  style={{
                    width: 200,
                    backgroundColor:
                      this.props.selectedAgentStatus !== 'active' &&
                      this.props.agentMetric
                        ? 'lightgrey'
                        : '#ed3833',
                  }}
                  onClick={() => this.props.stopBatchCall()}
                  disabled={
                    this.props.selectedAgentStatus !== 'active' &&
                    this.props.agentMetric
                  }
                >
                  Stop Calling Leads
                </button>
              </div>
            ) : null}
            {this.props.exportReport === 'agentReport' &&
              this.state.userRole === 'ADMIN' && (
                <ExportRecoveryAgents
                  agentList={this.props.agentList}
                  fromDate={this.props.fromDate}
                  toDate={this.props.toDate}
                />
              )}
            {this.props.type === 'ivr' && (
              <div style={{ display: 'flex' }}>
                <span>
                  <button
                    className="Otherbutton"
                    style={{ marginRight: 0 }}
                    onClick={this.props.onClickAddGroup}
                    disabled={!isSuperAdminWithPermission('ADD')}
                  >
                    ADD GROUP
                  </button>
                </span>
              </div>
            )}
            {this.props.type === 'recovery-call-logs' &&
              this.state.userRole === 'ADMIN' && (
                <ExportRecoveryCallLogsPopup
                  exportFilters={this.props.exportFilters || {}}
                />
              )}
            {/* {this.props.type === 'sms' && (
              <>
                {this.props.deleteAction !== 0 ? (
                  <DeleteMessagePopup
                    count={this.props.deleteAction}
                    deleteMessageHandler={this.props.deleteMessageHandler}
                  />
                ) : (
                  <div style={{ display: 'flex' }}>
                    <ExportSMSPopup />{' '}
                    {this.props.isSenderWhiteListEnabled &&
                      this.state.userRole === 'ADMIN' && (
                        <WhiteListSenderIdsPopup />
                      )}{' '}
                  </div>
                )}
              </>
            )} */}
            {(this.props.type === 'number-masking-bindings' ||
              this.props.type === 'number-masking-calls' ||
              this.props.type === 'number-masking-dids') && (
              <div style={{ display: 'flex' }}>
                {
                  <BindPopUp
                    reloadData={
                      this.props.type === 'number-masking-bindings'
                        ? this.props.reloadBindings
                        : null
                    }
                    isFreeFormEnabled={isFreeFormEnabled}
                  />
                }
              </div>
            )}
            {this.props.type === 'calls' && (
              <div style={{ display: 'flex' }}>
                <ExportCallLogsPopup />
                <TableHeaders {...this.props} />
              </div>
            )}
            {this.props.type === 'did-call-backs' && (
              <ExportDIDCallBackLogsPopup />
            )}
            {this.props.type === 'outgoing-calls' && (
              <div style={{ display: 'flex' }}>
                <ExportOutGoingCallLogsPopup />
                <TableHeaders {...this.props} />
              </div>
            )}

            {this.props.type === 'campaign-agent' &&
              this.state.userRole === 'USER' &&
              this.props.isPlaceACallButtonVisible === true && (
                <DiscreteCallPopup
                  open={this.state.isModalOpen}
                  toggle={this.props.toggleModal}
                  initiateRecoveryCall={(e) =>
                    this.props.initiateRecoveryCall(e)
                  }
                  selectedAgentStatus={this.props.agentStatus}
                ></DiscreteCallPopup>
              )}
            {this.props.type === 'campaign-agent' &&
              this.state.userRole === 'USER' &&
              (this.props.dailerType === 'dynamic' ||
                this.props.subType === 'static') &&
              !this.props.hideTableHeaders && (
                <ModifyHeadersPopUp {...this.props} />
              )}
            {this.props.type === 'recovery-x-campaign' &&
              this.state.userRole === 'ADMIN' && (
                <div style={{ display: 'flex' }}>
                  {this.props.subType === 'cloudCall' ? (
                    <>
                      <Link to="/create-campaign">
                        <button className="Otherbutton">ADD CAMPAIGN</button>
                      </Link>
                      <CCDispositionsRxPopup
                        refreshList={this.props.refreshList}
                        dispositions={this.props.dispositions}
                      />
                    </>
                  ) : (
                    <>
                      <Link to="/recovery-x-create-campaign">
                        <button className="Otherbutton">ADD CAMPAIGN</button>
                      </Link>
                      <DispositionsRxPopup
                        refreshList={this.props.refreshList}
                        dispositions={this.props.dispositions}
                      />
                    </>
                  )}
                </div>
              )}
          </div>
          <div style={{ flex: 1 }} />
          {this.props.type !== 'recoveryCalling' &&
            this.props.optional !== 'campaigns' &&
            this.props.type !== 'report' &&
            this.props.type !== 'recovery-x-campaign' &&
            !isFreeFormEnabled && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div className="SearchText">SEARCH BY</div>
                <select
                  className="SearchDropdown"
                  id="searchBy"
                  value={this.state.searchBy}
                  onChange={this.dropDownSelectHandler}
                >
                  <option>Select</option>
                  {this.props.type === 'users' ? (
                    <React.Fragment>
                      <option value="date">Added On</option>
                      <option value="user">Agent Name</option>
                      <option value="user_emp_id">Employee Id</option>
                      <option value="assigned_number">Virtual No</option>
                      <option value="personal_number">Personal No</option>
                      <option value="user_email">Email</option>
                    </React.Fragment>
                  ) : null}
                  {this.props.type === 'sms' ? (
                    <React.Fragment>
                      <option value="sender">Sender</option>
                      {this.state.userRole === 'ADMIN' && (
                        <>
                          <option value="receiver_name">Agent Name</option>
                          <option value="doosra_number">Virtual No</option>
                        </>
                      )}
                      <option value="date">Date</option>
                    </React.Fragment>
                  ) : null}
                  {this.props.type === 'sms-broadcast' ? (
                    <React.Fragment>
                      <option value="name">Campaign Name</option>
                    </React.Fragment>
                  ) : null}
                  {this.props.type === 'contacts' ? (
                    <React.Fragment>
                      {this.state.userRole === 'ADMIN' && (
                        <option value="user_name">Agent Name</option>
                      )}
                      <option value="contact_name">Contact Name</option>
                      <option value="contact_number">Contact No</option>
                      <option value="note">Note</option>
                      <option value="tag">Tag</option>
                    </React.Fragment>
                  ) : null}
                  {this.props.type === 'doosraNumbers' ? (
                    <React.Fragment>
                      <option value="doosra_number">Virtual No</option>
                      <option value="current_status">Status</option>
                      <option value="assigned_user">Agent Name</option>
                      <option value="assigned_user_number">Personal No</option>
                    </React.Fragment>
                  ) : null}
                  {this.props.type === 'calls' ? (
                    <React.Fragment>
                      <option value="sender">Caller</option>
                      <option value="call_type">Call Type</option>
                      {this.state.userRole === 'ADMIN' && (
                        <>
                          <option value="receiver_name">Agent Name</option>
                          <option value="doosra_number">Virtual No</option>
                        </>
                      )}
                      <option value="date">Date</option>
                    </React.Fragment>
                  ) : null}
                  {this.props.type === 'ivr' ? (
                    <React.Fragment>
                      <option value="groupName">Group Name</option>
                      <option value="vmn">VMN</option>
                      {/* <option value="did">DID</option> */}
                      <option value="routingType">Routing Type</option>
                      {/* <option value="groupType">Group Type</option> */}
                      <option value="agentName">Agent Name</option>
                    </React.Fragment>
                  ) : null}
                  {this.props.type === 'did-call-backs' ? (
                    <React.Fragment>
                      <option value="sender">Caller</option>
                      <option value="call_type">Call Type</option>
                      {this.state.userRole === 'ADMIN' && (
                        <option value="receiver_name">Agent Name</option>
                      )}
                      <option value="doosra_number">Virtual No</option>
                      <option value="date">Date</option>
                    </React.Fragment>
                  ) : null}
                  {this.props.type === 'outgoing-calls' ? (
                    <React.Fragment>
                      <option value="destinationNumber">Destination</option>
                      <option value="status">Call Status</option>
                      {this.state.userRole === 'ADMIN' && (
                        <option value="initiatorNumber">
                          Agent Primary No
                        </option>
                      )}
                      <option value="date">Date</option>
                    </React.Fragment>
                  ) : null}
                  {this.props.type === 'number-masking-dids' ? (
                    <React.Fragment>
                      <option value="didNumber">Masked Number</option>
                    </React.Fragment>
                  ) : null}
                  {this.props.type === 'number-masking-calls' ? (
                    <React.Fragment>
                      <option value="callerNumber">Caller Number</option>
                      <option value="receiverNumber">Receiver Number</option>
                      <option value="didNumber">Masked Number</option>
                      <option value="date">Date</option>
                    </React.Fragment>
                  ) : null}
                  {this.props.type === 'number-masking-bindings' ? (
                    <React.Fragment>
                      <option value="callerNumber">Caller Number</option>
                      <option value="receiverNumber">Receiver Number</option>
                      <option value="didNumber">Masked Number</option>
                      <option value="bindingStatus">Status</option>
                      <option value="bindingValidity">Validity</option>
                    </React.Fragment>
                  ) : null}
                  {this.props.type === 'agent-caller-tune' ? (
                    <React.Fragment>
                      <option value="user">Agent Name</option>
                    </React.Fragment>
                  ) : null}
                  {this.props.type === 'group-caller-tune' ? (
                    <React.Fragment>
                      <option value="groupName">Group Name</option>
                    </React.Fragment>
                  ) : null}
                  {this.props.type === 'autoAttendant' ? (
                    <React.Fragment>
                      <option value="name">Name</option>
                      <option value="description">Description</option>
                    </React.Fragment>
                  ) : null}
                  {this.props.type === 'recovery-call-logs' ? (
                    <React.Fragment>
                      <option value="initiatorNumber">Initiator Number</option>
                      <option value="destinationNumber">
                        Destination Number
                      </option>
                      <option value="date">Date</option>
                      {/* <option value="status">CALL STATUS</option> */}
                    </React.Fragment>
                  ) : null}
                  {this.props.type === 'voice-broadcast' ? (
                    <React.Fragment>
                      <option value="name">Campaign Name</option>
                      <option value="date">Date</option>
                    </React.Fragment>
                  ) : null}
                  {this.props.type === 'campaign-agent' ? (
                    this.props.subType && this.props.subType === 'static' ? (
                      <React.Fragment>
                        <option value="name">Lead Name</option>
                        <option value="number">Lead Number</option>
                        <option value="call_status">Call status</option>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <option value="leadName">Lead Name</option>
                        <option value="leadNumber">Lead Number</option>
                      </React.Fragment>
                    )
                  ) : null}
                </select>
                {searchInput}
              </div>
            )}

          {localStorage.getItem('is_v2_static_campaigns_enabled') !== 'true' &&
            localStorage.getItem('is_v2_dynamic_campaigns_enabled') !==
              'true' &&
            this.props.type === 'recovery-x-campaign' && (
              <div style={{ display: 'flex', paddingRight: '100px' }}>
                {this.props.agentMetric && (
                  <Select
                    className="AgentStatus"
                    id="searchBy"
                    value={this.props.selectedAgentStatus}
                    onChange={this.props.handleAgentActiveStatus}
                  >
                    {this.props.optionsAgentActiveStatus.map((option) => (
                      <MenuItem
                        value={option.value}
                        style={{ boxShadow: 'none', fontSize: '15px' }}
                      >
                        <span
                          key={option.value}
                          style={{
                            display: 'flex',
                            color: option.color,
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;
                          <img
                            src={option.image}
                            style={{ fill: option.color }}
                            alt=".."
                          />
                          &nbsp;
                          <span
                            style={{
                              padding: '5px',
                              fontFamily: 'Montserrat',
                              fontSize: '14px',
                              fontWeight: 'bold',
                              fontStretch: 'normal',
                              fontStyle: 'normal',
                            }}
                          >
                            {option.label}
                          </span>
                        </span>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </div>
            )}

          {this.props.type === 'recoveryCalling' && (
            <div style={{ display: 'flex' }}>
              {this.props.agentMetric && (
                <Select
                  className="AgentStatus"
                  id="searchBy"
                  value={this.props.selectedAgentStatus}
                  onChange={this.props.handleAgentActiveStatus}
                >
                  {this.props.optionsAgentActiveStatus.map((option) => (
                    <MenuItem
                      value={option.value}
                      style={{ boxShadow: 'none', fontSize: '15px' }}
                    >
                      <span
                        key={option.value}
                        style={{
                          display: 'flex',
                          color: option.color,
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;
                        <img
                          src={option.image}
                          style={{ fill: option.color }}
                          alt=".."
                        />
                        &nbsp;
                        <span
                          style={{
                            padding: '5px',
                            fontFamily: 'Montserrat',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                          }}
                        >
                          {option.label}
                        </span>
                      </span>
                    </MenuItem>
                  ))}
                </Select>
              )}
              <PopulateLeadLists
                type="recoveryCalling"
                getLeadListsHandler={(e) => this.props.getLeadListsHandler(e)}
                getLeadsCount={(e) => this.props.getLeadsCount(e)}
                fetchLeadListContactsHandler={(e) =>
                  this.props.fetchLeadListContactsHandler(e)
                }
                leadLists={this.props.leadLists}
              />
            </div>
          )}

          {this.props.type === 'report' && (
            <PopulateLeadLists
              type="report"
              getLeadsCount={(e) => this.props.getLeadsCount(e)}
            />
          )}

          <div>
            {this.state.btnOnReset ? (
              <button
                className="ClearResultButton"
                style={{
                  border: 'none',
                }}
                onClick={this.onReset}
              >
                CLEAR RESULTS
              </button>
            ) : (
              <>
                {this.props.type !== 'recoveryCalling' &&
                  this.props.type !== 'report' &&
                  this.props.optional !== 'campaigns' &&
                  this.props.type !== 'recovery-x-campaign' &&
                  !isFreeFormEnabled && (
                    <button
                      className="SearchButton"
                      style={{ width: '100px', margin: '0 8px' }}
                      onClick={this.onSearch}
                    >
                      SEARCH
                    </button>
                  )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default styled(SearchBar)`

input::placeholder {
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}
input {
  color-scheme: ${(props) => props.theme.colors.dateCalenderIconColor};
}
.SearchBarComponent {
  width: 100%;
  height: 54px;
  background-color: ${(props) => props.theme.colors.searchBarBgcolor};
  display: flex;
  grid-template-columns: 136px auto auto 116px;
  align-items: center;
  border-radius: 1px;
  box-shadow: 0px -15px 10px -15px rgb(0, 0, 0, 0.4);
  border: solid 1px ${(props) => props.theme.colors.searchBarBgcolor};
}

.SearchText {
  font-size: 12px;
  font-weight: 500;
  margin: auto 10px;
  /* letter-spacing: 1.2px; */
  text-align: left;
  color: ${(props) => props.theme.colors.searchByColor};
}

.SearchDropdown {
  width: 136px;
  height: 32px;
  font-size: 13px;
  margin: auto 5px;
  padding: 0 5px;
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colors.contentContainerBorder};
  background-color: ${(props) => props.theme.colors.inputBgColor};
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.SearchBox {
  width: 136px;
  height: 32px;
  margin: auto 5px;
  font-size: 13px;
  padding: 0 10px;
  /* padding: 8px 16px 9px; */
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colors.contentContainerBorder};
  background-color: ${(props) => props.theme.colors.inputBgColor};
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.SearchButton {
  width: 116px;
  height: 32px;
  margin: 8px;
  padding: 7px;
  border-radius: 4px;
  border: none;
  background-color: #ed3833;
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
}

.ClearResultButton{
  width: 120px;
  height: 32px;
  margin: 8px;
  padding: 7px;
  border-radius: 4px;
  background-color: #ed3833;
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
  width: '128px',
  border: none,
}
.Otherbutton {
  width: 140px;
  height: 32px;
  margin: 8px;
  padding: 7px;
  border-radius: 4px;
  border: none;
  background-color: ${(props) => props.theme.colors.lightGreyBgColor};
  font-size: 12px;
  font-weight: bold;
  color: var(--white-color);
}

.AgentStatus {
  width: 150px;
  height: 32px;
  display: flex;
  margin: 16px 16px 16px;
  padding: 7px 0px 8px 0px;
  font-size: 15px;
  border-radius: 4px;
  border: solid 1px ${(props) => props.theme.colors.contentContainerBorder};
  background-color: white;
  color: ${(props) => props.theme.colors.inputPlaceholderColor};
}

.SelectedContainer {
  width: 136px;
  height: 54px;
  background-color: #196cca;
}

.SelectedContainer h6 {
  font-size: 12px;
  font-weight: bold;
  margin: 0;
  padding-top: 11px;
  padding-left: 14px;
  color: #ffffff;
}

.SelectedContainer p {
  font-size: 16px;
  font-weight: bold;
  padding-left: 14px;
  margin: 0;
  letter-spacing: 1.2px;
  color: #ffffff;
}

@media only screen and (max-width: 900px) {
  .SearchBarComponent {
    width: fit-content;
    grid-template-columns: 48px 136px 77px auto 150px;
  }
}
`;
