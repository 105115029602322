import React from 'react';
import Topbar from '../../common/component/topbar/Topbar';
import Sidebar from '../../common/componentV2/sidebar/Sidebar';
import HubSpotConnectCard from '../../common/component/hubspot/HubSpotConnectCard';
import { connect } from 'react-redux';
import { commonStyle } from '../../assets/css/css';

const NativeIntegrationsPage = (props) => {
  return (
    <div className={`${props.className}`}>
      <Topbar />
      <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
        <Sidebar />
        <div style={styles.NativeIntegrationsContainer}>
          <HubSpotConnectCard />
        </div>
      </div>
    </div>
  );
};
const styles = {
  NativeIntegrationsContainer: {
    position: 'relative',
    top: '20px',
    left: '20px',
  },
};
const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
});
export default commonStyle(
  connect(mapStateToProps, {})(NativeIntegrationsPage)
);
