import React, { useEffect, useState } from 'react';
import Sidebar from '../../common/componentV2/sidebar/Sidebar';
import Topbar from '../../common/component/topbar/Topbar';
import { commonStyle } from '../../assets/css/css';
import { connect } from 'react-redux';
import DataTable from '../../common/component/table/DataTable';
import TabSearchBar from '../../common/component/TabSearchbar/TabSearchBarComponent';
import { GET, DELETE } from '../../services/HttpRequests';
import Popup from '../../common/component/popups/popups';
import Close from '../../assets/images/popupClose.png';
import { EditIcon } from '../../assets/Icons/Icons';
import Delete from '../../assets/images/deleteWRedBackground.png';
import Paginator from '../../common/component/pagination/PaginationComponent';
import PreLoader from '../../common/component/preLoader/PreLoader';
import { toast } from 'react-toastify';
import CallFlowIcon from '../../assets/images/callFlowLarge.png';
import Add from '../../assets/images/add.png';
import TemplateOne from '../../assets/images/Group8.png';
import TemplateTwo from '../../assets/images/Group9.png';

const ivrUrl = process.env.REACT_APP_IVR_API;

const CallFlowListPage = (props) => {
  const [callFlows, setCallFlows] = useState([]);
  const [callFlowsCount, setCallFlowsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [callFlowName, setCallFlowName] = useState('');
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [documentId, setDocumentId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isTemplatePopupOpen, setIsTemplatePopupOpen] = useState(false);
  const [fileToLoad, setFileToLoad] = useState("");
  const tabs = [
    {
      title: 'CallFlows',
      onClick: () => {},
      subtitle: callFlowsCount?.toString() || '0',
      active: true,
    },
  ];
  useEffect(() => {
    setIsLoading(true);
    const params = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      pageNum: currentPage,
      pageSize: 20,
    };
    GET(`${ivrUrl}v1/ivr/organisation/get-call-flows`, params)
      .then((res) => {
        if (res?.data?.success) {
          setCallFlows(res?.data?.response?.callFlows);
          setCallFlowsCount(res?.data?.response?.metadata?.total);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data?.error?.reason || 'An error occured');
      });

    // GET(`http://localhost:14018/v1/ivr/organisation/get-call-flows`, params).then((res) => {
    //   if (res?.data?.success) {
    //     setCallFlows(res?.data?.response?.callFlows);
    //     setCallFlowsCount(res?.data?.response?.metadata?.total);
    //     setIsLoading(false);
    //   }
    // }).catch((err) => {
    //   setIsLoading(false);
    //   console.log("error====", err)
    // })
  }, []);
  const fetchCallFlowsList = (value) => {
    setIsLoading(true);
    const params = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      pageNum: value,
      pageSize: 20,
    };
    GET(`${ivrUrl}v1/ivr/organisation/get-call-flows`, params)
      .then((res) => {
        if (res?.data?.success) {
          setIsLoading(false);
          setCallFlows(res?.data?.response?.callFlows);
          setCallFlowsCount(res?.data?.response?.metadata?.total);
          setCurrentPage(value);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data?.error?.reason || 'An error occured');
      });
  };
  const closePopup = () => {
    setIsPopupOpen(false);
  };
  const handleCallFlowEdit = (item) => {
    props.history.push({
      pathname: '/call-flow',
      state: { documentId: item?._id, callFlowName: item?.ivrName },
    });
  };
  const handleDelete = () => {
    setIsLoading(true);
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      documentId,
    };
    DELETE(`${ivrUrl}v1/ivr/organisation/call-flow-delete`, {}, data)
      .then((res) => {
        if (res?.data?.success) {
          fetchCallFlowsList(1);
          setDocumentId('');
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log('err at callflow creation', err);
      });
  };

  const handleSave = () => {
    let state = { callFlowName };
    if (fileToLoad === "TemplateOne") {
      state = {
        ...state,
        templateId: "67764fdd4bbf4e4ff213eba6"
      }
    }
    if (fileToLoad === "TemplateTwo") {
      state = {
        ...state,
        templateId: "6777c21b4bbf4e4ff213ebcb"
      }
    }
    props.history.push({
      pathname: '/call-flow',
      state,
    });
  }
  return (
    <div className={`${props.className}`}>
      <Topbar />
      <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
        <Sidebar />
        <div className="ContentContainer">
          {callFlows?.length > 0 ? (
            <>
              <TabSearchBar type={'callFlows'} tabs={tabs}></TabSearchBar>
              <button
                className="Rectangle-9041"
                onClick={() => setIsTemplatePopupOpen(true)}
              >
                ADD CALLFLOW
              </button>
              <DataTable.Table>
                <DataTable.Head>
                  <DataTable.CellHead>Name</DataTable.CellHead>
                  <DataTable.CellHead>DID</DataTable.CellHead>
                  <DataTable.CellHead>STATUS</DataTable.CellHead>
                  <DataTable.CellHead style={{ width: '7%' }}>
                    ACTIONS
                  </DataTable.CellHead>
                </DataTable.Head>
                <DataTable.Body>
                  {(callFlows || []).map((item) => (
                    <DataTable.Row key={item?._id}>
                      <DataTable.Cell>{item?.ivrName}</DataTable.Cell>
                      <DataTable.Cell>{item?.did}</DataTable.Cell>
                      <DataTable.Cell>
                        {item?.live?.length > 0 ? (
                          <label style={{ color: '#04A93F' }}>Live</label>
                        ) : (
                          <label style={{ color: '#EC3833' }}>Draft</label>
                        )}
                      </DataTable.Cell>
                      <DataTable.Cell>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                          }}
                        >
                          <EditIcon onClick={() => handleCallFlowEdit(item)} />
                          <img
                            alt=""
                            onClick={() => {
                              setDocumentId(item?._id);
                              setIsDeletePopupOpen(true);
                            }}
                            src={Delete}
                          />
                        </div>
                      </DataTable.Cell>
                    </DataTable.Row>
                  ))}
                </DataTable.Body>
              </DataTable.Table>
              <Paginator
                currentPage={currentPage}
                lastPage={Math.ceil(callFlowsCount / 20)}
                getInfo={(value) => {
                  fetchCallFlowsList(value);
                }}
              />
            </>
          ) : isLoading ? (
            <PreLoader />
          ) : (
            <div
              style={{
                display: 'flex',
                width: '80vw',
                height: '80vh',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <img style={{ marginBottom: '18px' }} src={CallFlowIcon} />
              <button
                onClick={() => setIsPopupOpen(true)}
                style={{
                  width: '180px',
                  height: '50px',
                  backgroundColor: '#196cca',
                  borderRadius: '10px',
                  border: 'none',
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                  color: 'white',
                  boxShadow: 'initial',
                }}
              >
                Add Call Flow
              </button>
            </div>
          )}
        </div>


        <Popup
          open={isTemplatePopupOpen}
          closeOnDocumentClick={false}
          closeOnEscape={false}
        >
          <div className="PopupContainer">
            <div className="PopupHeaderContainer">
              <p>CREATE CALL FLOW</p>
              <img
                alt=""
                className="PopupClose"
                src={Close}
                onClick={() => setIsTemplatePopupOpen(false)}
              />
            </div>
            <div className="PopupBody">
              <div className="PopupTemplateContainer" style={{ cursor: "pointer" }} onClick={() => {
                  setFileToLoad("New");
                  setIsTemplatePopupOpen(false);
                  setIsPopupOpen(true);
                }}>
              <img
                alt=""
                style={{ width: "28px", height: "28px" }}
                src={Add}
              />
              </div>
              <text className="PopupTemplateText">New file</text>
              <div className="PopupTemplateContainer" style={{ cursor: "pointer" }} onClick={() => {
                setFileToLoad("TemplateOne");
                setIsTemplatePopupOpen(false);
                setIsPopupOpen(true);
              }}>
              <img
                alt=""
                src={TemplateOne}
              />

              </div>
              <text className="PopupTemplateText">Single level call flow template</text>
              <div className="PopupTemplateContainer" style={{ cursor: "pointer" }} onClick={() => {
                  setFileToLoad("TemplateTwo");
                  setIsTemplatePopupOpen(false);
                  setIsPopupOpen(true);
                }}>
              <img
                alt=""
                style={{ cursor: "pointer" }}
                src={TemplateTwo}
              />
              </div>
              <text className="PopupTemplateText">Multiple level call flow template</text>
            </div>
          </div>
        </Popup>

        {/* POPUP */}
        <Popup
          open={isPopupOpen}
          closeOnDocumentClick={false}
          closeOnEscape={false}
        >
          <div className="PopupContainer">
            <div className="PopupHeaderContainer">
              <p>Create CallFlow Name</p>
              <img
                alt=""
                className="PopupClose"
                src={Close}
                onClick={closePopup}
              />
            </div>
            <div className="PopupBody">
              <div className="PopupInputContainer">
                <label className="PopupInputLabel">CallFlow Name</label>
                <input
                  className="PopupInput"
                  id="emp_id"
                  value={callFlowName}
                  onChange={(e) => setCallFlowName(e.target.value)}
                />
              </div>
              <button
                className="ButtonFullWidth"
                style={{ marginTop: '10px' }}
                type="submit"
                onClick={() => handleSave()}
                disabled={!callFlowName}
              >
                {'SAVE'}
              </button>
              <button className="ButtonCancelFullWidth" onClick={closePopup}>
                CANCEL
              </button>
            </div>
          </div>
        </Popup>

        {/* Delete Confirmation */}
        <Popup
          open={isDeletePopupOpen}
          closeOnDocumentClick={false}
          closeOnEscape={false}
        >
          <div className="PopupContainer">
            <div className="PopupHeaderContainer">
              <p>Delete Call Flow</p>
              <img
                alt=""
                className="PopupClose"
                src={Close}
                onClick={() => {
                  setIsDeletePopupOpen(false);
                  setDocumentId('');
                }}
              />
            </div>
            <div className="PopupBody">
              <p className="PopupBodyTitle">
                Are you sure you want to remove this Call Flow?
              </p>

              <button
                className="ButtonFullWidth BgRed"
                onClick={() => {
                  handleDelete();
                  setIsDeletePopupOpen(false);
                }}
              >
                REMOVE
              </button>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
});

export default commonStyle(connect(mapStateToProps, {})(CallFlowListPage));
