/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, useLocation, useHistory } from 'react-router-dom';
import * as onbaordingTourConstants from '../../../assets/onbaordingTourConstants';
import {
  toggleSidebar,
  setSMSAccessKey,
  setCallAccessKey,
} from '../../../actions/sidebarActions';
import styled from '@emotion/styled';
import '../../../assets/css/Sidebar.css';
import { DELETE, GET, PUT } from '../../../services/HttpRequests';
import { SIDEBAR_BUTTON, AVAILABILITY_TOGGLE } from '../../posthog/events';
import { logPostHogEvent } from '../../posthog/posthog';
import Call from '../../../assets/images/CallIcon.svg';
import CallWhite from '../../../assets/images/CallWhite.svg';
import Schema from '../../../assets/images/Schema.svg';
import SchemaWhite from '../../../assets/images/SchemaWhite.svg';
import Campaigns from '../../../assets/images/Campaigns.svg';
import CampaignsWhite from '../../../assets/images/CampaignsWhite.svg';
import Rupee from '../../../assets/images/Rupee.svg';
import AvgPace from '../../../assets/images/AvgPace.svg';
import PhoneSetting from '../../../assets/IconsV2/phonelink_setup.png';
import Article from '../../../assets/images/Article.svg';
import Terminal from '../../../assets/images/Terminal.svg';
import Webhook from '../../../assets/images/Webhook.svg';
import ProfileSettings from '../../../assets/images/profileSettings.svg';
import Logout from '../../../assets/images/Logout.svg';
import LogoutWhite from '../../../assets/images/LogoutWhite.svg';
import SettingsHeart from '../../../assets/images/SettingsHeart.svg';
import SettingsHeartWhite from '../../../assets/images/SettingsHeartWhite.svg';
import ComedyMask from '../../../assets/images/ComedyMask.svg';
import ComedyMaskWhite from '../../../assets/images/ComedyMaskWhite.svg';
import Equalizer from '../../../assets/images/Equalizer.svg';
import EqualizerWhite from '../../../assets/images/EqualizerWhite.svg';
import Contacts from '../../../assets/images/Contacts.svg';
import ContactsWhite from '../../../assets/images/ContactsWhite.svg';
import Dialpad from '../../../assets/images/Dialpad_1.svg';
import Group from '../../../assets/images/Group.svg';
import Groups from '../../../assets/images/Groups_2.svg';
import Robot from '../../../assets/images/Robot_2.svg';
import KYC from '../../../assets/images/Kyc.svg';
import CallerTunes from '../../../assets/images/CallerTunes.svg';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import AgentSettings from '../../../assets/images/AgentSettings.svg';
import VoiceBroadcast from '../../../assets/images/VoiceBroadcast1.svg';
import VoiceBroadcastWhite from '../../../assets/images/VoiceBroadcastWhite.svg';
import VoiceAPI from '../../../assets/images/VoiceAPI.svg';
import VoiceAPIWhite from '../../../assets/images/VoiceAPIWhite.svg';
import CallFlow from '../../../assets/images/CallFlow.svg';
import CallFlowWhite from '../../../assets/images/CallFlowWhite.svg';
import Integration from '../../../assets/images/Integration.svg';
import IntegrationWhite from '../../../assets/images/IntegrationWhite.svg';

import { USER_PROFILE_DETAILS } from '../../../actions/topBarActions';
import { use } from 'react';

const userUrl = process.env.REACT_APP_USER_API;
const incomingCallsUrl = process.env.REACT_APP_INCOMING_CALLS_API;
const organizationUrl = process.env.REACT_APP_ORGANIZATION_API;
const webRTCOutGoingUrl = process.env.REACT_APP_WEBRTC_RECOVERY_API_URL;
const cloudCallUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;

const userRole = localStorage.getItem('doosra-biz-user-role');
const isSuperAdmin =
  localStorage.getItem('doosra-biz-user-role-is-super-admin') === 'true';

const SidebarMainButton = ({
  name,
  isVisible,
  isActive,
  icon,
  iconInvert,
  linkedUrl,
  callback,
}) => {
  const handleClick = () => {
    callback();
  };
  if (isVisible === false) return null;

  return (
    <div className="SidebarMainButtonHolder" onClick={handleClick}>
      <div
        className={isActive ? 'SidebarMainButtonActive' : 'SidebarMainButton'}
      >
        <img alt="" src={isActive ? iconInvert : icon} />
      </div>
      <p
        className={
          isActive ? 'SidebarMainButtonTextActive' : 'SidebarMainButtonText'
        }
      >
        {name}
      </p>
    </div>
  );
};

const SubMenuSideBarButton = ({ name, icon, url, action, isActive }) => {
  const history = useHistory();
  return (
    <div
      className={
        isActive ? 'SubMenuSidebarButtonActive' : 'SubMenuSidebarButton'
      }
      onClick={() => {
        if (action) {
          action();
        } else {
          history.push(url);
        }
      }}
    >
      <img
        alt=""
        src={icon}
        style={{
          marginLeft: '4px',
          marginRight: '8px',
        }}
      />
      <p style={{ margin: 0, lineHeight: '1.6' }}>{name}</p>
    </div>
  );
};

const SettingsMenu = (props) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const isPlanAvailable = JSON.parse(localStorage.getItem('is_plan_available'));
  const isCallerTuneEnabled = useSelector(
    (state) => state.topbar.isCallerTuneEnabled
  );
  return (
    <div className="SubMenuSideBar">
      <h3 className="SubMenuSidebarTitle">Settings</h3>
      <p className="SubMenuSidebarSubTitle">Account</p>
      <div className="SubMenuSidebarButtonGroup">
        <SubMenuSideBarButton
          name="Profile Settings"
          icon={ProfileSettings}
          url="/account-settings"
          isActive={currentPath === '/account-settings'}
        />
        {userRole === 'ADMIN' && isPlanAvailable && (
          <SubMenuSideBarButton
            name="Plan Settings"
            icon={Rupee}
            url="/payment-history"
            isActive={currentPath === '/payment-history'}
          />
        )}
        {userRole === 'ADMIN' && (
          <SubMenuSideBarButton
            name="Agent Settings"
            icon={AgentSettings}
            url="/agent-settings"
            isActive={currentPath === '/agent-settings'}
          />
        )}
        {userRole === 'ADMIN' && (
          <SubMenuSideBarButton
            name="Operating Schedule"
            icon={AvgPace}
            url="/off-hours"
            isActive={currentPath === '/off-hours'}
          />
        )}
        {userRole === 'ADMIN' && (
          <SubMenuSideBarButton
            name="Call Settings"
            icon={PhoneSetting}
            url="/call-settings"
            isActive={currentPath === '/call-settings'}
          />
        )}
        {userRole === 'ADMIN' && isCallerTuneEnabled && (
          <SubMenuSideBarButton
            name="Caller tunes"
            icon={CallerTunes}
            url="/caller-tunes"
            isActive={currentPath === '/caller-tunes'}
          />
        )}
        {userRole === 'ADMIN' && isPlanAvailable && (
          <SubMenuSideBarButton
            name="Kyc"
            icon={KYC}
            url="kyc"
            isActive={currentPath === '/kyc'}
          />
        )}
      </div>
      <p className="SubMenuSidebarSubTitle">Developer Resources</p>
      <div className="SubMenuSidebarButtonGroup">
        <SubMenuSideBarButton
          name="Documentation"
          icon={Article}
          action={() => window.open('https://docs.alohaa.ai/', '_blank')}
        />
        {userRole === 'ADMIN' && (
          <SubMenuSideBarButton
            name="API Key"
            icon={Terminal}
            url="/api-management"
            isActive={currentPath === '/api-management'}
          />
        )}
        {userRole === 'ADMIN' && props.webHookEnabled && (
          <SubMenuSideBarButton
            name="Webhooks"
            icon={Webhook}
            url={
              props.outgoingWebhookEnabled
                ? '/outgoing-call-webhook'
                : 'call-webhook'
            }
            isActive={[
              '/call-webhook',
              '/outgoing-call-webhook',
              '/voice-api-webhook',
              '/number-masking-api-webhook',
              '/number-masking-call-details-webhook',
            ].includes(currentPath)}
          />
        )}
      </div>
    </div>
  );
};

const BuildingBlockMenu = ({}) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const isAutoAttendantEnabled = useSelector(
    (state) => state.topbar.isAutoAttendantEnabled
  );
  const isPriorityRoutingEnabled = useSelector(
    (state) => state.topbar.isPriorityRoutingEnabled
  );

  return (
    <div className="SubMenuSideBar">
      <h3 className="SubMenuSidebarTitle">Building Blocks</h3>
      <p className="SubMenuSidebarSubTitle">Account</p>
      <div className="SubMenuSidebarButtonGroup">
        <SubMenuSideBarButton
          name="Numbers"
          icon={Dialpad}
          url="/virtual-numbers"
          isActive={currentPath === '/virtual-numbers'}
        />
        <SubMenuSideBarButton
          name="Users"
          icon={Group}
          url="/users"
          isActive={currentPath === '/users'}
        />
        {isPriorityRoutingEnabled && (
          <SubMenuSideBarButton
            name="Groups"
            icon={Groups}
            url="/ivr"
            isActive={currentPath === '/ivr'}
          />
        )}
        {isAutoAttendantEnabled && (
          <SubMenuSideBarButton
            name="Auto Attendant"
            icon={Robot}
            url="/auto-attended"
            isActive={currentPath === '/auto-attended'}
          />
        )}
      </div>
    </div>
  );
};

const Sidebar = ({ className }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const sidebarRef = useRef(null);
  const currentPath = location.pathname;
  const isReocveryXEnabled = useSelector(
    (state) => state?.topbar?.isReocveryXEnabled
  );
  const isCampaignEnabled = useSelector(
    (state) => state?.topbar?.isCampaignEnabled
  );
  const selectedCampaign = useSelector(
    (state) => state?.topbar?.selectedCampaign
  );
  const RTCSessionData = useSelector((state) => state?.dailpad?.RTCSessionData);
  const isVoiceBroadcastEnabled = useSelector(
    (state) => state.topbar.isVoiceBroadcastEnabled
  );

  const isVoiceApiEnabled = useSelector(
    (state) => state.topbar.isVoiceApiEnabled
  );
  const isFreeFormEnabled = useSelector(
    (state) => state.topbar.isFreeFormEnabled
  );
  const isNumberMaskingEnabled = useSelector(
    (state) => state.topbar.isNumberMaskingEnabled
  );
  const isAnalyticsEnabled = useSelector(
    (state) => state.topbar.isAnalyticsEnabled
  );
  const isCampaignV2Enabled = useSelector(
    (state) => state.topbar.isCampaignV2Enabled
  );
  const isIntegrationsEnabled = useSelector(
    (state) => state.topbar.isIntegrationsEnabled
  );

  const batchId = useSelector((state) => state.dailpad.batchId);
  const [isLoading, setIsLoading] = useState(false);
  const [isFreeTrail, setIsFreeTrail] = useState(true);
  const [isCallApiAvailable, setisCallApiAvailable] = useState(false);
  const [isVoiceApiAvailable, setisVoiceApiAvailable] = useState(false);
  const [isOutgoingApiAvailable, setisOutgoingApiAvailable] = useState(false);
  const [settingsMenuOpen, setSettingsMenuOpen] = useState(
    [
      '/account-settings',
      '/off-hours',
      '/call-settings',
      '/agent-settings',
      '/off-hours',
      '/payment-history',
      '/caller-tunes',
      '/kyc',
      '/call-webhook',
      '/outgoing-call-webhook',
      '/voice-api-webhook',
      '/number-masking-api-webhook',
      '/number-masking-call-details-webhook',
      '/api-management',
    ].includes(currentPath)
      ? true
      : false
  );
  const [buildingBlocksMenuOpen, setBuildingBlocksMenuOpen] = useState(
    ['/virtual-numbers', '/users', '/ivr', '/auto-attended'].includes(
      currentPath
    )
      ? true
      : false
  );
  let MainMenuOptions = [];

  useEffect(() => {
    const userRole = localStorage.getItem('doosra-biz-user-role');
    const orgId = localStorage.getItem('doosra-biz-organisation-id');
    const isFreeTrial = localStorage.getItem('is_free_trial_enabled');
    setIsFreeTrail(isFreeTrial ? isFreeTrial === 'true' : false);

    // Simulating any API call or necessary setup on mount
    const params = {
      organisation_id: orgId,
      webhook_type: [
        'CALL',
        'OUTGOING_CALL',
        'VOICE_API',
        'NUMBER_MASKING',
        'NM_CALL_DETAILS',
      ],
    };

    // Replace this block with the actual API call or initialization logic

    GET(userUrl + `v1/user/profile`, {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
    })
      .then((res) => {
        return res.data.response.result;
      })
      .then((res) => {
        if (res?.is_sms_deletion_enabled) {
          localStorage.setItem(
            'doosra-biz-user-deletion-enabled',
            res.is_sms_deletion_enabled
          );
        } else {
          localStorage.setItem('doosra-biz-user-deletion-enabled', false);
        }
        if (res.apiKey)
          localStorage.setItem('doosra-biz-org-apiKey', res.apiKey);
        if (res.name) localStorage.setItem('doosra-biz-user-name', res.name);
        if (res.email) localStorage.setItem('doosra-biz-user-email', res.email);
        if (res.phone_number)
          localStorage.setItem('doosra-biz-user-number', res.phone_number);

        dispatch({
          type: USER_PROFILE_DETAILS,
          payload: {
            ...res,
            isCallForwardingAllowed:
              res?.organisation?.is_call_forwarding_allowed || false,
            user_call_minutes_available:
              res?.organisation?.call_minutes_available,
            user_startTime: res?.organisation?.startTime || '',
            user_endTime: res?.organisation?.endTime || '',
            vmnExpire: res?.organisation?.vmnExpireDate || '',
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });

    GET(`${organizationUrl}v1/organisation/web-hook`, params)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        const { features, add_ons } = res.webHookData;
        if (
          features &&
          features.voice_api &&
          add_ons?.is_voice_api_webhook_enabled
        ) {
          const { is_voice_api_webhook_enabled } = add_ons;
          setisVoiceApiAvailable(is_voice_api_webhook_enabled);
          localStorage.setItem(
            'is_voice_api_webhook_enabled',
            is_voice_api_webhook_enabled
          );
        }
        const { is_outgoing_call_webhook_enabled } = add_ons;
        setisOutgoingApiAvailable(is_outgoing_call_webhook_enabled);
        localStorage.setItem(
          'is_outgoing_call_webhook_enabled',
          is_outgoing_call_webhook_enabled
        );
        if (features && features.call && add_ons?.is_call_webhook_enabled) {
          const { is_call_webhook_enabled } = add_ons;
          setisCallApiAvailable(is_call_webhook_enabled);
          localStorage.setItem(
            'is_call_webhook_enabled',
            is_call_webhook_enabled
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    // Restore the scroll position when the sidebar is mounted
    const savedScrollPosition = sessionStorage.getItem('sidebar-scroll');
    if (sidebarRef.current && savedScrollPosition) {
      sidebarRef.current.scrollTop = parseInt(savedScrollPosition, 10);
    }
  }, []);

  const handleScroll = () => {
    // Save the scroll position when the sidebar is scrolled
    if (sidebarRef.current) {
      sessionStorage.setItem('sidebar-scroll', sidebarRef.current.scrollTop);
    }
  };

  const handleAgentActiveStatus = async (inputData) => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      agentId: localStorage.getItem('doosra-biz-user-id'),
      breakSwitch: '',
      breakReason: inputData.status,
    };
    if (inputData.logout) {
      data.logout = inputData.logout;
    }
    if (inputData.status === 'active') {
      data.breakSwitch = 'off';
    } else {
      data.breakSwitch = 'on';
    }

    await PUT(webRTCOutGoingUrl + 'metrics/update-agent-break-time', {}, data)
      .then((res) => {
        toast.success(res?.data?.response?.msg);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const stopBatchCall = () => {
    var data = JSON.stringify({
      batchCallId: batchId,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId: selectedCampaign,
      userId: localStorage.getItem('doosra-biz-user-id'),
    });
    if (RTCSessionData) {
      RTCSessionData.terminate();
    }
    return POST(cloudCallUrl + 'cloud-call/stop-batch-call', data)
      .then((res) => {
        return true;
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason);
        return false;
      });
  };

  const handleDialerAgentStatus = async (eventKey) => {
    const breakReasonMap = {
      Break: 'break',
      Online: 'active',
      Busy: 'busy',
      Offline: 'offline',
    };
    let breakReason = breakReasonMap[eventKey] || 'offline';
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      agentId: localStorage.getItem('doosra-biz-user-id'),
      breakSwitch: '',
      breakReason,
      logout: true,
    };
    if (eventKey === 'Online') {
      data.breakSwitch = 'off';
    } else {
      data.breakSwitch = 'on';
    }
    if (selectedCampaign) {
      await stopBatchCall();
    }
    await PUT(cloudCallUrl + 'metric/update-agent-break-time', {}, data)
      .then((res) => {
        return true;
      })
      .catch((err) => {
        return false;
      });
  };

  const logoutHandler = async () => {
    const userRole = localStorage.getItem('doosra-biz-user-role');
    if (userRole === 'USER') {
      if (isReocveryXEnabled || isCampaignEnabled) {
        await handleAgentActiveStatus({
          status: 'offline',
          logout: true,
        });
      }
      if (localStorage.getItem('is_dialer_widget_enabled') === 'true') {
        await handleDialerAgentStatus('Offline');
      }
    }
    DELETE(`${userUrl}v1/auth/logout`)
      .then((res) => {
        if (res?.data?.response?.user_id) {
          localStorage.clear();
          sessionStorage.clear();
          window.location.reload();
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  if (userRole === 'ADMIN') {
    MainMenuOptions = [
      {
        name: 'Call logs',
        isVisible: true,
        isActive: true,
        linkedUrl: '/call-logs',
        activeUrl: ['/call-logs', '/outgoing-call-logs'],
        icon: Call,
        iconInvert: CallWhite,
      },
      {
        name: 'Building Blocks',
        isVisible: true,
        isActive: false,
        linkedUrl: '/virtual-numbers',
        activeUrl: ['/virtual-numbers', '/users', '/ivr', '/auto-attended'],
        action: 'toggleBuildingBlocksMenu',
        icon: Schema,
        iconInvert: SchemaWhite,
      },
      {
        name: 'Contacts',
        isVisible: true,
        isActive: false,
        linkedUrl: '/contacts',
        activeUrl: ['/contacts'],
        icon: Contacts,
        iconInvert: ContactsWhite,
      },
      {
        name: 'Voice Broadcast',
        isVisible: isVoiceBroadcastEnabled,
        isActive: false,
        linkedUrl: '/voice-broadcasts-list',
        activeUrl: [
          '/voice-broadcasts-list',
          '/create-voice-broadcast',
          '/voice-broadcast-details',
        ],
        icon: VoiceBroadcast,
        iconInvert: VoiceBroadcastWhite,
      },
      {
        name: 'Voice API',
        isVisible: isVoiceApiEnabled,
        isActive: false,
        linkedUrl: '/voice-api-list',
        activeUrl: [
          '/voice-api-list',
          '/create-voice-api',
          '/voice-api-details',
        ],
        icon: VoiceAPI,
        iconInvert: VoiceAPIWhite,
      },
      {
        name: 'Campaigns',
        isVisible: isCampaignV2Enabled,
        isActive: false,
        linkedUrl: '/campaigns-v2',
        activeUrl: [
          '/campaigns-v2',
          '/calls-v2',
          '/create',
          '/campaign-report',
        ],
        icon: Campaigns,
        iconInvert: CampaignsWhite,
      },
      {
        name: 'Integrations',
        isVisible: isIntegrationsEnabled,
        isActive: false,
        linkedUrl: '/native-integrations',
        activeUrl: ['/native-integrations'],
        icon: Integration,
        iconInvert: IntegrationWhite,
      },
      {
        name: 'Call Flow',
        isVisible: true,
        isActive: false,
        linkedUrl: '/call-flows',
        activeUrl: ['/call-flows', '/call-flow'],
        icon: CallFlow,
        iconInvert: CallFlowWhite,
      },
      {
        name: 'Number Masking',
        isVisible: isNumberMaskingEnabled,
        isActive: false,
        linkedUrl: '/number-masking-call-logs',
        activeUrl: ['/number-masking-call-logs', '/number-masking-did'],
        icon: ComedyMask,
        iconInvert: ComedyMaskWhite,
      },
      {
        name: 'Analytics',
        isVisible: isAnalyticsEnabled,
        isActive: false,
        linkedUrl: '/analytics',
        activeUrl: ['/analytics'],
        icon: Equalizer,
        iconInvert: EqualizerWhite,
      },
      {
        name: 'Settings',
        isVisible: true,
        isActive: false,
        linkedUrl: '/account-settings',
        activeUrl: [
          '/account-settings',
          '/payment-history',
          '/agent-settings',
          '/off-hours',
          '/call-settings',
          '/caller-tunes',
          '/kyc',
          '/outgoing-call-webhook',
          '/call-webhook',
          '/voice-api-webhook',
          '/number-masking-api-webhook',
          '/number-masking-call-details-webhook',
          '/api-management',
        ],
        action: 'toggleSettingsMenu',
        icon: SettingsHeart,
        iconInvert: SettingsHeartWhite,
      },
      {
        name: 'Logout',
        isVisible: true,
        isActive: false,
        action: 'logout',
        icon: Logout,
        iconInvert: LogoutWhite,
      },
    ];
  } else {
    MainMenuOptions = [
      {
        name: 'Call logs',
        isVisible: true,
        isActive: true,
        linkedUrl: '/call-logs',
        activeUrl: ['/call-logs', '/outgoing-call-logs'],
        icon: Call,
        iconInvert: CallWhite,
      },
      {
        name: 'Contacts',
        isVisible: true,
        isActive: false,
        linkedUrl: '/contacts',
        activeUrl: ['/contacts'],
        icon: Contacts,
        iconInvert: ContactsWhite,
      },
      {
        name: 'Campaigns',
        isVisible: isCampaignV2Enabled,
        isActive: false,
        linkedUrl: '/calls-v2',
        activeUrl: ['/calls-v2', '/agent-leads'],
        icon: Campaigns,
        iconInvert: CampaignsWhite,
      },
      {
        name: 'Settings',
        isVisible: true,
        isActive: false,
        linkedUrl: '/account-settings',
        activeUrl: ['/account-settings'],
        action: 'toggleSettingsMenu',
        icon: SettingsHeart,
        iconInvert: SettingsHeartWhite,
      },
      {
        name: 'Logout',
        isVisible: true,
        isActive: false,
        action: 'logout',
        icon: Logout,
        iconInvert: LogoutWhite,
      },
    ];
  }

  const updatedMenuOptions = MainMenuOptions.map((item) => ({
    ...item,
    isActive: item?.activeUrl?.includes(location?.pathname),
  }));

  const handleMenuClick = (item) => {
    if (item.action === 'toggleSettingsMenu') {
      setSettingsMenuOpen(true); // Toggle submenu visibility
    }
    // else {
    //   setSettingsMenuOpen(false); // Close submenu for other buttons
    // }

    if (item.action === 'toggleBuildingBlocksMenu') {
      setBuildingBlocksMenuOpen(!buildingBlocksMenuOpen); // Toggle submenu visibility
    } else {
      setBuildingBlocksMenuOpen(false); // Close submenu for other buttons
    }

    if (item.action === 'logout') {
      logoutHandler();
    }

    if (item.linkedUrl) {
      history.push(item.linkedUrl);
    }
  };

  return (
    <div className={`${className}`}>
      <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
        <div className="Sidebar" ref={sidebarRef} onScroll={handleScroll}>
          {updatedMenuOptions.map((item) => (
            <SidebarMainButton
              icon={item.icon}
              iconInvert={item.iconInvert}
              key={Math.random()}
              name={item.name}
              isActive={item.isActive}
              isVisible={item.isVisible}
              linkedUrl={item.linkedUrl}
              activeUrl={item.activeUrl}
              callback={() => handleMenuClick(item)}
            />
          ))}
        </div>
        {isLoading && <PreLoader />}
        {settingsMenuOpen && (
          <SettingsMenu
            webHookEnabled={
              isCallApiAvailable ||
              isOutgoingApiAvailable ||
              isVoiceApiAvailable
              // || isFreeFormEnabled
            }
            outgoingWebhookEnabled={isOutgoingApiAvailable}
            incomingWebhookEnabled={isCallApiAvailable}
          />
        )}
        {buildingBlocksMenuOpen && <BuildingBlockMenu />}
      </div>
    </div>
  );
};

export default styled(withRouter(Sidebar))`
  .ParentContainer {
    display: grid;
    grid-template-columns: 384px auto;
  }

  // control initial sidebar
  .Sidebar {
    margin: 0;
    padding: 0;
    height: calc(100vh - 100px);
    width: 96px;
    background-color: #f6f6f6;
    height: -webkit-fill-available;
    overflow: auto;
    transition: width 0.3s;
    border-right: 1px solid #eaeaea;
    overflow-anchor: none;
  }

  .SidebarMainButtonHolder {
    display: flex;
    flex-direction: column;
    max-width: 64px;
    margin: 16px;
  }

  .SidebarMainButton {
    background-color: #ffffff;
    height: 64px;
    width: 64px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 1px 1.6px 0px #00000029;
  }


  .SidebarMainButton:hover{
  border:2px solid #F8153B;
  }

  .SidebarMainButtonActive {

    height: 64px;
    width: 64px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 1.6px 0px #00000029;
    background-color: #F8153B;
    
  }

  .SidebarMainButtonText {
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: -0.02em;
    margin: 0px;
    padding-top:8px;
    text-spacing:0.5px;
    color:#474747;
  }
.SidebarMainButtonTextActive{
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: -0.02em;
    margin: 0px;
    color: #F8153B;
    padding-top:8px;
    color:#F8153B
    text-spacing:0.5px;
    

}

  .SubMenuSideBar {
    
    height: calc(100vh - 72px);
    overflow:auto;
    width: 311px;
    background-color: #f6f6f6;
    padding: 24px;
    border-right: 1px solid #eaeaea;
  }
  .SubMenuSidebarTitle {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.26px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #f8153b;
  }
  .SubMenuSidebarSubTitle {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #9b9b9b;
  }
  .SubMenuSidebarButton {
    width: 263px;
    height: 53px;
    padding: 14px;
    border-radius: 6px;
    background-color: #ffffff;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    box-shadow: 0px 2px 2px 0px #00000029;
    cursor: pointer;
    display:flex;
  }
  .SubMenuSidebarButtonActive {
    width: 263px;
    height: 53px;
    padding: 14px;
    border-radius: 6px;
    background-color: #ffffff;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    box-shadow: 0px 2px 2px 0px #00000029;
    cursor: pointer;
    display:flex;
    border: 2px solid #F8153B;
  }


  .SubMenuSidebarButton:hover{
  border:1px solid #F8153B;
  }
  .SubMenuSidebarButtonGroup {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 32px;
  }
`;
