import Sidebar from '../../../common/componentV2/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { CALL_LOGS_MAPPER } from '../../../common/constants';
import { commonStyle } from '../../../assets/css/css';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import '../../../assets/css/dragAndDrop.css';
import { useHistory, useLocation } from 'react-router-dom';
import BackIcon from '../../../assets/images/backArrowBlue.png';
import { GET } from '../../../services/HttpRequests';
import DataCard from '../component/DataCard';
import DataTable from '../../../common/component/table/DataTable';
import ExportCampaignWiseReport from '../component/ExportCampaignWiseReport';
import AudioPlayer from '../component/AudioPlayer';
import ModifyHeadersPopUp from '../../../common/component/TableHeaders/TableHeaders';
import { setCampaignCallHistoryTableHeaders } from '../../../actions/recoveryXActions';

const cloudCallCenterUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;

function CampaignReportTable(props) {
  console.log('The headers are', props?.headers);
  return (
    <DataTable.Table>
      <DataTable.Head>
        <DataTable.CellHead width="50">#</DataTable.CellHead>
        {props?.headers?.length > 0 &&
          props?.headers?.map((header) => (
            <DataTable.CellHead width="50">
              {header.toUpperCase()}
            </DataTable.CellHead>
          ))}
      </DataTable.Head>
      <DataTable.Body>
        {props?.agentsList?.length > 0 &&
          props?.agentsList?.map((agent, index) => (
            <DataTable.Row id={agent.userId}>
              <DataTable.Cell>{1 + index}</DataTable.Cell>
              {props.headers.map((header) => {
                if (header === 'DATE') {
                  return (
                    <DataTable.Cell width="50">
                      {new Date(agent.createdAt).toLocaleDateString() || '-'}
                    </DataTable.Cell>
                  );
                } else if (header === 'TIME') {
                  return (
                    <DataTable.Cell width="50">
                      {new Date(agent.createdAt).toLocaleTimeString() || '-'}
                    </DataTable.Cell>
                  );
                } else if (header === 'CALL RECORDING') {
                  return (
                    <DataTable.Cell>
                      {agent?.recordingUrl ? (
                        <AudioPlayer
                          callRecordingUrl={agent?.recordingUrl}
                          callId={agent?._id}
                        />
                      ) : (
                        ''
                      )}
                    </DataTable.Cell>
                  );
                } else {
                  return (
                    <DataTable.Cell>
                      {agent?.[CALL_LOGS_MAPPER[header]] || '-'}
                    </DataTable.Cell>
                  );
                }
              })}
            </DataTable.Row>
          ))}
      </DataTable.Body>
    </DataTable.Table>
  );
}

export function CampaignCallHistory(props) {
  const [campaignData, setCampaignData] = useState({});
  const [filteredHeaders, setFilteredHeaders] = useState(
    props.campaignCallHistoryTableHeaders
  );
  const [isHeadersPopUpOpen, setIsHeadersPopUpOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');

  useEffect(() => {
    setFilteredHeaders(
      props.campaignCallHistoryTableHeaders || [
        'AGENT',
        'LEAD NAME',
        'LEAD NUMBER',
        'CALL STATUS',
        'DATE',
        'DISPOSITION',
        'CALL RECORDING',
      ]
    );
  }, [props.campaignCallHistoryTableHeaders]);

  const handleFetchCampaignDetails = (id) => {
    const data = {
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      campaignId: id,
    };
    GET(cloudCallCenterUrl + 'cloud-call-logs/get-campaign-calls', data)
      .then((res) => {
        console.log('res', res.data.response);
        setCampaignData(res.data.response);
      })
      .catch((err) => console.log(err));
  };

  const updateFilteredHeader = (header, isSelected) => {
    console.log(header, isSelected);
    if (isSelected) {
      setFilteredHeaders((headers) => [...headers, header]);
      props.setCampaignCallHistoryTableHeaders({
        campaignCallHistoryTableHeaders: [...filteredHeaders, header],
      });
    } else {
      const unFilteredHeaders = filteredHeaders.filter(
        (headerType) => headerType !== header
      );
      setFilteredHeaders(unFilteredHeaders);
      props.setCampaignCallHistoryTableHeaders({
        campaignCallHistoryTableHeaders: unFilteredHeaders,
      });
    }
  };

  useEffect(() => {
    if (id) {
      handleFetchCampaignDetails(id);
    }
  }, [id]);

  return (
    <div className={`${props.className}`}>
      <Topbar />
      <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
        <Sidebar />
        <div className="ContentContainer">
          <TabSearchBar>
            <div
              style={{
                marginLeft: '24px',
                display: 'flex',
                gap: '24px',
                alignItems: 'center',
              }}
            >
              <img
                src={BackIcon}
                style={{ cursor: 'pointer' }}
                onClick={() => history.push(`/campaigns-v2`)}
              />
              <p
                style={{
                  margin: 0,
                  color: '#00515a',
                  fontSize: '24px',
                  fontWeight: 500,
                }}
              >
                {campaignData?.campaignName || ''}
              </p>
              <ExportCampaignWiseReport
                campaignName={campaignData?.campaignName || ''}
                campaignData={campaignData?.campaignCallsData}
              />
              <ModifyHeadersPopUp
                headers={Object.keys(CALL_LOGS_MAPPER)}
                updateFilteredHeader={updateFilteredHeader}
                filteredHeaders={filteredHeaders}
                isPopUpOpen={isHeadersPopUpOpen}
                openHeadersPopUp={() => setIsHeadersPopUpOpen(true)}
                closeHeadersPopUp={() => setIsHeadersPopUpOpen(false)}
              />
            </div>
          </TabSearchBar>
          <div style={{ display: 'flex', gap: '32px', padding: '16px' }}>
            <DataCard title="Calls" count={campaignData?.totalCallsMade} />
            <DataCard
              title="Connected Calls"
              count={campaignData?.answeredCalls}
            />
            {/* <DataCard
              title="Calls Unanswered"
              count={campaignData?.unAnsweredCalls}
            />
            <DataCard
              title="Call Answer Rate (%)"
              count={campaignData?.answerPercentage}
            /> */}
          </div>
          <CampaignReportTable
            agentsList={campaignData?.campaignCallsData}
            headers={filteredHeaders}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  userDetails: state.recovery.userDetails,
  campaignCallHistoryTableHeaders:
    state.recovery.campaignCallHistoryTableHeaders,
});

export default commonStyle(
  connect(mapStateToProps, { setCampaignCallHistoryTableHeaders })(
    CampaignCallHistory
  )
);
