export const audioFileMaxSize = 500000; // 500kb for audio file size

export const CALL_LOGS_MAPPER = {
  AGENT: 'agentName',
  'LEAD NAME': 'leadName',
  'LEAD NUMBER': 'destinationNumber',
  'CALL STATUS': 'status',
  DURATION: 'duration',
  DATE: 'createdAt',
  TIME: 'createdAt',
  DISPOSITION: 'disposition',
  'CALL RECORDING': 'recording',
};

export const ANENT_CALL_LOGS_MAPPER = {
  'LEAD NAME': 'leadName',
  'LAST CALL DISPOSITION': 'callTag',
  'LEAD NUMBER': 'destinationNumber',
  'LAST CALL STATUS': 'status',
  'LAST CALL DURATION': 'duration',
  'DATE AND TIME': 'createdAt',
  HISTORY: 'destinationNumber',
  'CALL RECORDING': 'recording',
};

export const AGENT_DASHBOARD_CALL_LOGS_MAPPER = {
  'LEAD NAME': 'leadName',
  'LEAD NUMBER': 'destinationNumber',
  'LAST CALL STATUS': 'status',
  'LAST CALL DURATION': 'duration',
  DATE: 'createdAt',
  TIME: 'createdAt',
  DISPOSITION: 'disposition',
  'DISPOSITION COMMENT': 'callComment',
};

export const CAMPAIGNS_MAPPER = {
  'CAMPAIGN NAME': 'campaignName',
  DESCRIPTION: 'campaignDescription',
  'START DATE': 'campaignStartDate',
  'END DATE': 'campaignEndDate',
  'START TIME': 'campaignStartTime',
  'END TIME': 'campaignEndTime',
  'CAMPAIGN TYPE': 'campaignType',
  LEADS: 'totalcampainLeads',
  AGENTS: 'totalAgents',
  CALLS: 'campaignTotalCalls',
  'CONNECTED CALLS': 'campaignAnsweredCalls',
  'WAITING CALLS': 'waitingRingingMetrics',
};

export const OUTGOING_CALL_LOGS_MAPPER = {
  'INITIATOR NAME': 'zvr_name',
  'INITIATOR NUMBER': 'initiatorNumber',
  'DESTINATION NUMBER': 'destinationNumber',
  'CALL STATUS': 'status',
  DURATION: 'duration',
  'PULSE COUNT': 'pulseCount',
  TIME: 'createdAt',
  DATE: 'createdAt',
  DISPOSITION: 'callTag',
  COMMENT: 'callComment',
  RECORDING: 'recording',
  'CAMPAIGN NAME': 'campaignName',
};

export const OUTGOING_CALLS_MAPPER = {
  'OUTGOING NUMBER': 'didNumber',
  DESTINATION: 'destinationNumber',
  'CONTACT NAME': 'contact_name',
  NOTES: 'notes',
  TAGS: 'tags',
  'CALL STATUS': 'status',
  DURATION: 'duration',
  DISPOSITION: 'dispositon',
  CALLCOMMENT: 'callComment',
  'PULSE COUNT': 'duration',
  'CALL RECORD': 'recording',
  TIME: 'createdAt',
  DATE: 'createdAt',
  'CONFERENCE DETAILS': 'conferenceDetails',
  'TRANSFER CALL': 'transferDetails',
};

export const INCOMING_CALLS_MAPPER = {
  'VIRTUAL NO': 'virtual_no',
  'CALL TYPE': 'call_type',
  'DTMF CODE': 'dtmf_code',
  CALLER: 'caller',
  'CONTACT NAME': 'contact_name',
  NOTES: 'notes',
  TAGS: 'tags',
  'CALL STATUS': 'call_status',
  DURATION: 'duration',
  'PULSE COUNT': 'pulse_count',
  DISPOSITION: 'disposition',
  'CALL COMMENT': 'callComment',
  'CALL RECORD': 'call_record',
  TIME: 'time',
  DATE: 'date',
};

export const CHANNEL_PARTNERS = {
  SHIP_ROCKET: 'SHIP_ROCKET',
};
