import React, { Component, createRef } from 'react';
import Select from 'react-select';
import {
  changeTimeFormat,
  displayUserCallBalance,
  enableFeaturesByOrg,
} from '../../../utility/utility';
import { AccessTimeIcon } from '../../../assets/Icons/Icons';
import { GET, DELETE } from '../../../services/HttpRequests';
import { connect } from 'react-redux';
import { getTopBarData } from '../../../actions/topBarActions';
import '../../../assets/css/Topbar.css';
import styled from '@emotion/styled';
import moment from 'moment';
import Popup from 'reactjs-popup';
import Tooltip from './TopbarTooltip';

import { Button } from 'react-bootstrap';
import {
  startRun,
  setStepIndexToZero,
} from '../../../actions/onBoardingTourActions';

import profileImage from '../../../assets/images/user_profile.svg';
import Profile from '../Profile/Profile';
import AgentStatusDropDown from '../agentStatus/AgentStatusDropDown';
import DailPad from '../dailPad/DailPad';
import ChargebeePortalButton from './../chargbee/ChargebeePortalButton';
import ChargebeeCheckoutButton from './../chargbee/ChargebeeCheckoutButton';
import BeginTourPopup from '../freeTrial/BeginTourPopup';
import Alert from '../../../assets/images/notification-14158.png';
import BuyCallMinutes from '../buyCallMinutes/BuyCallMinutes';
import TopbarExtras from '../topbarExtras';
import Logo from '../../../assets/images/alohaaLogoWOText.svg';
const userUrl = process.env.REACT_APP_USER_API;
const orgUrl = process.env.REACT_APP_ORGANIZATION_API;
const isSuperAdmin = () =>
  localStorage.getItem('doosra-biz-user-role-is-super-admin') === 'true';

class Topbar extends Component {
  constructor() {
    super();
    this.state = {
      organisationList: [],
      selectedOrg: {
        value: localStorage.getItem('doosra-biz-organisation-id'),
        label:
          localStorage.getItem('doosra-biz-organisation-name') ||
          'Doosra-Engineering',
        showBeginTourPopup: false,
      },
    };
  }

  componentWillMount() {
    this.props.getTopBarData();
    this.checkFreeTrail();
    if (isSuperAdmin()) {
      this.getOrganisationList();
    }
    const hasShownPopup = localStorage.getItem('hasShownBeginTourPopup');
    if (!hasShownPopup) {
      this.setState({ showBeginTourPopup: true });
    }
  }

  checkFreeTrail() {
    const startDateString = localStorage.getItem('free_trial_start_date');
    const endDateString = localStorage.getItem('free_trial_end_date');
    if (startDateString) {
      const startDate = new Date(startDateString);
      const endDate = new Date(endDateString);
      const currentDate = new Date();

      if (currentDate > endDate) {
        // this.logoutHandler();
      }
    }
  }

  componentDidMount() {
    this.props.getTopBarData();
    if (isSuperAdmin()) {
      this.getOrganisationList();
    }
  }

  getOrganisationList = () => {
    GET(orgUrl + `v1/organisation/list?isClientPortalRequest=${true}`)
      .then((res) => res.data.response)
      .then((res) => {
        this.setState({ organisationList: res });
        const org = res.find(
          (org) =>
            org.value === localStorage.getItem('doosra-biz-organisation-id')
        );
        this.setState({ selectedOrg: org });
      });
  };

  logoutHandler() {
    DELETE(`${userUrl}v1/auth/logout`)
      .then((res) => {
        if (res?.data?.response?.user_id) {
          localStorage.clear();
          sessionStorage.clear();
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    let userRole = localStorage.getItem('doosra-biz-user-role');

    const {
      balance,
      name,
      logo,
      callForwardingStatus,
      isOrgOutboundCallEnabled,
      isDialerWidgetEnabled,
      isOutboundCallEnabled,
      isVoiceBroadcastEnabled,
      vmnExpireDate,
      isOutgoingCallsDowntime,
      isNumberMaskingEnabled,
      isOrgOutgoingCallEnabled
    } = this.props;
    console.log('logo is here for test data ', logo);
    let {
      minuteBalance,
      user_call_minutes_available,
      user_endTime,
      user_startTime,
      creditBalance,
      pulseCost,
      pulseDuration,
    } = this.props;
    const userCallBalance = displayUserCallBalance(user_call_minutes_available);
    if (minuteBalance) {
      minuteBalance = minuteBalance / 60;
      minuteBalance = Math.floor(minuteBalance);
    } else {
      minuteBalance = 0;
    }
    let userDoosraNumber = localStorage.getItem(
      'doosra-biz-user-doosra-number'
    );
    let isFreemiumUsed = JSON.parse(
      localStorage.getItem('is_freemium_plan_used')
    );
    let isFreeTrail = localStorage.getItem('is_free_trial_enabled');
    isFreeTrail = isFreeTrail ? (isFreeTrail === 'true' ? true : false) : false;
    // isKycCompleted = isKycCompleted
    //   ? isKycCompleted === 'true'
    //     ? true
    //     : false
    //   : false;
    if (userDoosraNumber === 'NA') {
      userDoosraNumber = '';
    }
    if (userDoosraNumber && userDoosraNumber.length === 12) {
      userDoosraNumber = userDoosraNumber.substring(2);
    }

    const updateOrganisationAndReload = (data) => {
      localStorage.setItem('doosra-biz-organisation-id', data.value);
      localStorage.setItem('doosra-biz-organisation-name', data.label);
      window.location.reload();
    };

    const closeBeginTourPopup = () => {
      this.setState({ showBeginTourPopup: false });
      localStorage.setItem('hasShownBeginTourPopup', true);
    };
    const startTour = () => {
      this.props.startRun();
      this.props.setStepIndexToZero();
    };

    console.log(
      '----->>>>',
      isOutboundCallEnabled,
      isOrgOutboundCallEnabled,
      isDialerWidgetEnabled
    );

    return (
      <div className={`${this.props.className}`}>
        {/* {this.state.showBeginTourPopup && isFreeTrail ? (
          <BeginTourPopup
            closePopup={closeBeginTourPopup}
            startTour={startTour}
          />
        ) : null} */}
        {console.log('the exp date is', vmnExpireDate)}
        {userRole === 'USER' ? (
          userDoosraNumber ? (
            new Date(vmnExpireDate) <= new Date() ? (
              <>
                <div
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'space-around',
                    height: '40px',
                    backgroundColor: '#ed3833',
                  }}
                >
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      color: '#f1f2f2',
                      padding: 10,
                    }}
                  >
                    Your VMN has expired. Please reach out to your Administrator
                    to re-enable it.
                  </span>
                </div>
              </>
            ) : null
          ) : null
        ) : null}
        {isOutgoingCallsDowntime && userRole !== 'USER' && (
          <div className="Dialer-info-text" style={{ display: 'flex' }}>
            <img
              alt=""
              src={Alert}
              style={{
                paddingRight: '5px',
                width: '25px',
                height: '18px',
              }}
            />
            Call services are facing temporary downtime. This may affect agent's
            call activities. We value your understanding and are actively
            working to restore normal service promptly.
          </div>
        )}
        <div className="TopbarContainer">
          <div style={{ display: 'flex' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '96px',
                height: '72px',
              }}
            >
              <img src={Logo} alt="Logo" style={{ width: '40px' }} />
            </div>
            <div
              id="searchValue"
              className="TopbarSecondContainer"
              style={{ width: '120px' }}
            >
              {/* {(isFreeTrail && !isKycCompleted) ? (
                < React.Fragment >
                  {userRole !== 'USER' && <>
                    <br />
                    <Button className="btn btn-danger" onClick={this.logoutHandler}>Logout</Button>
                  </>}
                </React.Fragment>) : null
              } */}
              {isFreeTrail && userRole !== 'USER' && (
                <span>
                  {' '}
                  <Button
                    size="sm"
                    className="start-tour"
                    variant="outline-primary"
                    onClick={startTour}
                  >
                    Start Tour
                  </Button>
                </span>
              )}
              {callForwardingStatus === true ||
                isOrgOutboundCallEnabled === true ||
                isNumberMaskingEnabled === true ? (
                <React.Fragment>
                  {userRole !== 'USER' && !isFreeTrail && (
                    <>
                      <p className="TopbarAccBalText">Call Balance</p>
                      <p className="TopbarAccBalAmt">{minuteBalance} min</p>
                    </>
                  )}
                </React.Fragment>
              ) : null}
              {userRole === 'USER' ? (
                userDoosraNumber ? (
                  vmnExpireDate ? (
                    <React.Fragment>
                      <p className="TopbarAccBalText">Validity</p>
                      <p className="TopbarAccBalAmt">
                        {moment(vmnExpireDate).format('Do MMM YYYY')}
                      </p>
                    </React.Fragment>
                  ) : null
                ) : null
              ) : null}
            </div>
            {/* {(callForwardingStatus === true ||
              isOrgOutboundCallEnabled === true) &&
              userRole !== 'USER' &&
              !isFreeTrail &&
              !this.props.isCampaignEnabled && (
                <div
                  id="searchValue"
                  className="TopbarSecondContainer"
                  style={{ width: '120px' }}
                >
                  <BuyCallMinutes />
                </div>
              )} */}
            <div
              id="searchValue"
              className="TopbarSecondContainer"
              style={{ width: '170px' }}
            >
              <React.Fragment>
                {userRole !== 'USER' && isVoiceBroadcastEnabled === true && (
                  <>
                    <Tooltip
                      creditBalance={creditBalance}
                      pulseCost={pulseCost}
                      pulseDuration={pulseDuration}
                    />
                  </>
                )}
              </React.Fragment>
            </div>
            <div
              id="searchValue"
              className="TopbarSecondContainer"
              style={{ width: '170px' }}
            >
              {/* <React.Fragment>
                {userRole !== 'USER' && (
                  <>
                    <p className="TopbarAccBalText">SMS Credits</p>
                    <p className="TopbarAccBalAmt">{minuteBalance} min</p>
                  </>
                )}
              </React.Fragment> */}
            </div>
            {isSuperAdmin() ? (
              <div
                style={{
                  marginTop: '15px',
                  marginLeft: '10px',
                  width: '180px',
                }}
              >
                {/* Super Admin with Permission sends true for org admin
              adding addition isSuperAdmin check to block that */}
                <Select
                  options={this.state.organisationList}
                  defaultValue={this.state.selectedOrg}
                  onChange={updateOrganisationAndReload}
                ></Select>
              </div>
            ) : null}
            {!isSuperAdmin() && userRole !== 'USER' && isFreemiumUsed && (
              <div
                style={{
                  marginTop: '15px',
                  marginLeft: '10px',
                  width: '230px',
                }}
              >
                <TopbarExtras />
              </div>
            )}
          </div>
          <div className="TopbarCompanyContainer">
            <div className="TopbarThirdContainer">
              {/* <div>
                {' '}
                <p className="TopbarCompanyName">{name}</p>
              </div>
              {enableFeaturesByOrg() ? (
                <p className="TopbarCompanyName TopbarUserTime">
                  {user_startTime ? (
                    <>
                      <AccessTimeIcon className="TimeIcon" />
                      {`${changeTimeFormat(
                        user_startTime
                      )} - ${changeTimeFormat(user_endTime)} `}
                      &nbsp;&nbsp;
                    </>
                  ) : (
                    ''
                  )}
                  {userDoosraNumber ? `+91 ${userDoosraNumber}` : ''}
                </p>
              ) : (
                ''
              )} */}
              {
                userRole === 'USER' &&
                isOrgOutboundCallEnabled &&
                isDialerWidgetEnabled && <DailPad
                  isAgentOutboundCallEnabled={isOutboundCallEnabled && isOrgOutgoingCallEnabled}
                ></DailPad>}{' '}
              &nbsp;&nbsp;
              {
                userRole === 'USER' &&
                isOrgOutboundCallEnabled &&
                isDialerWidgetEnabled && (
                  <AgentStatusDropDown ></AgentStatusDropDown>
                )}
            </div>

            {/* {!logo.includes('undefined') && (
              <img
                alt="profileImage"
                className="TopbarLogo"
                src={logo + `?h=${Date.now()}`}
              />
            )}
            {logo.includes('undefined') && (
              <img
                alt="profileImage"
                className="TopbarLogo"
                src={profileImage}
              />
            )} */}
            <Profile></Profile>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  balance: state.topbar.balance,
  name: state.topbar.name,
  logo: state.topbar.logo,
  minuteBalance: state.topbar.minuteBalance,
  callForwardingStatus: state.topbar.call_forwarding_status,
  isCampaignEnabled: state.topbar.isCampaignEnabled,
  isOrgOutboundCallEnabled: state.topbar.isOrgOutboundCallEnabled,
  isOrgOutgoingCallEnabled: state.topbar.isOrgOutgoingCallEnabled,
  isDialerWidgetEnabled: state.topbar.isDialerWidgetEnabled,
  isOutboundCallEnabled: state.topbar.isOutboundCallEnabled,
  isVoiceBroadcastEnabled: state.topbar.isVoiceBroadcastEnabled,
  isVoiceApiEnabled: state.topbar.isVoiceApiEnabled,
  user_call_minutes_available: state.topbar?.user_call_minutes_available,
  user_endTime: state.topbar?.user_endTime,
  user_startTime: state.topbar?.user_startTime,
  vmnExpireDate: state.topbar?.vmnExpireDate,
  run: state.onboardingtour?.run,
  stepIndex: state.onboardingtour?.stepIndex,
  steps: state.onboardingtour?.steps,
  isOutgoingCallsDowntime: state.topbar.isOutgoingCallsDowntime,
  isNumberMaskingEnabled: state.topbar.isNumberMaskingEnabled,
  creditBalance: state.topbar.creditBalance,
  pulseCost: state.topbar.pulseCost,
  pulseDuration: state.topbar.pulseDuration,
  agentStatus: state.dailpad.agentStatus,
  RTCcallStatus: state.recovery.RTCcallStatus,
  currentplanProduct: state.topbar.currentplanProduct,
});

export default styled(
  connect(mapStateToProps, { startRun, setStepIndexToZero, getTopBarData })(
    Topbar
  )
)`
  position: relative;
  .TopbarContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 72px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background: ${(props) => props.theme.colors.topBarBg};
  }

  .TopbarFirstContainer {
    margin: auto;
  }

  .TopbarDataContainer {
    height: 100%;
    width: 180px;
    text-align: left;
  }

  .TopbarAccBalText {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    width: fit-content;
    opacity: 0.5;
    color: ${(props) => props.theme.colors.topBarAccTextColor};
    margin: 14px 0 0 6px;
  }

  .TopbarAccBalAmt {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    width: fit-content;
    color: ${(props) => props.theme.colors.primaryColor1};
    margin: 0 0 0 6px;
  }

  .TopbarThirdContainer {
    margin: auto 0;
    display: flex;
    flex-direction: row;
    text-align: right;
    padding-right: 10px;
    align-items: center;
    justify-content: flex-end;
  }

  .TopbarCompanyContainer {
    width: 4100px;
    display: grid;
    grid-template-columns: auto 50px 20px;
  }
  .btn-outline-primary {
    color: #007bff;
    border-color: #007bff;
  }

  .start-tour {
    margin-left: 15px;
    margin-top: 20px;
    display: block;
    color: #007bff;
    border-color: #007bff;
  }

  .start-tour:hover,
  .start-tour:focus {
    background-color: rgb(0, 123, 255) !important;
    color: #ffffff !important;
    border-color: #007bff !important;
  }

  .TopbarCompanyName {
    /* float: right; */
    /* width: fit-content; */
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
    color: ${(props) => props.theme.colors.topBarAccTextColor};
  }

  .TopbarUserTime {
    color: #196cca;
    ine-height: 19px;
    font-size: 16px;
  }

  .TopbarLogo {
    margin: auto 0;
    float: right;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #196cca;
    object-fit: contain;
  }

  .Dailer {
    margin: auto 0;
    float: right;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #f8153b;
    object-fit: contain;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .DailerInActive {
    margin: auto 0;
    float: right;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #b8b8b8;
    object-fit: contain;
  }

  .TimeIcon {
    margin-right: 6px;
    margin-bottom: 4px;
  }
  @media only screen and (max-width: 900px) {
    .TopbarContainer {
      display: grid;
      grid-template-columns: 150px auto 150px 100px;
      width: 100%;
      height: 72px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
  }
  .To .top-menu-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #f8f9fa;
    padding: 10px 20px;
    border-bottom: 1px solid #e0e0e0;
  }

  .dialer-icon {
    margin: 0 15px;
    cursor: pointer;
  }

  .dialer-icon.disabled {
    color: #d6d6d6; /* Light gray color for disabled state */
    cursor: not-allowed;
  }

  .dialer-icon.enabled {
    color: #6c757d; /* Original color for enabled state */
  }

  .status-dropdown .dropdown-toggle {
    background-color: white;
    border: none;
    width: 162px;
    align-items: center;
    padding: 5px 10px;
  }

  .status-dropdown .status-indicator {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }

  .status-dropdown .status-indicator.online {
    background-color: green;
  }

  .status-dropdown .status-indicator.offline {
    background-color: gray;
  }

  .status-dropdown .status-indicator.busy {
    background-color: red;
  }

  .status-dropdown .status-indicator.break {
    background-color: #f8bb44;
  }

  .status-dropdown .status-indicator.calling {
    background-color: #f8bb44;
  }

  .status-dropdown .status-indicator.tea {
    background-color: #7c6f68;
  }
  .status-dropdown .status-indicator.lunch {
    background-color: #bd8c06;
  }
  .status-dropdown .status-indicator.meeting {
    background-color: #b571a2;
  }
  .status-dropdown .status-indicator.training {
    background-color: #306cca;
  }
  .status-dropdown .status-indicator.biobreak {
    background-color: #42a1e2;
  }

  .dropdown-toggle::after {
    display: none !important;
  }
`;
