import {
  TOPBAR_DATA_SUCCESS,
  USER_PROFILE_DETAILS,
  TOPBAR_VOICE_CREDITS_DATA_SUCCESS,
} from '../actions/topBarActions';

const initialState = {
  balance: 0,
  logo: '',
  name: '',
  minute_balance: 0,
  call_forwarding_status: false,
  accountStatus: null,
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case USER_PROFILE_DETAILS:
      return {
        ...state,
        phone_number: action.payload?.phone_number || '',
        user_name: action.payload?.name || '',
        user_email: action.payload?.email || '',
        isOutboundCallEnabled: action.payload?.isOutboundCallEnabled || false,
        isCallForwardingAllowed:
          action.payload?.isCallForwardingAllowed || false,
        user_call_minutes_available:
          action.payload?.user_call_minutes_available,
        user_endTime: action.payload?.user_endTime,
        user_startTime: action.payload?.user_startTime,
        vmnExpireDate: action.payload?.organisation?.vmnExpireDate,
        incomingPreferenceMode: action.payload?.incoming_preference || '-',
        internalCommunicationMode:
          action.payload?.internalCommunicationMode || '-',
      };
    case TOPBAR_DATA_SUCCESS:
      return {
        ...state,
        balance: action.balance,
        logo: action.data.logo_path + action.data.logo,
        name: action.data.name,
        call_priority_routing:
          action.data?.add_ons?.is_priority_routing_enabled || false,
        isPriorityCallRoutingAllowed:
          action?.data?.add_ons?.is_priority_based_call_routing_enabled ||
          false,
        isRoundRobinCallRoutingAllowed:
          action?.data?.add_ons?.is_round_robin_based_call_routing_enabled ||
          false,
        call_forwarding_status:
          action.data?.add_ons?.is_incoming_call_enabled || false,
        isIncomingStickyAgentEnabled:
          action.data.add_ons.is_call_sticky_agent_enabled || false,
        isOrgOutboundCallEnabled:
          action.data?.add_ons?.is_outgoing_call_enabled ||
          action.data?.add_ons?.is_dialer_widget_enabled ||
          false,
        isOrgOutgoingCallEnabled:
          action.data?.add_ons?.is_outgoing_call_enabled || false,
        isIncomingWebrtcEnabled:
          action.data?.add_ons?.incoming_webrtc_enabled || false,
        isOutgoingStickyAgentEnabled:
          action.data?.add_ons?.is_outgoing_call_sticky_agent_enabled || false,
        isDialerWidgetEnabled: action.data?.add_ons?.is_dialer_widget_enabled,
        minuteBalance: action.data.call_minutes_available,
        callerTuneUrl: action.data.callerTuneUrl || '',
        isCallerTuneEnabled:
          action.data?.add_ons?.is_caller_tune_enabled || false,
        isReocveryXEnabled:
          action.data?.add_ons?.is_recovery_x_enabled || false,
        isNumberMaskingEnabled:
          action.data?.add_ons?.is_number_masking_enabled || false,
        isAnalyticsEnabled:
          action?.data?.add_ons?.is_analytics_enabled || false,
        isCampaignV2Enabled:
          action?.data?.add_ons?.is_campaigns_v2_enabled || false,
        isSmsInboxEnabled: !(
          action.data?.add_ons?.is_sms_inbox_enabled === false
        ),
        isCallLogEmailSent:
          action.data?.upgrade_services?.is_call_logs_email_sent || false,
        isRecoveryXEmailSent:
          action.data?.upgrade_services?.is_recoveryx_email_sent || false,
        isSmsInboxEmailSent:
          action.data?.upgrade_services?.is_sms_inbox_email_sent || false,
        isNumberMaskingEmailSent:
          action.data?.upgrade_services?.is_number_masking_email_sent || false,
        isCallRoutingEmailSent:
          action.data?.upgrade_services?.is_call_routing_email_sent || false,
        isCallerTuneEmailSent:
          action.data?.upgrade_services?.is_caller_tune_email_sent || false,
        isWebhookEmailSent:
          action.data?.upgrade_services?.is_webhooks_email_sent || false,
        isFreeTrialEmailSent:
          action.data?.upgrade_services?.is_free_trial_email_sent || false,

        isWebrtcEnabled: action.data?.add_ons?.is_webrtc_enabled || false,
        isSenderWhiteListEnabled:
          action.data?.add_ons?.is_sender_white_list_enabled || false,
        isUnansweredAgentEnabled:
          action.data?.add_ons?.is_unanswered_agent_enabled || false,
        isAutoAttendantEnabled:
          action.data?.add_ons?.is_auto_attendant_enabled || false,
        isCampaignEnabled: action.data?.add_ons?.is_campaign_enabled || false,
        isMessageBroadcastEnabled:
          action.data?.add_ons?.is_message_broadcast_enabled || false,
        isVoiceBroadcastEnabled:
          action.data?.add_ons?.is_voice_broadcast_enabled || false,
        isIntegrationsEnabled:
          action?.data?.add_ons?.is_integrations_enabled || false,
        isVoiceApiEnabled: action.data?.add_ons?.is_voice_api_enabled || false,
        isFreeFormEnabled: action.data?.add_ons?.is_free_form_enabled || false,
        isHideCustomerEnabled:
          action.data?.add_ons?.is_hide_customer_details_enabled || false,
        isOutgoingCallsDowntime: action.data?.outgoingCallsDowntime || false,
        isCCOutgoingCallsEnabled:
          action.data?.add_ons?.is_cloud_call_center_enabled || false,
        isCCWebrtcEnabled:
          action.data?.add_ons?.is_cloud_webrtc_enabled || false,
        accountStatus: action.data?.status,
        currentplanProduct: action.data?.PlanData?.product,
        agentEmailVerification:
          action?.data?.settings?.agent_email_verification_required,
        isPriorityRoutingEnabled:
          action?.data?.add_ons?.is_priority_routing_enabled || false,
      };
    case TOPBAR_VOICE_CREDITS_DATA_SUCCESS:
      return {
        ...state,
        creditBalance: action?.payload?.balance || 0,
        pulseCost: action?.payload?.pulseCost || 0,
        pulseDuration: action?.payload?.pulseDuration || 0,
      };
    default:
      return state;
  }
}
