import React, { useEffect, useState } from "react";
import CallFlowStyle from "../callFlow/CallFlow.css";
import { useDispatch, useSelector } from 'react-redux';
import { SET_SIDEBAR_OPEN } from "../../actions/callFlowActions";
import Phone from "../../assets/images/phone_callback.png";
import Audio from "../../assets/images/audio_file.png";
import SupportAgent from "../../assets/images/support_agent.png";
import GroupIcon from "../../assets/images/group.png";
import Dialpad from "../../assets/images/dialpad.png";
import Group1 from "../../assets/images/Group1.png";
import { toast } from 'react-toastify';
import { Checkbox } from '@material-ui/core';
import callFlowWebhook from "../../assets/images/callFlowWebhook.png";

const Menubar = ({ handleSaveData, dids, agents, groups, audioFiles, nodes }) => {
  const dispatch = useDispatch();
  const [ivrNumber, setIvrNumber] = useState("");
  const [audioFile, setAudioFile] = useState(null);
  const [agent, setAgent] = useState("");
  const [group, setGroup] = useState("");
  const dailPadValues = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"];
  const [selectedNumber, setSelectedNumbers] = useState([]);
  const [uploadedAudioUrl, setUploadedAudioUrl] = useState(null);
  const [isAgentViaApi, setIsAgentViaApi] = useState(false);
  const [agentApiData, setAgentApiData] = useState({ method: "GET", headers: {}, url: "" });
  const [webhookData, setWebhookData] = useState({ method: "POST", headers: {}, url: "", title: "" });
  const handleDailPadSelect = (value) => {
    if (selectedNumber.includes(value)) {
      setSelectedNumbers(selectedNumber.filter((num) => num !== value));
    } else {
      setSelectedNumbers([...selectedNumber, value]);
    }
  }

  const closeSideBar = () => {
    dispatch({ type: SET_SIDEBAR_OPEN, payload: { isSideBarOpen: false  }})
  }
  const menuName = useSelector(state => state?.callFlow?.menuName);
  const nodeId = useSelector(state => state?.callFlow?.id);
  const data = useSelector(state => state?.callFlow?.data);

  useEffect(() => {
    if (menuName === "IVR" && data) {
      setIvrNumber(data)
    }
    if (menuName === "MENU" && data) {
      const uploadedAudio = audioFiles?.filter((x) => x?.nodeId === nodeId);
      if(uploadedAudio?.length > 0) {
        setAudioFile(uploadedAudio[0]?.audioFile);
      }
      if (uploadedAudio <= 0) {
        const menuNodeData = nodes.filter((x) => x?.id === nodeId);
        setUploadedAudioUrl(menuNodeData?.[0]?.audioUrl);
      }
      setSelectedNumbers(data);
    }
    if (menuName === "AUDIO" && data) {
      setAudioFile(data);
    }
    if (menuName === "AGENT" && data) {
      if (!data?.additionalInfo?.isAgentViaApi) {
        setAgent({
          zvr_name: data?.label,
          zvr_mobile_no: data?.additionalInfo?.mobileNo,
          user_id: data?.additionalInfo?.userId
        });
      } else {
        setIsAgentViaApi(true);
        setAgentApiData({
          method: data?.additionalInfo?.agentApi?.method || "GET",
          headers: data?.additionalInfo?.agentApi?.headers || {},
          url: data?.additionalInfo?.agentApi?.url || ""
        })
      }
    }
    if (menuName === "GROUP" && data) {
      setGroup({
        name: data?.label,
        _id: data?.additionalInfo?.groupId
      });
    }
    if (menuName === "WEBHOOK" && data) {
      setWebhookData({
        method: data?.additionalInfo?.webhook?.method || "POST",
        headers: data?.additionalInfo?.webhook?.headers || {},
        url: data?.additionalInfo?.webhook?.url || "",
        title: data?.additionalInfo?.webhook?.title || ""
      });
    }
  }, [data])

  const handleIvrChange = (event) => {
    if (event.target.value) {
      setIvrNumber(event.target.value);
    }
  }

  const handleAgentChange = (event) => {
    if (event?.user_id) {
      setAgent(event);
    }
  }

  const handleGroupChange = (groupData) => {
    if (groupData?._id) {
      setGroup(groupData);
    };
  }

  const hasEmptyKeyAndValue = (obj) => {
    for (const key in obj) {
        if (key.trim() === "" && obj[key] === "") {
            return true;
        }
    }
    return false; // No empty key-value pairs
  }

  const handleSave = () => {
    switch (menuName) {
      case "IVR": 
        if (!ivrNumber) {
          toast.error("Please Select IVR Number");
        } else {
          handleSaveData(nodeId, { label: ivrNumber, additionalInfo: '' } );
          closeSideBar();
        }
      break;
      case "AUDIO":
        if (!audioFile) {
          toast.error("Please Select Audio file");
        } else {
          handleSaveData(nodeId, { label: audioFile?.name, additionalInfo: 'AUTOATTENDANT', audioFile: audioFile } );
          closeSideBar();
        }
      break;
      case "AGENT":
        if (!isAgentViaApi) {
          if (!agent?.user_id) {
            toast.error("Please Select Agent");
          } else {
            handleSaveData(nodeId, { label: agent?.zvr_name, additionalInfo: { mobileNo: agent?.zvr_mobile_no, userId: agent?.user_id } } );
            closeSideBar();
          }
        } else {
          if (!agentApiData?.url) {
            toast.error("Please Enter cURL");
          } else {
            let apiData = agentApiData;
            const isHeaderEmpty = hasEmptyKeyAndValue(agentApiData?.headers);
            if (isHeaderEmpty) {
              apiData = {
                ...apiData,
                headers: {}
              }
              setAgentApiData({
                ...apiData,
                headers: {}
              })
            }
            handleSaveData(nodeId, { label: "Agent via API", additionalInfo:{
              isAgentViaApi: true,
              agentApi: apiData
            }});
            closeSideBar();
          }
        }
      break;
      case "GROUP":
        if (!group?._id) {
          toast.error("Please Select Group");
        } else {
          handleSaveData(nodeId, { label: group?.name, additionalInfo: { groupId: group?._id } } );
          closeSideBar();
        }
      break;
      case "MENU":
        if (selectedNumber?.length <= 0 || (!audioFile && !uploadedAudioUrl)) {
          toast.error("Please Select Menu Options and Audio");
        } else {
          handleSaveData(nodeId, { label: selectedNumber, additionalInfo: 'MENU', audioFile: audioFile } );
          closeSideBar();
        }
      break;
      case "WEBHOOK":
        if (!webhookData?.title) {
          toast.error("Please Enter Title");
        } else if (!webhookData?.url) {
          toast.error("Please Enter URL");
        } else {
          let webhookObj = webhookData;
          const isHeaderEmpty = hasEmptyKeyAndValue(webhookData?.headers);
          if (isHeaderEmpty) {
            webhookObj = {
              ...webhookObj,
              headers: {}
            }
            setWebhookData({
              ...webhookObj,
              headers: {}
            })
          }
          handleSaveData(nodeId, { label: webhookData?.title, additionalInfo:{
            webhook: webhookObj
          }});
          closeSideBar();
        }
      break;
    }
  }

  const handleFileChange  = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAudioFile(file);
    }
  }

  const handleAgentApiDataChange = (e, input) => {
    if (input === "curl") {
      setAgentApiData({
        ...agentApiData,
        url: e.target.value
      })
    } 
    if (input === "param") {
      const header = agentApiData.headers;
      const paramKey = Object.keys(header)[0];
      setAgentApiData({
        ...agentApiData,
        headers: {
          [e.target.value]: header[paramKey]
        }
      })
    } 
    if (input === "paramValue") {
      const header = agentApiData.headers;
      const paramKey = Object.keys(header)[0];
      setAgentApiData({
        ...agentApiData,
        headers: {
          [paramKey]: e.target.value
        }
      })
    } 
  }

  const handleWebhookChange = (e, input) => {
    if (input === "title") {
      setWebhookData({
        ...webhookData,
        title:  e.target.value
      })
    }
    if (input === "url") {
      setWebhookData({
        ...webhookData,
        url: e.target.value
      })
    }
    if (input === "param") {
      const header = webhookData.headers;
      const paramKey = Object.keys(header)[0];
      setWebhookData({
        ...webhookData,
        headers: {
          [e.target.value]: header[paramKey]
        }
      })
    } 
    if (input === "paramValue") {
      const header = webhookData.headers;
      const paramKey = Object.keys(header)[0];
      setWebhookData({
        ...webhookData,
        headers: {
          [paramKey]: e.target.value
        }
      })
    } 
  }
  
  return (
    <div className="SidebarBox" >
      <div className="SideBarHeader">
        <text onClick={closeSideBar} className="CancelText">CANCEL</text>
        <text className="SaveText" onClick={handleSave}>SAVE</text>
      </div>
      {
        menuName === "IVR"
          ? <div className="SideBarBody">
            <div style={{ display: "flex", flexDirection: "row", marginTop: "86px", marginLeft: "40px" }}>
              <img style={{ width: "24px", height: "24px", marginTop: "3px" }} src={Phone} />
              <h4 className="StartIvrHeading" style={{ marginLeft: "5px" }}>Start IVR</h4>
            </div>
            <h4 className="SubHeading">Select a number that begins this IVR program</h4>
            <h6 className="SubHeading" style={{ color: 'black', marginTop: "40px" }}>Select number</h6>
            <div className="HeaderFooter">
              <select value={ivrNumber} onChange={handleIvrChange} className="FooterSelectBox">
              <option value="" >
                --select--
              </option>
                {
                  dids?.map((did) => (
                    <option value={did}>{did}</option>
                  ))
                }
              </select>
            </div>
          </div>
        :  menuName === "AUDIO"
            ? <div className="SideBarBody">
              <div style={{ display: "flex", flexDirection: "row", marginTop: "86px", marginLeft: "40px" }}>
                <img style={{ width: "24px", height: "24px", marginTop: "3px" }} src={Audio} />
                <h4 className="StartIvrHeading" style={{ marginLeft: "5px", color: "#235789" }}>Play Audio</h4>
              </div>
              <h4 className="SubHeading">Select an audio file</h4>
              <div className="UploadAudioButton">
              <label  htmlFor="audioFile"  className="UploadAudioText">
                Upload audio
              </label>
              <input
                type="file"
                id="audioFile"
                 accept=".wav"
                onChange={handleFileChange}
                style={{ display: 'none', cursor: "pointer" }}
              />
              </div>
              {audioFile && <p className="SubHeading">{audioFile.name}</p>}
            </div> 
        : menuName === "MENU"
        ? <div className="SideBarBody">
        <div style={{ display: "flex", flexDirection: "row", marginTop: "34px", marginLeft: "40px" }}>
          <img style={{ width: "24px", height: "24px", marginTop: "3px" }} src={Dialpad} />
          <h4 className="StartIvrHeading" style={{ marginLeft: "5px", color: "#5C5C5C" }}>Menu</h4>
        </div>
        <h4 className="SubHeading">Configure dial options for customers on call</h4>
        <div className={`DailpadContainer`}>
          {dailPadValues.map((x) => {
            const valueSelected = selectedNumber?.includes(x);
            return (
              <div onClick={() => handleDailPadSelect(x)} className={`DailpadItem ${valueSelected ? `DailPadSelected` : ''}`}>
                <text className="DailpadText">{x}</text>
                {
                  valueSelected &&  <img className="DailPadTickMark" src={Group1} />
                }
              </div>
            )
          })}
        </div>
        <div className="MenuAudioContainer">
            <div style={{ display: "flex", flexDirection: "row" }}>
                <img style={{ width: "24px", height: "24px" }} src={Audio} />
                <h4 className="StartIvrHeading" style={{ marginLeft: "5px", color: "#235789", whiteSpace: 'wrap' }}>Play Audio</h4>
            </div>
            <h4 className="MenuAudioSubHeading">Select an audio file that plays for customers when they’re on this menu</h4>
            {audioFile ?
              <h4 className="MenuAudioSubHeading">{audioFile.name}</h4>
              : uploadedAudioUrl ? <h4 className="MenuAudioSubHeading">{uploadedAudioUrl}</h4> : null}
            <div className="UploadAudioButton" style={{ marginLeft: "0px" }}>
              <input
                type="file"
                id="audioFile"
                accept=".wav"
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
              <label  htmlFor="audioFile"  className="UploadAudioText">
                Upload audio
              </label>
              </div>
          </div>
      </div>
        : menuName === "AGENT"
        ? <div className="SideBarBody">
        <div style={{ display: "flex", flexDirection: "row", marginTop: "86px", marginLeft: "40px" }}>
          <img style={{ width: "24px", height: "24px", marginTop: "3px" }} src={SupportAgent} />
          <h4 className="StartIvrHeading" style={{ marginLeft: "5px", color: "#D55672" }}>Agent</h4>
        </div>
        <h4 className="SubHeading">Select an agent who answers calls in this flow</h4>

        <label className="SubHeading" style={{  color: "black", fontWeight: "400", marginTop: "40px" }}>Who do you want to redirect the call to?</label>
        <div className="agentCheckboxContainer">
          <label>
            <Checkbox
              checked={!isAgentViaApi}
              onChange={() => setIsAgentViaApi(false)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <span className="checkBoxText">Registered agent</span>
          </label>
          <label>
            <Checkbox
              checked={isAgentViaApi}
              onChange={() => setIsAgentViaApi(true)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <span className="checkBoxText">Agent via API</span>
          </label>
          <label></label>
        </div>
        {
          !isAgentViaApi ?
          <div>
            <h6 className="SubHeading" style={{ color: 'black', marginTop: "40px" }}>Select agent</h6>
            <div className="HeaderFooter">
              <select value={agent?.zvr_name} onChange={(e) => handleAgentChange(agents[e.target.selectedIndex - 1])} className="FooterSelectBox">
              <option value="" >
                --select--
              </option>
              {
                agents?.map((agent) => (
                  <option value={agent?.zvr_name}>{agent?.zvr_name}</option>
                ))
              }
              </select>
            </div>
          </div>
          : <div className="agentViaApiContainer">
              <label className="agentViaApiInputContainer">
                Enter cURL
                <input className="agentViaApiInput" style={{  width: "360px" }}  onChange={(e) => handleAgentApiDataChange(e, "curl")} value={agentApiData?.url} />
              </label>
              <label style={{ marginTop: "24px" }} className="agentViaApiInputContainer">
                Select method
                <select  style={{ marginTop: "11px" }} value="GET" className="FooterSelectBox" disabled>
                  <option value="GET"  disabled>
                    GET
                  </option>
                </select>
              </label>
              <label style={{ marginTop: "24px" }} className="agentViaApiInputContainer">
                Header
                <div style={{ display: "flex", flexDirection: 'row', marginTop: "11px" }}>
                <input className="agentViaApiInput" placeholder="Param" onChange={(e) => handleAgentApiDataChange(e, "param")}  value={Object.keys(agentApiData.headers)[0]} />
                <input style={{ marginLeft: "8px" }} className="agentViaApiInput" placeholder="Input"  onChange={(e) => handleAgentApiDataChange(e, "paramValue")} value={ agentApiData?.headers[Object.keys(agentApiData.headers)[0]]} />
                </div>
              </label>
              {/* <div className="DocumentaionContainer" style={{ marginLeft: "0px" }}>
                <a href={"https://docs.alohaa.ai/alohaa/webhooks/incoming-call-webhook"} target="_blank" className="CallFlowDocumentaion">
                    Documentation
                  </a>
              </div> */}
          </div>
        }
      </div>
        : menuName === "GROUP"
        ? <div className="SideBarBody">
        <div style={{ display: "flex", flexDirection: "row", marginTop: "86px", marginLeft: "40px" }}>
          <img style={{ width: "24px", height: "24px", marginTop: "3px" }} src={GroupIcon} />
          <h4 className="StartIvrHeading" style={{ marginLeft: "5px", color: "#6B4ACE" }}>Group</h4>
        </div>
        <h4 className="SubHeading">Select a group who answers calls in this flow</h4>
        <h6 className="SubHeading" style={{ color: 'black', marginTop: "40px" }}>Select group</h6>
        <div className="HeaderFooter">
          <select value={group?.name} onChange={(e) => handleGroupChange(groups[e.target.selectedIndex - 1])} className="FooterSelectBox">
          <option value="--select--" >
            --select--
         </option>
          {
            groups?.map((x) => (
              <option value={x?.name}>{x?.name}</option>
            ))
          }
          </select>
        </div>
      </div>
      : menuName === "WEBHOOK" ?
        <div className="SideBarBody">
        <div style={{ display: "flex", flexDirection: "row", marginTop: "86px", marginLeft: "40px" }}>
          <img style={{ width: "24px", height: "24px", marginTop: "3px" }} src={callFlowWebhook} />
          <h4 className="StartIvrHeading" style={{ marginLeft: "5px", color: "#F8BB44" }}>Webhook</h4>
        </div>
        <h4 className="SubHeading">Configure your webhook</h4>
        <div className="webhookBodyContainer">
          <label className="webhookInputContainer">
            <text className="webhookText">Webhook title</text>
            <input className="webhookInput" onChange={(e) => handleWebhookChange(e, "title")} value={webhookData?.title} />
          </label>
          <label className="webhookInputContainer" style={{ marginTop: "24px" }}>
            <text className="webhookText">URL</text>
            <input className="webhookInput" onChange={(e) => handleWebhookChange(e, "url")} value={webhookData?.url}  />
          </label>
          <label style={{ marginTop: "24px" }} className="agentViaApiInputContainer">
                Select method
                <select style={{ marginTop: "11px" }} value="GET" className="FooterSelectBox" disabled>
                  <option value="GET"  disabled>
                    POST
                  </option>
                </select>
          </label>
          <label style={{ marginTop: "24px" }} className="agentViaApiInputContainer">
                Header
                <div style={{ display: "flex", flexDirection: 'row', marginTop: "11px" }}>
                  <input className="agentViaApiInput" placeholder="Param" onChange={(e) => handleWebhookChange(e, "param")}  value={Object.keys(webhookData.headers)[0]} />
                  <input style={{ marginLeft: "8px" }} className="agentViaApiInput" placeholder="Input"  onChange={(e) => handleWebhookChange(e, "paramValue")} value={ webhookData?.headers[Object.keys(webhookData.headers)[0]]} />
                </div>
          </label>
        </div>
        <div className="DocumentaionContainer">
          <a href={"https://docs.alohaa.ai/alohaa/webhooks/incoming-call-webhook"} target="_blank" className="CallFlowDocumentaion" rel="noreferrer">
              Documentation
            </a>
        </div>
      </div>  : ""
      }
    </div>
  )
};

export default Menubar;