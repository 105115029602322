import React from 'react';
import { connect } from 'react-redux';
import { commonStyle } from '../../../assets/css/css';

// import '../../../assets/css/Content.css';
import Topbar from '../../../common/component/topbar/Topbar';
import Sidebar from '../../../common/componentV2/sidebar/Sidebar';
import TabSearchBarComponent from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import FormComponent from '../components/FormComponent';
import { Redirect } from 'react-router-dom';

const NumberMaskingCallDetailsWebhookPage = (props) => {
  const userRole = localStorage.getItem('doosra-biz-user-role');
  // const isSMSApiAvailable = localStorage.getItem('is_sms_webhook_enabled');
  const isCallApiAvailable = localStorage.getItem('is_call_webhook_enabled');
  const isOutgoingCallApiAvailable = localStorage.getItem(
    'is_outgoing_call_webhook_enabled'
  );
  const isVoiceApiAvailable = localStorage.getItem(
    'is_voice_api_webhook_enabled'
  );
  const isFreeFormEnabled = localStorage.getItem('is_free_form_enabled');
  const isNumberMaskingEnabled = localStorage.getItem(
    'is_number_masking_enabled'
  );

  const tabs = [];
  // if (userRole === 'ADMIN' && isSMSApiAvailable === 'true') {
  //     tabs.push({
  //         title: 'SMS',
  //         subtitle: 'webhook',
  //         onClick: () => props.history.push('/sms-webhook'),
  //     });
  // }
  if (userRole === 'ADMIN' && isCallApiAvailable === 'true') {
    tabs.push({
      title: 'INCOMING CALL',
      subtitle: 'webhook',
      onClick: () => props.history.push('/call-webhook'),
    });
  }
  if (userRole === 'ADMIN' && isOutgoingCallApiAvailable === 'true') {
    tabs.push({
      title: 'OUTGOING CALL',
      subtitle: 'webhook',
      onClick: () => props.history.push('/outgoing-call-webhook'),
    });
  }
  if (userRole === 'ADMIN' && isVoiceApiAvailable === 'true') {
    tabs.push({
      title: 'VOICE API',
      subtitle: 'webhook',
      onClick: () => props.history.push('/voice-api-webhook'),
    });
  }
  if (
    userRole === 'ADMIN' &&
    isNumberMaskingEnabled === 'true' &&
    isFreeFormEnabled === 'true'
  ) {
    tabs.push({
      title: 'NUMBER MASKING',
      subtitle: 'Get Receiver Number',
      onClick: () => props.history.push('/number-masking-api-webhook'),
    });
  }
  if (
    userRole === 'ADMIN' &&
    isNumberMaskingEnabled === 'true' &&
    isFreeFormEnabled === 'true'
  ) {
    tabs.push({
      title: 'NUMBER MASKING',
      subtitle: 'Send Call Details',
      active: true,
    });
  }
  const formProps = {
    title: 'NUMBER MASKING',
    subtitle: 'Send Call Details',
    webhook_type: ['NM_CALL_DETAILS'],
    access_key: props.accessKeyForNumberMaskingCallDetailsWebhook,
  };
  console.log('form', formProps);
  if (isFreeFormEnabled === 'false') {
    return <Redirect to="/" />;
  }

  return (
    <div className={`${props.className}`}>
      <Topbar />
      <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
        <Sidebar />
        <div className="ContentContainer">
          <TabSearchBarComponent tabs={tabs} />
          <FormComponent {...formProps} />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  accessKeyForNumberMaskingCallDetailsWebhook:
    state.sidebar.accessKeyForNumberMaskingCallDetailsWebhook,
});
export default commonStyle(
  connect(mapStateToProps, {})(NumberMaskingCallDetailsWebhookPage)
);
