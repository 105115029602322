import React, { useState, useEffect } from 'react';
import '../styles/webhook.css';
import ResponseComponent from './ResponseComponent';
import { commonStyle } from '../../../assets/css/css';

import DeletePopUp from './DeletePopup';
import UpdatePopup from './UpdatePopup';
import KnowledeBar from './KnowledgeBar';
import checkMark from '../../../assets/images/checkmark.png';
import { GET, PUT } from '../../../services/HttpRequests';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import DataTable from '../../../common/component/table/DataTable';
import { DarkModeOffIcon, DarkModeOnIcon } from '../../../assets/Icons/Icons';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import { ADMIN_INCOMING_WEBHOOK_DAILED_ON_AGENT_TOGGLE } from "../../../common/posthog/events";
import { logPostHogEvent } from "../../../common/posthog/posthog";
toast.configure();

const organizationUrl = process.env.REACT_APP_ORGANIZATION_API;
const organisationId = localStorage.getItem('doosra-biz-organisation-id');
const isSuperAdmin = localStorage.getItem('doosra-biz-user-role-is-super-admin') === 'true';
const FormComponent = (props) => {
  const [Response, setResponse] = useState({});
  const [ButtonDisableCheck, setButtonDisableCheck] = useState('');
  const [APIEndpoint, setAPIEndpoint] = useState('');
  const [APIKeyValue, setAPIKeyValue] = useState('');
  const [APIKey, setAPIKey] = useState('');
  const [ReqType, setReqType] = useState('post');
  const [IsWebhookAvailable, setIsWebhookAvailable] = useState(false);
  const [AreFieldsUpdated, setAreFieldsUpdated] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [dialedOnAgent, setDialedOnAgent] = useState(false);
  const [callCompletion, setCallCompletion] = useState(false);
  const button_disabled =
    /*(ButtonDisableCheck && APIKey && APIKeyValue)*/ ButtonDisableCheck
      ? false
      : true;
  const webhook_type = props.webhook_type;
  const onAPIChange = (API) => {
    setButtonDisableCheck(API);
    setAPIEndpoint(API);
  };
  const onKeyChange = (Key) => {
    setAPIKeyValue(Key);
  };
  const onOptionChange = (option) => {
    setReqType(option);
  };
  const onLabelChange = (value) => {
    setAPIKey(value);
  };
  const clearFields = () => {
    setAPIEndpoint('');
    setAPIKeyValue('');
    setAPIKey('');
    setReqType('post');
    setButtonDisableCheck('');
  };
  const createWebhook = () => {
    setIsLoading(true);
    // const organisationId = localStorage.getItem('doosra-biz-organisation-id');
    const accessKey = props.access_key;
    let request_headers = {};
    request_headers[APIKey] = APIKeyValue;
    // request_headers.request_type = ReqType
    const params = {
      organisation_id: organisationId,
      access_key: accessKey,
      web_hook: {
        end_point: APIEndpoint,
        headers: request_headers,
        api_key: {
          label: APIKey,
          value: APIKeyValue,
        },
        request_type: ReqType || 'post',
      },
      type: webhook_type[0],
    };

    PUT(`${organizationUrl}v1/organisation/webhook-domain`, {}, params)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        toast.success('webhook added successfully');
        setAreFieldsUpdated(true);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.error.reason);
        setIsLoading(false);
      });
  };

  const changeWebhookType = async (searchKey,searchValue) => {
    logPostHogEvent(ADMIN_INCOMING_WEBHOOK_DAILED_ON_AGENT_TOGGLE);
    if (props.title === 'CallWebHook') {
      
      // const organisationId = localStorage.getItem('doosra-biz-organisation-id');
      const params = {
        organisationId,
        dialedOnAgent,
        callCompletion
      }
      if(searchKey==='dialedOnAgent'){
        params.dialedOnAgent=searchValue;
        setDialedOnAgent(searchValue);
      }
      if(searchKey==='callCompletion'){
        params.callCompletion=searchValue;
        setCallCompletion(searchValue);
      }
      console.log({ organisationId, params })

      await PUT(`${organizationUrl}v1/organisation/webhooktype`, {}, params
      ).then((res) => {
        console.log(res);
        return res.data.response;
      })
        .then((res) => {
          console.log(res);
          toast.success(res.message);
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.error.reason);
        });
    }
  }

  useEffect(() => {
    setIsLoading(true);
    // const organisation_id = localStorage.getItem('doosra-biz-organisation-id');
    const params = { organisation_id: organisationId, webhook_type };
    GET(`${organizationUrl}v1/organisation/web-hook`, params)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        setIsLoading(false);
        const { features, add_ons } = res.webHookData;
        console.log('features and addons', features, add_ons);
        let apiKey = '';
        let apiKeyValue = '';
        let domain = '';
        let requestType = '';
        let webhookAvailable = false;
        const webhooktype =
          webhook_type && webhook_type.length > 0 ? webhook_type[0] : null;
        if (features && webhooktype) {
          const is_sms_webhook_enabled = add_ons.is_sms_webhook_enabled;
          const is_call_webhook_enabled = add_ons.is_call_webhook_enabled;
          const is_outgoing_call_enabled = add_ons.is_outgoing_call_enabled;
          const is_voice_api_webhook_enabled = add_ons.is_voice_api_webhook_enabled;
          const is_free_form_enabled = add_ons.is_free_form_enabled;
          // const { is_white_listed, web_hook } = webhooktype === 'SMS' ? features.sms : features.call;

          let is_white_listed, web_hook;

          if (webhooktype === 'SMS') {
            ({ is_white_listed, web_hook } = features.sms);
          }
          if (webhooktype === 'CALL') {
            ({ is_white_listed, web_hook } = features.call);
            setDialedOnAgent(features.call.dialedOnAgent);
            setCallCompletion(features.call.callCompletion);
          }
          if (webhooktype === 'OUTGOING_CALL') {
            ({ is_white_listed, web_hook } = features.outgoing_calls);
          }
          if (webhooktype === 'VOICE_API') {
            ({ is_white_listed, web_hook } = features.voice_api);
          }
          if (webhooktype === 'NUMBER_MASKING') {
            ({ is_white_listed, web_hook } = features.number_masking);
          }
          if (webhooktype === 'NM_CALL_DETAILS') {
            ({ is_white_listed, web_hook } = features.number_masking_call_details);
          }
          console.log('white', is_white_listed, web_hook);
          if (is_white_listed && web_hook) {
            domain = web_hook.domain;
            requestType = web_hook.request_type;
            webhookAvailable =
              is_sms_webhook_enabled ||
              is_call_webhook_enabled ||
              is_outgoing_call_enabled ||
              is_voice_api_webhook_enabled ||
              is_free_form_enabled;
            if (web_hook.headers) {
              const headersKeys = Object.keys(web_hook.headers);
              apiKey = headersKeys[0];
              // const headersValues = Object.values(headers);
              apiKeyValue = web_hook.headers[apiKey];
            }
          }
        }
        console.log(
          'resp',
          domain,
          apiKey,
          apiKeyValue,
          requestType,
          webhookAvailable,
          dialedOnAgent,
        );
        setResponse({
          domain,
          apiKey,
          apiKeyValue,
          requestType,
          webhookAvailable,
        });
        setAPIEndpoint(domain);
        setAPIKey(apiKey);
        setAPIKeyValue(apiKeyValue);
        setReqType(requestType);
        setIsWebhookAvailable(webhookAvailable);
        setAreFieldsUpdated(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.error?.reason);
        setIsLoading(false);
      });
  }, [webhook_type, AreFieldsUpdated]);

  const ResponseProps = {
    Response,
    webhook_type,
  };
  const deleteWebhookProps = {
    Response,
    clearFields,
    setIsWebhookAvailable,
    setIsLoading,
    toast,
    type: webhook_type[0],
  };
  const updateWebhookProps = {
    Response,
    webhook_type,
    setAreFieldsUpdated,
    setIsLoading,
    toast,
  };
  return (
    <div>
      <DataTable.Table style={{ marginBottom: 0 }}>
        {IsLoading && <PreLoader />}
        <DataTable.Head>
          <DataTable.CellHead style={{ width: '2000px' }}></DataTable.CellHead>
        </DataTable.Head>
      </DataTable.Table>

      <div className="webhookPage">
        <div className="webHookContainer">
          {(props.title === 'CallWebHook') ? (
            <div style={{ paddingBottom: "20px", display: 'flex' }} className="PopupBody">
              <div style={{ display: 'flex', marginRight: "50px" }}>
                <div style={{ paddingTop: "2px" }}>Dialed on Agent </div>
                <div style={{ paddingLeft: "10px", cursor: "pointer" }}>
                  {dialedOnAgent ? (
                    <DarkModeOnIcon fill="#12a802" onClick={() => {
                      if (isSuperAdmin && !isSuperAdminWithPermission('ADD')) {
                        changeWebhookType('dialedOnAgent',false); setDialedOnAgent(false);
                      }
                      if (!isSuperAdmin) {
                        changeWebhookType('dialedOnAgent',false); setDialedOnAgent(false);
                      }
                    }} />
                  ) : (
                    <DarkModeOffIcon onClick={() => {
                      if (isSuperAdmin && !isSuperAdminWithPermission('ADD')) {
                        changeWebhookType('dialedOnAgent',true); setDialedOnAgent(true);
                      }
                      if (!isSuperAdmin) {
                        changeWebhookType('dialedOnAgent',true); setDialedOnAgent(true);
                      }
                    }} />
                  )}
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ paddingTop: "2px" }}>Call completion</div>
                <div style={{ paddingLeft: "10px" }}>
                {callCompletion ? (
                    <DarkModeOnIcon fill="#12a802" onClick={() => {
                      if (isSuperAdmin && !isSuperAdminWithPermission('ADD')) {
                        changeWebhookType('callCompletion',false);
                      }
                      if (!isSuperAdmin) {
                        changeWebhookType('callCompletion',false);
                      }
                    }} />
                  ) : (
                    <DarkModeOffIcon onClick={() => {
                      if (isSuperAdmin && !isSuperAdminWithPermission('ADD')) {
                        changeWebhookType('callCompletion',true); 
                      }
                      if (!isSuperAdmin) {
                        changeWebhookType('callCompletion',true);
                      }
                    }} />
                  )}
                </div>
              </div>

            </div>
          ) : null}
          <div>
            <div className="webhookTitle">
              API Endpoint <span style={{ color: 'red' }}> *</span>
            </div>

            <div className="webhookDesc">
              {`Paste your APIs endpoint (URL) here, 
                        so that we know where to send your systems our responses.`}
            </div>
            <input
              className="webHookInput"
              type="text"
              value={APIEndpoint}
              disabled={IsWebhookAvailable}
              onChange={(event) => onAPIChange(event.target.value)}
            />
            {IsWebhookAvailable ? (
              <img alt="" className="check-mark-img" src={checkMark} />
            ) : null}
            <div className="Validation">
              {`Alohaa doesn’t validate your endpoints, 
                        so be sure to double check your URL entered here`}
            </div>
          </div>
          <div>
            <div className="webhookTitle">API Key </div>
            <div className="webhookDesc">Input a label for the API Key</div>
            <input
              className="webHookInput"
              type="text"
              value={APIKey}
              disabled={IsWebhookAvailable}
              onChange={(event) => onLabelChange(event.target.value)}
            />
            {IsWebhookAvailable ? (
              <img alt="" className="check-mark-img" src={checkMark} />
            ) : null}
            <div className="Validation">
              {`Alohaa doesn’t validate your API Key Label, 
                        so be sure to double check your API key Label`}
            </div>
          </div>
          <div>
            <div className="webhookTitle">API Key Value</div>
            <div className="webhookDesc">
              Create a unique key, keep it a secret!
            </div>
            <input
              className="webHookInput"
              type="text"
              value={APIKeyValue}
              disabled={IsWebhookAvailable}
              onChange={(event) => onKeyChange(event.target.value)}
            />
            {IsWebhookAvailable ? (
              <img alt="" className="check-mark-img" src={checkMark} />
            ) : null}
            <div className="Validation">
              {`Alohaa doesn’t validate your API Key, 
                        so be sure to double check your API key entered here`}
            </div>
          </div>
          <div>
            <div className="webhookTitle">Request Type</div>

            <select
              name="request-type"
              id="request"
              className="req-dropdown"
              value={ReqType}
              disabled={IsWebhookAvailable}
              onChange={(event) => onOptionChange(event.target.value)}
            >
              <option value="post" className="drp-dwn-txt">
                POST
              </option>
            </select>
            {IsWebhookAvailable ? (
              <img alt="" className="check-mark-img" src={checkMark} />
            ) : null}
          </div>
          {!IsWebhookAvailable ? (
            <div className="btn-group">
              <button
                className="primary-btn"
                type="submit"
                disabled={button_disabled || !isSuperAdminWithPermission('ADD')}
                onClick={createWebhook}
              >
                <div className="pri-btn-txt">CREATE</div>
              </button>
              <button
                className="secondary-btn"
                type="reset"
                disabled={button_disabled}
                onClick={clearFields}
              >
                <div
                  className={
                    button_disabled ? 'sec-btn-txt-disabled' : 'sec-btn-txt'
                  }
                >
                  CLEAR
                </div>
              </button>
            </div>
          ) : (
            <div className="btn-group">
              <UpdatePopup {...updateWebhookProps} />
              <DeletePopUp {...deleteWebhookProps} />
            </div>
          )}

          <div className="Res-Comp-Wrap">
            <ResponseComponent {...ResponseProps} />
          </div>
        </div>
        <KnowledeBar />
      </div>
    </div>
  );
};
export default commonStyle(FormComponent);
