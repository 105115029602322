import React, { Component } from 'react';
import styled from '@emotion/styled';

import Topbar from '../../../common/component/topbar/Topbar';
import Sidebar from '../../../common/componentV2/sidebar/Sidebar';
import { connect } from 'react-redux';
import InfoRed from '../../../assets/images/infoRed.png';
import Logo from '../../../assets/images/alohaaLogoAndText.svg';
import visible from '../../../assets/images/visibleEye.png';
import invisible from '../../../assets/images/invisibleEye.png';
import PasswordCheck from '../../../common/component/passwordCheck/PasswordCheckComponent';
import Success from '../../../assets/images/greenSuccessBig.png';
import { PUT, DELETE, POST, GET } from '../../../services/HttpRequests';
import { DarkModeOffIcon, DarkModeOnIcon } from '../../../assets/Icons/Icons';
import { ThemeContext } from '../../../providers/customeThemeProvider';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import DataTable from '../../../common/component/table/DataTable';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { toast } from 'react-toastify';

const userUrl = process.env.REACT_APP_USER_API;
const orgUrl = process.env.REACT_APP_ORGANIZATION_API;
const role = localStorage.getItem('doosra-biz-user-role');
const planAvailable = localStorage.getItem('is_plan_available');
// const showPaymentHistory = localStorage.getItem('show_payment_history');
let isFreeTrail = localStorage.getItem('is_free_trial_enabled');
isFreeTrail = isFreeTrail ? (isFreeTrail === 'true' ? true : false) : false;
const incomingWebrtcEnabled =
  localStorage.getItem('incoming_webrtc_enabled') === 'true' ? true : false;
const validatePhoneNumber = (phoneNumber) => {
  const phonePattern = /^\d{10}$/;
  return phonePattern.test(phoneNumber);
};
class AccountSettingsPage extends Component {
  constructor() {
    super();
    this.state = {
      step: 1,
      error: '',
      passwordVisible: false,
      confPasswordVisible: false,
      orgId: '',
      adminId: '',
      name: '',
      number: '',
      email: '',
      password: '',
      confPassword: '',
      baseInfo: {},
      hideCustomerDetails: false,
      isPhoneNumberChanged: false,
      countdown: 0,
      otp: '',
      userRole: role,
      incomingPreferenceMode: 'PSTN',
      internalCommunicationMode: 'PSTN'
    };
  }

  static contextType = ThemeContext;
  componentDidMount() {
    this.setState({
      hideCustomerDetails:
        this.props.isHideCustomerEnabled || this.state.hideCustomerDetails,
      userRole: role,
      name: localStorage.getItem('doosra-biz-user-name'),
      number: localStorage.getItem('doosra-biz-user-number'),
      email: localStorage.getItem('doosra-biz-user-email'),
      orgId: localStorage.getItem('doosra-biz-organisation-id'),
      adminId: localStorage.getItem('doosra-biz-user-id'),
      baseInfo: {
        name: localStorage.getItem('doosra-biz-user-name'),
        number: localStorage.getItem('doosra-biz-user-number'),
        email: localStorage.getItem('doosra-biz-user-email'),
      },
    });

    if (role === 'USER') this.getPreferenceModes();
  }

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value }, () => {
      if (
        e.target.id === 'number' &&
        e.target.value !== this.state.baseInfo.number
      ) {
        this.setState({ isPhoneNumberChanged: true });
        this.setState({ error: '' });
      } else if (e.target.id === 'number') {
        this.setState({ error: '' });
        this.setState({ isPhoneNumberChanged: false });
      }
    });
  };

  openPopup = () => {
    this.setState({ popupOpen: true });
  };

  closePopup = () => {
    this.setState({ popupOpen: false });
  };

  passwordVisiblityToggleHandler = (second) => {
    if (second) {
      this.setState({ confPasswordVisible: !this.state.confPasswordVisible });
    } else {
      this.setState({ passwordVisible: !this.state.passwordVisible });
    }
  };

  switchStep = (step) => {
    this.setState({ step: step, isOtpSent: false });
  };

  logoutAllUserHandler() {
    let userId = localStorage.getItem('doosra-biz-user-id');
    DELETE(`${userUrl}v1/auth/logout/all-devices`, {
      userId: userId,
    })
      .then((res) => {
        this.setState({ popupOpen: false });
        if (res?.data?.response?.success) {
          localStorage.clear();
          sessionStorage.clear();
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ popupOpen: false });
      });
  }

  startCountdown = () => {
    const timer = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.countdown > 0) {
          return { countdown: prevState.countdown - 1 };
        } else {
          clearInterval(timer);
          return { countdown: 0 };
        }
      });
    }, 1000);
  };

  sendOtp = async () => {
    const phoneNumber = this.state.number;

    if (!validatePhoneNumber(phoneNumber)) {
      this.setState({ error: 'Invalid phone number' });
      return;
    }

    this.setState({ isLoading: true });

    try {
      const res = await POST(`${orgUrl}signup/send-otp`, { phoneNumber });
      this.setState(
        {
          otpSecret: res.data.response?.otpObj?.secretKey,
          isLoading: false,
          isOtpSent: true,
          otpError: '',
          error: '',
          countdown: 60,
        },
        () => this.startCountdown()
      );
    } catch (err) {
      this.setState({
        isLoading: false,
        error: err?.response?.data?.error?.reason,
      });
    }
  };

  submitNewDetailHandler = () => {
    let reqBody = {
      name: this.state.name,
      phone_number: this.state.number,
      email: this.state.email,
      organisationId: this.state.orgId,
      userId: this.state.adminId,
      otpSecret: this.state.otpSecret,
      otp: this.state.otp,
    };
    PUT(`${userUrl}v1/user/profile`, {}, reqBody)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        if (role === 'USER') {
          localStorage.setItem('doosra-biz-user-email', this.state.email);
        } else {
          localStorage.setItem('doosra-biz-admin-name', this.state.name);
          localStorage.setItem('doosra-biz-admin-number', this.state.number);
        }
        let newBaseInfo = { ...this.state.baseInfo };
        newBaseInfo.name = res.name;
        newBaseInfo.number = res.phone_number;
        this.setState({
          step: 1,
          baseInfo: newBaseInfo,
          otp: '',
          otpSecret: '',
          isOtpSent: false,
          isPhoneNumberChanged: false,
          error: '',
        });
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          this.setState({ error: err.response.data.error.reason });
        }
      });
  };

  submitNewPasswordHandler = () => {
    let reqBody = {
      new_password: this.state.password,
      confirm_password: this.state.confPassword,
      userId: this.state.adminId || this.state.userId,
      organisationId: this.state.orgId,
    };
    PUT(`${userUrl}v1/user/profile/reset-password`, {}, reqBody)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        localStorage.removeItem('doosra-business-sessionToken');
        localStorage.removeItem('doosra-biz-organisation-name');
        window.location.reload();
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          this.setState({ error: err.response.data.error.reason });
        }
      });
  };

  cancelEditHandler = () => {
    this.setState({
      name: this.state.baseInfo.name,
      number: this.state.baseInfo.number,
      email: this.state.baseInfo.email,
      otp: '',
      otpSecret: '',
      isOtpSent: false,
      error: '',
      isPhoneNumberChanged: false,
    });
    this.switchStep(1);
  };

  onClickTheme = (item) => {
    const { onChangeTheme } = this.context;
    onChangeTheme(item);
  };
  hideCustomerDetails = async (status) => {
    try {
      var formData = new FormData();
      formData.append('organisation_id', this.state.orgId);
      formData.append('is_hide_customer_details_enabled', status);
      await PUT(`${orgUrl}v1/organisation`, null, formData);
      this.setState({ hideCustomerDetails: status });
    } catch (err) {
      console.log({ err });
    }
  };

  getPreferenceModes = async () => {
    try {
      const res = await GET(`${userUrl}v1/user/get-perference-modes`);
      this.setState({ incomingPreferenceMode: res?.data?.response?.incomingPreferenceMode?.incoming_preference || 'PSTN' });
      this.setState({ internalCommunicationMode: res?.data?.response?.incomingPreferenceMode?.internalCommunicationMode || 'PSTN' });
    } catch (err) {
      console.log('Unable to fetch incoming Preference');
    }
  };

  updatePreferenceMode = async (preferenceType, mode) => {
    try {
      const data = {
        preferenceMode: mode,
        preferenceType
      };
      await PUT(`${userUrl}v1/user/update-perference-mode`, null, data);
      if (preferenceType === 'incomingPreference') {
        this.setState({ incomingPreferenceMode: mode });
      }
      if (preferenceType === 'internalCommunicationMode') {
        this.setState({ internalCommunicationMode: mode });
      }
      toast.success('Updated incoming preference');
    } catch (err) {
      toast.error('Unable to update incoming preference');
    }
  };

  render() {
    let tabs = [
      {
        title: 'Account',
        active: true,
        subtitle: ``,
        onClick: () => this.props.history.push('/account-settings'),
      },
      {
        title: 'Operating Schedule',
        active: false,
        subtitle: ``,
        onClick: () => this.props.history.push('/off-hours'),
      },
      {
        title: 'Call Settings',
        active: false,
        subtitle: ``,
        onClick: () => this.props.history.push('/call-settings'),
      },
      {
        title: 'Agent Settings',
        active: false,
        subtitle: ``,
        onClick: () => this.props.history.push('/agent-settings'),
      },
    ];
    console.log({ planAvailable });
    // if (planAvailable === true || planAvailable === 'true') {
    //   if (showPaymentHistory) {
    //     tabs.push({
    //       title: 'Payment History',
    //       active: false,
    //       subtitle: ``,
    //       onClick: () => this.props.history.push('/payment-history'),
    //     });
    //   }
    //   tabs.push({
    //     title: 'KYC',
    //     active: false,
    //     subtitle: ``,
    //     onClick: () => this.props.history.push('/kyc'),
    //   });
    // }

    // if (this.props.isCampaignEnabled) {
    //   tabs.pop();
    // }
    let CurrentScreen;
    switch (this.state.step) {
      case 6:
        CurrentScreen = (
          <div
            className="LoginContentHolder"
            style={{ margin: 'auto', marginTop: 3 }}
          >
            <div className="AuthLogoContainer">
              <img alt="" src={Logo} />
            </div>
            <div className="AuthHeadContainer">
              {/* <p className="DoosraBusinessHeader">
                <span className="DoosraDefaultColor"></span> <span className="DoosraBussinessDefaultColor">alohaa</span>
              </p> */}
              <p className="AuthSubHeader">Password successfully reset.</p>
            </div>
            <form>
              <div className="AuthSuccessHolder">
                <img alt="" src={Success} />
              </div>
            </form>
          </div>
        );

        break;
      case 5:
        CurrentScreen = (
          <div className="" style={{ margin: 'auto', marginTop: 3 }}>
            <div className="AuthLogoContainer">
              <img alt="" src={Logo} />
            </div>
            <div className="AuthHeadContainer">
              {/* <p className="DoosraBusinessHeader">
                <span className="DoosraDefaultColor"></span> <span className="DoosraBussinessDefaultColor">alohaa</span>
              </p> */}
              <p className="AuthSubHeader">Create a new password</p>
            </div>

            <div className="PasswordInputHolder">
              <input
                id="password"
                className="PasswordInput"
                value={this.state.password}
                onChange={this.handleChange}
                type={this.state.passwordVisible ? 'text' : 'password'}
                placeholder="Enter your new password"
              />
              {this.state.passwordVisible ? (
                <img
                  alt=""
                  onClick={() => this.passwordVisiblityToggleHandler()}
                  className="PasswordInvisibleToggle"
                  src={invisible}
                />
              ) : (
                <img
                  alt=""
                  onClick={() => this.passwordVisiblityToggleHandler()}
                  className="PasswordVisibleToggle"
                  src={visible}
                />
              )}
            </div>
            <div className="PasswordInputHolder">
              <input
                id="confPassword"
                value={this.state.confPassword}
                onChange={this.handleChange}
                className="PasswordInput"
                type={this.state.confPasswordVisible ? 'text' : 'password'}
                placeholder="Re-enter your new password"
              />
              {this.state.confPasswordVisible ? (
                <img
                  alt=""
                  onClick={() => this.passwordVisiblityToggleHandler(true)}
                  className="PasswordInvisibleToggle"
                  src={invisible}
                />
              ) : (
                <img
                  alt=""
                  onClick={() => this.passwordVisiblityToggleHandler(true)}
                  className="PasswordVisibleToggle"
                  src={visible}
                />
              )}
            </div>

            <PasswordCheck password={this.state.password} />

            <div className="ErrorContainer ErrorContainerMargin">
              {this.state.error ? (
                <React.Fragment>
                  <img
                    alt=""
                    src={InfoRed}
                    style={{
                      paddingRight: '5px',
                      position: 'relative',
                      top: '-2px',
                    }}
                  />
                  {this.state.error}
                </React.Fragment>
              ) : null}
            </div>
            <p
              style={{
                width: '280px',
                fontSize: '14px',
                textAlign: 'center',
                color: '#6d6e71',
                marginTop: '16px',
                marginLeft: ' auto ',
                marginRight: 'auto',
              }}
            >
              You will be logged out of all sessions after you reset your
              password
            </p>
            <button
              className="AuthButton"
              onClick={this.submitNewPasswordHandler}
            >
              NEXT
            </button>
            <button
              className="AuthButton AuthButtonInvert"
              onClick={() => this.switchStep(1)}
            >
              CANCEL
            </button>
          </div>
        );
        break;
      case 4:
        CurrentScreen = (
          <div
            className="LoginContentHolder"
            style={{ margin: 'auto', marginTop: 3 }}
          >
            <div className="AuthLogoContainer">
              <img alt="" src={Logo} />
            </div>
            <div className="AuthHeadContainer">
              {/* <p className="DoosraBusinessHeader">
                <span className="DoosraDefaultColor"></span> <span className="DoosraBussinessDefaultColor">alohaa</span>
              </p> */}
              <p className="AuthSubHeader">Enter OTP</p>
            </div>

            <input className="InputBox" placeholder="OTP goes here" />
            <div className="ErrorContainer ErrorContainerMargin">
              {this.state.error ? (
                <React.Fragment>
                  <img
                    alt=""
                    src={InfoRed}
                    style={{
                      paddingRight: '5px',
                      position: 'relative',
                      top: '-2px',
                    }}
                  />
                  {this.state.error}
                </React.Fragment>
              ) : null}
            </div>
            <button className="AuthButton" onClick={() => this.switchStep(5)}>
              NEXT
            </button>
            <button
              className="AuthButton AuthButtonInvert"
              onClick={() => this.switchStep(3)}
            >
              BACK
            </button>
          </div>
        );
        break;
      case 3:
        CurrentScreen = (
          <div
            className="LoginContentHolder"
            style={{ margin: 'auto', marginTop: 3 }}
          >
            <div className="AuthLogoContainer">
              <img alt="" src={Logo} />
            </div>
            <div className="AuthHeadContainer">
              {/* <p className="DoosraBusinessHeader">
                <span className="DoosraDefaultColor"></span> <span className="DoosraBussinessDefaultColor">alohaa</span>
              </p> */}
              <p className="AuthSubHeader">Reset your password</p>
            </div>

            <input
              className="InputBox"
              placeholder="Enter your phone number or email"
            />

            <div className="ErrorContainer ErrorContainerMargin">
              {this.state.error ? (
                <React.Fragment>
                  <img
                    alt=""
                    src={InfoRed}
                    style={{
                      paddingRight: '5px',
                      position: 'relative',
                      top: '-2px',
                    }}
                  />
                  {this.state.error}
                </React.Fragment>
              ) : null}
            </div>

            <button className="AuthButton" onClick={() => this.switchStep(4)}>
              NEXT
            </button>
            <button
              className="AuthButton AuthButtonInvert"
              onClick={() => this.switchStep(1)}
            >
              CANCEL
            </button>
          </div>
        );
        break;

      case 2:
        CurrentScreen = (
          <div
            className="LoginContentHolder"
            style={{ margin: 'auto', marginTop: 3 }}
          >
            <div className="AuthLogoContainer">
              <img alt="" src={Logo} />
            </div>
            <div className="AuthHeadContainer">
              {!(this.state.isOtpSent && this.state.isPhoneNumberChanged) && (
                <p className="AuthSubHeader">Edit your account details</p>
              )}
            </div>
            {!(this.state.isOtpSent && this.state.isPhoneNumberChanged) && (
              <div>
                <input
                  id="name"
                  onChange={this.handleChange}
                  value={this.state.name}
                  className="InputBox"
                  placeholder="Name"
                  readOnly={role === 'USER'}
                />
                <input
                  className="InputBox"
                  id="email"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={this.handleChange}
                  readOnly={role === 'USER'}
                />
                <input
                  id="number"
                  onChange={this.handleChange}
                  value={this.state.number}
                  className="InputBox"
                  placeholder="Phone number"
                  disabled={
                    this.state.isPhoneNumberChanged && this.state.isOtpSent
                  }
                  readOnly={role === 'USER'}
                />
              </div>
            )}
            {this.state.isOtpSent && this.state.isPhoneNumberChanged && (
              <input
                type="text"
                value={this.state.otp}
                onChange={this.handleChange}
                id="otp"
                placeholder="Enter OTP"
                className="InputBox"
              />
            )}
            <div className="ErrorContainer ErrorContainerMargin">
              {this.state.error ? (
                <React.Fragment>
                  <img
                    alt=""
                    src={InfoRed}
                    style={{
                      paddingRight: '5px',
                      position: 'relative',
                      top: '-2px',
                    }}
                  />
                  {this.state.error}
                </React.Fragment>
              ) : null}
            </div>
            {this.state.isPhoneNumberChanged && this.state.isOtpSent && (
              <button
                className={`AuthButton${this.state.countdown > 0 ? ' AuthButtonInvert' : ''
                  }`}
                onClick={this.sendOtp}
                disabled={this.state.countdown > 0}
              >
                {this.state.countdown > 0
                  ? `RESEND OTP IN (${this.state.countdown})S`
                  : 'RESEND OTP'}
              </button>
            )}

            <button
              className="AuthButton"
              onClick={
                this.state.isPhoneNumberChanged && !this.state.isOtpSent
                  ? this.sendOtp
                  : this.submitNewDetailHandler
              }
            >
              {this.state.isPhoneNumberChanged && !this.state.isOtpSent
                ? 'SEND OTP'
                : !this.state.isPhoneNumberChanged && !this.state.isOtpSent
                  ? 'SAVE'
                  : 'Verify'}
            </button>
            <button
              className="AuthButton AuthButtonInvert"
              onClick={this.cancelEditHandler}
            >
              CANCEL
            </button>
          </div>
        );
        break;
      default:
      case 1:
        CurrentScreen = (
          <div
            className="LoginContentHolder"
            style={{ margin: 'auto', marginTop: 3 }}
          >
            <div className="AuthLogoContainer">
              <img alt="" src={Logo} />
            </div>
            <div className="AuthHeadContainer">
              {/* <p className="DoosraBusinessHeader">
                <span className="DoosraDefaultColor"></span> <span className="DoosraBussinessDefaultColor">alohaa</span>
              </p> */}
              <p className="AuthSubHeader">Your account details</p>
            </div>
            <input
              id="name"
              value={this.state.name}
              className="InputBox"
              placeholder="name"
              readOnly="true"
            />
            <input
              id="email"
              value={this.state.email}
              className="InputBox"
              placeholder="Email"
              readOnly="true"
            />
            <input
              id="number"
              value={this.state.number}
              className="InputBox"
              placeholder="Phone number"
              readOnly="true"
            />

            {this.state.error ? (
              <React.Fragment>
                <div className="ErrorContainer ErrorContainerMargin">
                  <img
                    alt=""
                    src={InfoRed}
                    style={{
                      paddingRight: '5px',
                      position: 'relative',
                      top: '-2px',
                    }}
                  />
                  {this.state.error}
                </div>
              </React.Fragment>
            ) : null}
            {localStorage.getItem('doosra-biz-org-apiKey') && (
              <div style={{ marginTop: 20 }}>
                {' '}
                <b>API KEY : </b>{' '}
                {localStorage.getItem('doosra-biz-org-apiKey')}
              </div>
            )}
            <button
              className="AuthButton"
              onClick={() => this.switchStep(2)}
              style={{ marginTop: 20 }}
            >
              EDIT
            </button>
            <button
              className="AuthButton AuthButtonInvert"
              onClick={() => this.switchStep(5)}
            >
              RESET PASSWORD
            </button>
            {/* <div className="DarkModeContainer">
              <div className="DarkText">Dark Mode</div>
              <div
                style={{
                  marginRight: 0,
                  marginLeft: 'auto',
                }}
              >
                {this.context.themeType === 'dark' ? (
                  <DarkModeOnIcon onClick={() => this.onClickTheme('light')} />
                ) : (
                  <DarkModeOffIcon onClick={() => this.onClickTheme('dark')} />
                )}
              </div>
            </div> */}
            {this.state.userRole === 'ADMIN' && !isFreeTrail && (
              <div className="DarkModeContainer">
                <div className="DarkText">Hide Customer Details</div>
                <div
                  style={{
                    marginRight: 0,
                    marginLeft: 'auto',
                  }}
                >
                  {this.state.hideCustomerDetails ? (
                    <DarkModeOnIcon
                      fill="#12a802"
                      onClick={() => this.hideCustomerDetails(false)}
                    />
                  ) : (
                    <DarkModeOffIcon
                      onClick={() => this.hideCustomerDetails(true)}
                    />
                  )}
                </div>
              </div>
            )}
            {role === 'USER' && this.props.isIncomingWebrtcEnabled &&
              <div className="IncomingPreference">
                <div className="IncomingPreferenceText">
                  Incoming Preference
                </div>
                <div className="IncomingPreferenceMode">
                  <input
                    type="radio"
                    value="PSTN"
                    checked={this.state.incomingPreferenceMode === 'PSTN'}
                    onChange={(e) => this.updatePreferenceMode('incomingPreference', e.target.value)}
                  />
                  GSM
                </div>
                <div className="IncomingPreferenceMode">
                  <input
                    type="radio"
                    value="VOIP"
                    onChange={(e) => this.updatePreferenceMode('incomingPreference', e.target.value)}
                    checked={this.state.incomingPreferenceMode === 'VOIP'}
                  />
                  Browser
                </div>
              </div>
            }
            {role === 'USER' && (localStorage.getItem('call_merge_enabled') === 'true' ||
              localStorage.getItem('call_transfer_enabled') === 'true') &&
              // localStorage.getItem('call_merge_transfer_webrtc_enabled') === 'true' &&
              <div className='IncomingPreference'>
                <div className="IncomingPreferenceText">Call Conference/Transfer Preference</div>
                <div className='IncomingPreferenceMode'>
                  <input
                    type='radio'
                    value="PSTN"
                    checked={this.state.internalCommunicationMode === 'PSTN'}
                    onChange={(e) => this.updatePreferenceMode('internalCommunicationMode', e.target.value)}
                  />
                  GSM
                </div>
                <div className='IncomingPreferenceMode'>
                  <input
                    type='radio'
                    value="VOIP"
                    onChange={(e) => this.updatePreferenceMode('internalCommunicationMode', e.target.value)}
                    checked={this.state.internalCommunicationMode === 'VOIP'}
                  />
                  Browser
                </div>
              </div>
            }
            <button
              className="LogoutAllDevicesButton LogoutAllDevices"
              onClick={this.openPopup}
            >
              LOGOUT FROM ALL DEVICES
            </button>
          </div>
        );
        break;
    }
    let popper = (
      <Popup
        open={this.state.popupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Logout from all devices?</p>
            <img
              alt=""
              className="PopupClose"
              src={Close}
              onClick={this.closePopup}
            />
          </div>
          <div className="PopupBody">
            <DataTable.Table
              style={{ width: '100%', marginTop: 15, marginBottom: 5 }}
            >
              <DataTable.Body>
                <button
                  className="AuthButton LogoutAllDevicePopup"
                  onClick={() => this.logoutAllUserHandler()}
                >
                  YES
                </button>
                <button
                  className="LogoutAllDevicesButton LogoutAllDevices LogoutAllDevicePopup"
                  onClick={this.closePopup}
                >
                  Close
                </button>
              </DataTable.Body>
            </DataTable.Table>
          </div>
        </div>
      </Popup>
    );
    return (
      <div className={`${this.props.className}`}>
        <Topbar />
        <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
          <Sidebar />
          <div className="ContentContainer" style={{ width: '100%' }}>
            {/* {role !== 'USER' && !isFreeTrail && <TabSearchBar tabs={tabs} />} */}
            {CurrentScreen}
          </div>
        </div>
        {popper}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  isHideCustomerEnabled: state.topbar.isHideCustomerEnabled,
  isCampaignEnabled: state.topbar.isCampaignEnabled,
  isIncomingWebrtcEnabled: state.topbar.isIncomingWebrtcEnabled,
});

export default styled(connect(mapStateToProps, {})(AccountSettingsPage))`
  .ContentContainer {
    /* width: 98%; */
    /* margin: 1%; */
    background: ${(props) => props.theme.colors.acccountsettingBgColor};
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    overflow: auto;
  }

  .DarkModeContainer {
    display: flex;
    margin-top: 23px;
  }
  .DarkText {
    color: ${(props) => props.theme.colors.topBarAccTextColor};
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    width: 270px;
    height: 24px;
  }

  .IncomingPreference {
    display: flex;
    margin-top: 23px;
    gap: 15px;
    justifycontent: center;
    alignitems: center;
  }
  .IncomingPreferenceMode {
    display: flex;
    gap: 5px;
    font-size: 18px;
    justifycontent: center;
    alignitems: center;
    fontweight: 600;
  }
  .IncomingPreferenceText {
    color: ${(props) => props.theme.colors.topBarAccTextColor};
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    width: 270px;
    height: 24px;
  }

  .Content {
    margin-left: 230px;
    height: 100vh;
    transition: margin 0.3s;
  }

  .ContentFull {
    margin-left: 72px;
    height: 100vh;
    transition: margin 0.3s;
  }

  .SMSTableAlignment {
    display: grid;
    height: 48px;
    grid-template-columns:
      110px minmax(100px, 170px) minmax(80px, 100px) minmax(200px, auto)
      60px 110px;
    background: var(--light-grey-color);
    padding-left: 10px;
  }

  .SMSDataTableAlignment {
    display: grid;
    grid-template-columns:
      110px minmax(100px, 170px) minmax(80px, 100px) minmax(200px, auto)
      60px 110px;
    background: var(--white-color);
    padding-left: 10px;
  }

  .CallsTableAlignment {
    display: grid;
    height: 48px;
    grid-template-columns: 110px 110px 175px 130px 140px 100px auto 60px 110px;
    background: var(--light-grey-color);
    padding-left: 10px;
  }

  .CallsDataTableAlignment {
    display: grid;
    grid-template-columns: 110px 110px 175px 130px 140px 100px auto 60px 110px;
    background: var(--white-color);
    padding-left: 10px;
  }

  .DoosraNumbersTableAlignment {
    display: grid;
    height: 48px;
    grid-template-columns: 150px 100px 200px auto 100px;
    background: var(--light-grey-color);
    padding-left: 10px;
  }

  .DoosraNumbersDataTableAlignment {
    display: grid;
    grid-template-columns: 150px 100px 200px auto 300px;
    background: var(--white-color);
    padding-left: 10px;
  }

  .UsersTableAlignment {
    display: grid;
    height: 48px;
    grid-template-columns:
      90px 80px minmax(100px, 170px) 120px 110px 110px minmax(100px, auto)
      auto 122px 116px 90px;
    background: var(--light-grey-color);
    padding-left: 10px;
  }

  .UsersTableAlignmentAdj {
    grid-template-columns:
      90px 80px minmax(100px, 170px) 120px 110px 110px minmax(100px, auto)
      auto 130px 110px;
  }

  .UsersDataTableAlignment {
    display: grid;
    /* grid-template-columns: 48px 248px 250px 350px auto auto 100px; */
    grid-template-columns:
      90px 80px minmax(100px, 170px) 120px 110px 110px minmax(100px, auto)
      auto 122px 116px 90px;
    background: var(--white-color);
    padding-left: 10px;
  }

  .UsersDataTableAlignmentAdj {
    grid-template-columns:
      90px 80px minmax(100px, 170px) 110px 110px 110px 150px minmax(100px, auto)
      auto 130px 110px;
  }

  .TableHeader {
    font-size: 12px;
    font-weight: 600;
    margin: auto 0;
    color: var(--secondary-color1);
  }

  .TableContent {
    font-size: 13px;
    font-weight: 500;
    /* padding: 5px; */
    word-break: break-word;
    margin-bottom: 0;
    /* margin: 5px 0; */
    align-self: center;
  }

  .TableEndElement {
    text-align: end;
    padding-right: 10px;
  }

  .HR {
    border-top: 1px solid var(--mid-grey-color);
    width: 98%;
    margin: auto;
  }

  .HR.TableRowDivider {
    margin: 0 10px;
    width: calc(100% - 20px);
  }

  .ReassignButton {
    width: 152px;
    height: 32px;
    border-radius: 3px;
    background-color: var(--blue-color);
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1.2px;
    text-align: center;
    color: var(--white-color);
    border: none;
    margin: 5px;
  }

  .UnassignButton {
    width: 152px;
    height: 32px;
    border-radius: 3px;
    background-color: var(--yellowish-orange-color);
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1.2px;
    text-align: center;
    color: var(--white-color);
    border: none;
    margin: 5px;
  }

  .DeactivateButton {
    width: 152px;
    height: 32px;
    border-radius: 3px;
    background-color: var(--red-color);
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1.2px;
    text-align: center;
    color: var(--white-color);
    border: none;
    margin: 5px;
  }

  .ActivateButton {
    width: 152px;
    height: 32px;
    border-radius: 3px;
    background-color: var(--text-green-color);
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1.2px;
    text-align: center;
    color: var(--white-color);
    border: none;
    margin: 5px;
  }

  .TabelContainer {
    /* min-height: 82%; */
  }

  .NotFoundContainer {
    text-align: center;
    text-align: -webkit-center;
    margin-top: 8%;
    margin-left: auto;
    margin-right: auto;
  }

  .NotFound {
    font-size: 10vw;
    color: ${(props) => props.theme.colors.primaryColor};
    font-family: Montserrat;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }

  .NotFoundText {
    font-size: 3vw;
    font-family: Montserrat;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }

  .RedirectLink {
    font-size: 1vw;
    color: #32abbc;
    font-family: Montserrat;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-decoration: underline;
  }

  @media only screen and (max-width: 900px) {
    .ContentContainer {
      width: 98%;
      margin: 1%;
      height: calc(100vh - 100px);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: auto;
    }
    .SMSTableAlignment {
      width: fit-content;
      grid-template-columns: 48px 100px 100px 150px 200px 100px 100px;
    }
    .SMSDataTableAlignment {
      width: fit-content;
      grid-template-columns: 48px 100px 100px 150px 200px 100px 100px;
    }
    .DoosraNumbersTableAlignment {
      grid-template-columns: 48px 100px 100px 100px 150px 300px;
    }
    .DoosraNumbersDataTableAlignment {
      grid-template-columns: 48px 100px 100px 100px 150px 300px;
    }
    .UsersTableAlignment {
      grid-template-columns: 48px 60px 125px 125px 200px 100px 100px;
    }
    .UsersDataTableAlignment {
      grid-template-columns: 48px 60px 125px 125px 200px 100px 100px;
    }
    .TabelContainer {
      height: auto;
    }
  }

  .rectangle {
    width: 260px;
    min-height: 356px;
    margin: 40px 0px 0px 17px;
    border-radius: 4px;
    box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #f5f8fa;
    background-color: #fff;
  }

  .exmptyrectangle {
    flex-direction: column;
    width: 260px;
    display: flex;
    min-height: 356px;
    margin: 40px 0px 0px 17px;
    border-radius: 4px;
    box-shadow: 0 3px 11px 0 rgb(0 0 0 / 4%);
    border: solid 1px #f5f8fa;
    background-color: #fff;
    justify-content: center;
    align-items: center;
  }

  .create-a-group {
    width: 126px;
    height: 19px;
    margin: 0 0 9px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #00515a;
  }

  .showme {
    display: none;
  }

  .showhim:hover .showme {
    display: block;
    position: absolute;
    background: rgb(255, 255, 255);
    padding: 12px;
    width: 165px;
    order: solid 1px #f5f8fa;
    border-radius: 4px;
    box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.04);
  }

  .showMenu {
    display: flex;
    width: 144px;
    height: 28px;
    margin: 0 0 4px;
    padding: 7px 8px 7px;
    border-radius: 8px;
    background-color: #f4f5f6;
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #777;
    cursor: pointer;
  }

  .showDeleteMenu {
    display: flex;
    width: 144px;
    height: 28px;
    cursor: pointer;
    /* margin: 0 0 4px; */
    padding: 7px 8px 7px;
    border-radius: 8px;
    background-color: #ffe6ea;
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #ee3048;
  }
  .showMenuText {
    width: 120px;
    height: 13px;
    margin: 0 18px 0 0;
  }
  .column {
    float: left;
    width: 25%;
    padding: 0 0px;
  }

  .row {
    margin: 0 0;
    padding-bottom: 36px;
  }

  .row:after {
    content: '';
    display: table;
    clear: both;
  }

  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
      display: block;
      margin-bottom: 0px;
    }
  }

  .dot {
    height: 35px;
    width: 35px;
    background-color: #f2f5f6;
    border-radius: 50%;
    display: inline-block;
  }

  .menudot {
    height: 25px;
    width: 25px;
    background-color: #f2f5f6;
    border-radius: 50%;
    display: inline-block;
  }

  .BackgroundCircleOne {
    border-radius: 50%;
    width: 490px;
    height: 490px;
    z-index: 0;
    position: fixed;
    top: 5%;
    left: 45%;
    opacity: 0.1;
    background-color: var(--white-color);
  }

  .BackgroundCircleTwo {
    border-radius: 50%;
    width: 235px;
    height: 235px;
    z-index: 0;
    position: fixed;
    top: 72%;
    left: 34%;
    opacity: 0.1;
    background-color: var(--white-color);
  }

  .LoginDiv {
    position: absolute;
    width: 300px;
    height: 300px;
    /* Center form on page horizontally & vertically */
    top: 45%;
    left: 50%;
    margin-top: -300px;
    margin-left: -200px;
  }

  .LoginDiv40 {
    top: 40%;
  }

  .LoginDiv50 {
    top: 50%;
  }

  .LoginContentHolder {
    width: 520px;
    /* height: 300px; */
    background: ${(props) => props.theme.colors.acccountsettingBgColor};
    border-radius: 10px;
    margin: 0;
    padding: 80px 56px;
  }

  .AuthLogoContainer {
    margin-bottom: 15px;
  }

  .AuthHeadContainer {
    margin-bottom: 40px;
  }

  .DoosraBusinessHeader {
    /* font-family: Montserrat; */
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 1.07; */
    margin: 0;
    margin-bottom: 10px;
    letter-spacing: normal;
  }

  .AuthSubHeader {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    margin: 0;
    letter-spacing: normal;
    color: ${(props) => props.theme.colors.topBarAccTextColor};
  }

  .DoosraDefaultColor {
    color: var(--primary-color1);
  }
  .DoosraBussinessDefaultColor {
    color: ${(props) => props.theme.colors.topBarAccTextColor};
  }

  .InputBox {
    padding-left: 5%;
    width: 100%;
    height: 44px;
    font-size: 16px;
    border-radius: 4px;
    border: solid 1px #939598;
    margin-top: 7px;
    margin-bottom: 7px;
    color: ${(props) => props.theme.colors.topBarAccTextColor};
    background-color: ${(props) => props.theme.colors.topBarBg};
  }

  .AuthButton {
    width: 100%;
    padding: 15px 50px 14px;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.75px;
    text-align: center;
    color: var(--white-color);
    border-radius: 4px;
    border-width: 0;
    border: solid 1px var(--primary-color1);
    background-color: var(--primary-color1);
  }

  .AuthButtonInvert {
    border: solid 1px #939598;
    color: #939598;
    background-color: ${(props) => props.theme.colors.topBarBg};
  }

  .LogoutAllDevices {
    border: solid 1px red;
    color: red;
    background-color: ${(props) => props.theme.colors.topBarBg};
  }

  .AuthRememberOnDeviceContainer {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .AuthRememberOnDevice {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    margin: 0;
    letter-spacing: normal;
    text-align: left;
    color: #777777;
  }

  .AuthCheckbox {
    position: relative;
    top: 2px;
    right: 10px;
  }

  .PasswordInputHolder {
    display: grid;
    grid-template-columns: 92% 8%;
    /* display: flex; */
    /* justify-content: space-between; */
    align-items: center;
    margin-top: 7px;
    margin-bottom: 7px;
    padding: 15px 10px 14px 10px;
    border-radius: 4px;
    border: solid 1px var(--light-grey-input-bg-color);
    background-color: ${(props) => props.theme.colors.topBarBg};
  }

  .PasswordInput {
    border: none;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    padding-right: 45px;
    color: ${(props) => props.theme.colors.topBarAccTextColor};
    background-color: ${(props) => props.theme.colors.topBarBg};
  }

  .PasswordVisibleToggle {
    // position: relative;
    // top: 5px;
  }

  .PasswordInvisibleToggle {
    // position: relative;
    // top: 3px;
  }

  .PasswordInput:focus,
  .PasswordInput:focus {
    outline: none;
  }

  .TextCenter {
    text-align: center;
  }

  .QRCodeText {
    font-size: 15px;
    font-weight: bold;
    color: var(--white-color);
    padding-left: 20px;
    width: 215px;
    float: left;
  }

  .QRCodeIcon {
    float: right;
    margin: 12px;
  }

  .SignIntoDashboard {
    font-size: 15px;
    font-weight: bold;
    color: var(--white-color);
    padding-left: 20px;
    width: 230px;
    float: left;
    /* ------------- */
    width: 250px;
    margin: 13px;
  }

  .SignIntoRightArrowIcon {
    float: right;
    margin: 15px;
  }

  .AuthSuccessHolder {
    text-align: center;
    margin-bottom: 56px;
  }

  .PasswordCheckContainer {
    background-color: var(--light-grey-bg-color);
    border-radius: 4px;
    margin: 16px auto;
    padding: 5px 20px;
  }

  .PasswordCheckContainer h1 {
    font-size: 14px;
    font-weight: 600;
  }

  /* .q {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #6d6e71;
} */

  .PasswordCheckContentContainer {
    display: grid;
    grid-template-columns: 8% 92%;
    grid-template-rows: auto auto auto auto;
  }

  .PasswordCheckContentContainer img {
    padding-top: 3px;
  }

  .GreyText {
    margin: 0;
    font-size: 13px;
    color: var(--text-grey-color);
  }

  .GreenText {
    margin: 0;
    font-size: 13px;
    color: var(--text-green-color);
  }

  .ErrorContainer {
    min-height: 19px;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    /* line-height: 3.92; */
    letter-spacing: normal;
    text-align: center;
    color: var(--red-color);
  }

  .ErrorContainerMargin {
    margin-top: 16px;
    margin-bottom: 18px;
  }

  .Error {
    width: fit-content;
    margin: 0;
  }

  .OTPResent {
    display: flex;
    justify-content: center;
  }
  .OTPResent img {
    height: 14px;
    width: 14px;
    position: relative;
    top: 3px;
    margin-right: 4px;
  }
  .OTPResent p {
    color: green;
    margin: 0;
    font-size: 14px;
  }
  @media only screen and (max-width: 1500px) {
    .PasswordInput {
      border: none;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      padding-right: 0;
      color: ${(props) => props.theme.colors.topBarAccTextColor};
    }
    .PasswordVisibleToggle {
      float: right;
      padding: 6px 4px;
    }
    .PasswordInvisibleToggle {
      float: right;
      padding: 4px 4px;
    }
  }
`;
