import React, { useCallback } from "react";
import { Handle, Position } from "reactflow";
import Cancel from "../../assets/images/cancel.png";
import GroupIcon from "../../assets/images/group.png";
import { SET_SIDEBAR_OPEN } from "../../actions/callFlowActions";
import { useDispatch } from 'react-redux';
import Settings from "../../assets/images/settings.png"

const handleStyle = { left: 10 };

const GroupCall = ({ id, data, onDelete }) => {
  const dispatch = useDispatch();
 return (
    <>
    <Handle className="handle" type="target" position={Position.Top} />
    <div style={{ minHeight: '104px', minWidth: "248px", backgroundColor: "#F3FBF5",  borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
        <div style={{ minHeight: '52px', minWidth: "170px", backgroundColor: "#6B4ACE0D", display: "flex", justifyContent: "space-between",  alignItems: "center" }}>
            <div style={{marginLeft: 10 }}>
            <img style={{ width: "20px", height: "20px",  }} src={GroupIcon} /> 
            <text style={{ fontSize: "20px", color: "#235789", marginLeft: 10, fontWeight: "600" }}>Group</text>
            </div>
            <div>
              <img onClick={() => {
                dispatch({ type: SET_SIDEBAR_OPEN, payload: { isSideBarOpen: true, menuName: "GROUP", id, data: data  }})
              }} style={{ width: "20px", height: "20px", marginRight: 10 }} src={Settings} />
              <img onClick={() => onDelete(id)} style={{ width: "20px", height: "20px", marginRight: 10 }} src={Cancel} />
            </div>
        </div>
        <div onClick={() => {
          dispatch({ type: SET_SIDEBAR_OPEN, payload: { isSideBarOpen: true, menuName: "GROUP", id, data: data  }})
        }} style={{ minHeight: '52px', minWidth: "248px", backgroundColor: 'white', justifyContent: 'center', display: 'flex', alignItems: "center" }}> 
            <text style={{ fontSize: "16px", fontWeight: '500', color: data?.label ? "black" : "#9B9B9B" }}>{data?.label || "Select a group"}</text>
        </div>
        
      </div>
    <Handle className="handle" type="source" position={Position.Bottom} id="a" />
  </>
 )

};

export default GroupCall;